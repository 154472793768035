import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MDTypography from "components/MDTypography";
import { Pagination } from "@mui/material";
import PriceDetails from "components/FlightDetails/priceDetails";
import FlightSubstitute from "components/FlightDetails/flightSubstitute";
import UserStatus from "components/FlightDetails/userStatus";
import { layoversFlight } from "utils/api";
import FlightDetailModal from "components/FlightDetailModal";
import moment from "moment";
import { updateFlightPair } from "utils/api";
import { toast } from "react-toastify";
import { createFlightPair } from "utils/api";
import { updateSubstituteStatus } from "utils/api";
import { Modal } from "react-bootstrap";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const UserSubstitute = ({ substitutes,details,userId, loading, setLoading, current_price, allBookingData }) => {

  const [subId, setSubId] = React.useState(null)
  const [flightshow, setFlightShow] = React.useState(false);
  const [data, setData] = React.useState([])
  const [numStops, setNumStops] = React.useState(null)
  const [status, setStatus] = React.useState(null);
  const [layoverPrice, setLayoverPrice] = React.useState(null)
  const [substituteId, setSubstituteId] = useState("")
  const [layoverData, setLayoverData] = React.useState(null)
  const [show, setShow] = React.useState(false);
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [return_id, setReturnId] = React.useState(null);
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [siteUrl, setSiteUrl] = React.useState("")
  const [acceptData, setAcceptData] = React.useState(null)
  const [currentUpdate, setCurrentUpdate] = React.useState(false)
  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case "ELIMINATE":
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["Downgrade", "I don’t want downgrade"],
          ["OTHER", "Other"],
        ];
      case "REJECT":
        return [
          ["WAITING", "Waiting"],
          ["LAYOVER", "Layover"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          // ["Downgrade", "I don’t want downgrade"],
          ["OTHER", "Other"],
        ];
      default:
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["WAITING", "Waiting"],
          ["POLICY_RESTRICTION", "Policy Restriction"],
          ["OTHER", "Other"],
        ];
    }
  }, [status]);

  const handleClose = () => {
    setShow(false);
    setSelectedReason("");
    setOtherReasonText("");
    setSelectMesage("");
  };

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== "OTHER") {
      setOtherReasonText("");
    }
  };


  const pageCount = Math.ceil(substitutes?.length / itemsPerPage);

  const displayedItems = substitutes?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const flightHandleClose = () => {
    setFlightShow(false);
    setData([])
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

 

  const handleStatus = (id, status, rid, url, data, item, current) => {
    setShow(id);
    setStatus(status);
    setSiteUrl(url)
    setReturnId(rid)
    setSubstituteId(id)
    setCurrentUpdate(current)
    const storedUser = localStorage.getItem("active-user");
    const user = JSON.parse(storedUser);

    if ((status === "ACCEPT" || status === "REINSTATE") && (current === "current")) {
      setShow(true)
      const currtPrice = item?.same_flight_sub?.total_price
      setAcceptData({
        "id": item?.id,
        "price": currtPrice
      })
    } else {
      if ((status === "ACCEPT" || status === "REINSTATE")) {

        layoversFlight(id).then((res) => {
          const segment = res?.data?.[0]?.offer_data?.itineraries
          const offer_data = res?.data?.[0]?.offer_data
          const singleSegment = segment?.[0]?.segments?.[0]

          const childFlights = segment?.flatMap((flight, i) =>
            flight.segments
              .filter((_, index) => !(i === 0 && index === 0)) // Remove only the first segment from data[0]
              .map((segment) => ({
                message_id: userId,
                sender_email: user?.email,
                airline: offer_data?.validatingAirlineCodes?.[0],
                departure_city: segment?.departure?.iataCode,
                destination_city: segment?.arrival?.iataCode,
                departure_date: segment?.departure?.at?.split("T")?.[0],
                return_date: segment?.arrival?.at?.split("T")?.[0],
                price: offer_data?.price?.total,
                flight_number: `${segment?.carrierCode}${segment?.number}`,
                flight_class: offer_data?.travelerPricings?.[0]?.fareDetailsBySegment?.[0]?.cabin,
                departure_time: moment(segment?.departure?.at?.split("T")?.[1], "HH:mm:ss").format(
                  "h:mm A"
                ),
                Arrives_time: moment(segment?.arrival?.at?.split("T")?.[1], "HH:mm:ss").format(
                  "h:mm A"
                ),
                "number_of_travelers": item?.number_of_travelers,
                is_return_flight: i === 0 ? false : true,
                is_show_data: true,
              }))
          );

          if(rid == true || rid == "true"){
            setAcceptData({
              "id": userId,
              "user": userId,
              "Flight_substitute": data?.id,
              "saved_amount": data?.saved_amount,
              "booking_amount": data?.price,
              "previouse_amount": item?.price,
              "parent_flight": {
                "message_id": userId,
                "sender_email": user?.email,
                "airline": data?.airline_name,
                "departure_city": data?.departure_iata_code,
                "destination_city": data?.arrival_iata_code,
                "departure_date": data?.departure_time?.split(" ")?.[0],
                "return_date": data?.arrival_time?.split(" ")?.[0],
                "price": data?.price,
                "number_of_travelers": item?.number_of_travelers,
                "flight_number": `${data?.airline_code}${data?.flight_number}`,
                "flight_class": data?.travel_class,
                "departure_time": `${data?.departure_time?.split(" ")?.[1]} ${data?.departure_time?.split(" ")?.[2]}`,
                "Arrives_time": `${data?.arrival_time?.split(" ")?.[1]} ${data?.arrival_time?.split(" ")?.[2]}`,
                "is_return_flight": false,
                "is_show_data": true
              },
              "child_flight": childFlights
  
            })
          }else{
            setAcceptData({
              "id": userId,
              "user": userId,
              "Flight_substitute": data?.id,
              "saved_amount": data?.saved_amount,
              "booking_amount": data?.price,
              "previouse_amount": item?.price,
              "parent_flight": {
                "message_id": userId,
                "sender_email": user?.email,
                "airline": data?.airline_name,
                "departure_city": data?.departure_iata_code,
                "destination_city": singleSegment?.arrival?.iataCode,
                "departure_date": data?.departure_time?.split(" ")?.[0],
                "return_date": singleSegment?.arrival?.at?.split("T")?.[0],
                "price": data?.price,
                "number_of_travelers": item?.number_of_travelers,
                "flight_number": `${data?.airline_code}${data?.flight_number}`,
                "flight_class": data?.travel_class,
                "departure_time": `${data?.departure_time?.split(" ")?.[1]} ${data?.departure_time?.split(" ")?.[2]}`,
                "Arrives_time": moment(singleSegment?.arrival?.at?.split("T")?.[1], "HH:mm:ss").format("h:mm A"),
                "is_return_flight": false,
                "is_show_data": true
              },
              "child_flight": childFlights
  
            })
          }

          
        }).catch((error) => {
          console.log(error)
        })
      } else {
        setShow(true)
      }
    }
  };

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT" && status !== "REINSTATE") {
      setSelectMesage("Please select any reason");
      return;
    }

    if (selectedReason === "OTHER" && !otherReasonText.trim()) {
      setSelectMesage("Please provide a reason in the text field");
      return;
    }

    const tickeId = return_id ? { id: show, r_id: return_id } : { id: show }

    if ((status === "ACCEPT" || status === "REINSTATE") && (currentUpdate === "current")) {
      setLoading(true)
      updateFlightPair(acceptData).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setLoading(false)
          setShow(false)
          // setPaymentShow(true)
          // navigate("/dashboard")
          allBookingData();
          toast.success(
            `Booking accepted
             successfully`,
            {
              theme: "colored",
            }
          );
          setStatus(null)
          return;
        } else {
          setLoading(false)
          setShow(false)
          toast.error(`Failed to accept status`, {
            theme: "colored",
          });
        }
      }).catch((error) => {
        console.log(error)
        toast.error(`Failed to accept status`, {
          theme: "colored",
        });
        setLoading(false)
        setShow(false)
      })
    } else {
      if ((status === "ACCEPT" || status === "REINSTATE") && acceptData?.child_flight?.length > 0) {
        setLoading(true)

        createFlightPair(acceptData).then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            setLoading(false)
            setShow(false)
            // navigate("/dashboard")
            setPaymentShow(true)
            toast.success(
              `Booking accepted
              successfully`,
              {
                theme: "colored",
              }
            );
            allBookingData();
            setStatus(null)
          } else {
            setLoading(false)
            setShow(false)
            toast.error(`Failed to accept status`, {
              theme: "colored",
            });
          }
        }).catch((error) => {
          console.log(error)
          toast.error(`Failed to accept status`, {
            theme: "colored",
          });
          setLoading(false)
          setShow(false)
        })
        // }

      } else {
        setLoading(true)
        updateSubstituteStatus(substituteId, {
          status: status === "REINSTATE" ? "ACCEPT" : status,
          additional_information: selectedReason,
          other_reason_text: selectedReason === "OTHER" ? otherReasonText : null,
        })
          .then((res) => {
            setLoading(false)
            if (res.status === 200) {
              // allBookingData();

              toast.success(
                `Status ${status === "ELIMINATE"
                  ? `${status?.toLowerCase()}d`
                  : `${status?.toLowerCase()}ed`
                } successfully`,
                {
                  theme: "colored",
                }
              );
              if (status === "ACCEPT" || status === "REINSTATE") {
                setPaymentShow(true)
              } else {
                allBookingData();
              }
              setShow(false);
              setSelectedReason("");
              setOtherReasonText("");
              setSelectMesage("");
            } else {
              setLoading(false)
              toast.error(`Failed to ${status?.toLowerCase()} status`, {
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false)
          });
      }
    }
  };

  const handleSubstituteDetail = (id, num, price, data, layover) => {
    setData(data)
    setNumStops(num)
    setFlightShow(true)
    setLayoverPrice(price)
    setLayoverData(layover)
    setSubId(id)
  }
  return (
    <>
      <MDTypography variant="p" className="p-title">
        Highest Scoring Matches
      </MDTypography>
      {loading ? (
        <>
          <h5>Loading ..</h5>
        </>
      ) : (
        <>
          {displayedItems?.length > 0 ? (
            displayedItems?.map((ticket, index) => {
              const firstReturnFlightIndex = details?.children?.findIndex(c => c?.is_return_flight === true);
              const retSubstitues = details?.children?.length > 0 && firstReturnFlightIndex >= 0 ? true : false
              return (
                <div key={index} className="container-fluid p-3 mb-3 subtiktmain position-relative">
                  <div className="high_matches">
                    <p className="mb-0"> <strong className="match_percent">{ticket?.outbound_offer?.match_percentage} Score</strong></p>
                  </div>
                  <div
                    className="row align-items-center"
                    style={{ alignItems: "center", marginTop: "30px" }}
                  >
                  
                    <div className="col-md-6 col-lg-7">
                      <FlightSubstitute item={displayedItems} ticket={ticket?.outbound_offer} getTime={getTime}
                        handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                        ticketStatuses={ticketStatuses} index={index} />
                    </div>
                    <PriceDetails item={displayedItems} ticket={ticket?.return_offer ? ticket : ticket?.outbound_offer} getTime={getTime}
                      handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                      ticketStatuses={ticketStatuses} index={index} />
                    <UserStatus returnID={retSubstitues} ticketStatuses={ticketStatuses} index={index} item={displayedItems} ticket={ticket?.outbound_offer} handleStatus={handleStatus} />
                  </div>
                </div>
              )
            })
          ) : (
            <p>No substitute offers available</p>
          )}

          {displayedItems?.length > 0 && (
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          )}
        </>
      )}

      <FlightDetailModal numStops={numStops} data={data} subId={subId} setSubId={setSubId} flightshow={flightshow} setFlightShow={setFlightShow} handleClose={flightHandleClose} />

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {status === "ACCEPT" && (
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            )}

            {status === "REJECT" && (
              <MDTypography variant="p" className="delete_msj">
                Please let us know why you are Rejecting this offer.
              </MDTypography>
            )}

            {status === "ELIMINATE" && (
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            )}
            {
              status === "REINSTATE" && (
                <MDTypography variant="p" className="delete_msj">
                  Are you sure? Would you like to reinstate this flight?
                </MDTypography>
              )
            }

            {status !== "ACCEPT" && status !== "REINSTATE" && (
              <div className="my-3 text-left">
                <FormControl fullWidth className="select_reason_input">
                  <InputLabel id="reason-select-label">
                    Select Reason
                  </InputLabel>
                  <Select
                    labelId="reason-select-label"
                    value={selectedReason}
                    onChange={handleChange}
                    label="Select Reason"
                    className="select_reason_input"
                  >
                    {REASON_CHOICES?.map((reason) => (
                      <MenuItem key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedReason === "OTHER" && (
                  <TextField
                    fullWidth
                    label="Please specify the reason"
                    variant="outlined"
                    value={otherReasonText}
                    onChange={(e) => {
                      setOtherReasonText(e.target.value);
                      setSelectMesage("");
                    }}
                    className="other_reason_input mt-3"
                    multiline
                    rows={3}
                  />
                )}
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            )}

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              {status === "ACCEPT" ? "No" : "Cancel"}
            </Button>
            {
              status === "ACCEPT" ?
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => handleUpdateStatus()}
                >
                  Yes
                </Button>
                :
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                > Update
                </Button>
            }
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default UserSubstitute;
