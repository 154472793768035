import MDTypography from "components/MDTypography";
import React from "react";
import MDButton from "components/MDButton";
import Matched from "assets/img/matched-icon.png";
import Animities from "assets/img/animities-icon.png";
import moment from "moment";

const CarSubstitute = ({ data, handleStatus, ticketStatuses, index, userStatus }) => {
    return (
        <>
            <div className="hotel_header substitutes_card_flight row" style={{ paddingBottom: "0px !important" }}>
                <div className="hotel_name_title col-md-5">
                    <div className="car_name_section">
                        <MDTypography
                            variant="h6"
                            className="hotel_name"
                        >
                            {data?.vehicle_description}
                        </MDTypography>
                        <MDTypography
                            variant="p"
                            className="hotel_address"
                        >
                            {/* {hotel?.hotel_address} */}
                        </MDTypography>
                        <div className="ticket_details mt-2">
                            <MDTypography
                                variant="p"
                                className="ticket_title"
                            >
                                Pick up: <strong className="mx-1">{moment(data?.start_date_time).format("L")}, {moment(data?.start_date_time).format("LT")}</strong>
                                {data?.start_address_line}
                            </MDTypography>
                        </div>
                        <div className="ticket_details">
                            <MDTypography
                                variant="p"
                                className="ticket_title mt-1"
                            >
                                Drop Off: <strong className="mx-1">{moment(data?.end_date_time).format("L")}, {moment(data?.end_date_time).format("LT")}</strong>
                                {data?.end_address_line}
                            </MDTypography>
                        </div>
                    </div>

                    <div className="row align-items-center ">
                        <div className="hotel_price col-md-6">
                            <MDTypography
                                variant="p"
                                className="fstptag"
                            >
                                {data?.quotation_monetary_amount ? `${data?.quotation_currency_code} ${data?.quotation_monetary_amount}` : ""}
                            </MDTypography>
                            <p
                                className="sndptag"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                You Can Save{" "}
                                <span
                                    style={{
                                        color: "#61a108",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {data?.saved_amount ? `$${parseFloat(data?.saved_amount)?.toFixed(2)}` : ""}
                                </span>
                            </p>
                        </div>

                        {/* <div className="col-md-6 adult_class_name">
                            <MDTypography
                                variant="p"
                                className="ticket_title"
                            >1 Adult
                            </MDTypography>
                            <span className="mx-1">|</span>
                            <MDTypography
                                variant="p"
                                className="ticket_title text-capitalize"
                            >Economy
                            </MDTypography>
                        </div> */}
                    </div>
                </div>
                {/* <div className="col-md-5 py-2 px-2">

                    <div className="animities_section">
                        <div className="ticket_details">
                            <img src={Animities} />
                            <MDTypography
                                variant="p"
                                className="ticket_title ms-1"
                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    Amenities:
                                </span>

                            </MDTypography>
                        </div>
                        <ul className="row">
                            <li
                                className="col-md-12 hotel_desc_list">
                                GPS Navigation
                            </li>
                            <li
                                className="col-md-12 hotel_desc_list">
                                Air Conditioning
                            </li>
                            <li
                                className="col-md-12 hotel_desc_list">
                                Bluetooth Connectivity
                            </li>
                            <li
                                className="col-md-12 hotel_desc_list">
                                Backup Camera
                            </li>
                            <li
                                className="col-md-12 hotel_desc_list">
                                Cruise Control
                            </li>
                            <li
                                className="col-md-12 hotel_desc_list">
                                Child Seat & Leather Seats
                            </li>

                        </ul>
                    </div>
                    {hotel?.description_text
                        ?.split(",")
                        .map((value) => {
                            if (value !== "") {
                                return (
                                    <li
                                        className="col-md-12 hotel_desc_list"
                                        key={index}
                                    >
                                        {value}
                                    </li>
                                );
                            }
                        })}
                </div> */}

                {
                    userStatus ?
                        <div className="col-md-2 status_butn_section">
                            <div className="col-md-12 d-flex justify-content-end  airlinetkt ">
                                <div className="col-md-12 text-end">
                                    <div className="flight_detail_butns">
                                        {data?.status === "PENDING" &&
                                            <MDButton
                                                className="accept_butn"
                                            >
                                                PENDING
                                            </MDButton>
                                        }

                                        {data?.status === "ELIMINATE" &&
                                            <MDButton
                                                className="eliminated_butn"
                                            >
                                                ELIMINATED
                                            </MDButton>
                                        }

                                        {data?.status === "ACCEPT" &&
                                            <MDButton
                                                className="accept_butn accepted_butn"
                                            >
                                                ACCEPTED
                                            </MDButton>
                                        }

                                        {data?.status === "REJECT" &&
                                            <MDButton
                                                className="rejected_butn"
                                            >
                                                REJECTED
                                            </MDButton>
                                        }

                                        {/* {
                                            hotel?.additional_information === "OTHER" ?
                                                <p className="status_reason mb-0">{hotel?.other_reason_text}</p>
                                                :
                                                <>
                                                    {
                                                        status?.map((item, i) => {
                                                            if (item?.name === hotel?.additional_information) {
                                                                return (
                                                                    <>
                                                                        <p key={i} className="status_reason mb-0">{item?.value}</p>
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }

                                                </>
                                        } */}


                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {data?.status === "PENDING" ? (
                                <div className="col-md-2 d-flex airlinetkt">
                                    <div className="col-md-12">
                                        <div className="hotel_detail_butns">
                                            <MDButton
                                                className="accept_butn"
                                                onClick={() =>
                                                    handleStatus(data.id, "ACCEPT")
                                                }
                                                disabled={
                                                    ticketStatuses[data.id] ||
                                                    data.status !== "PENDING"
                                                }
                                            >
                                                ACCEPT
                                            </MDButton>
                                            <MDButton
                                                className={`reject_butn`}
                                                onClick={() =>
                                                    handleStatus(data.id, "REJECT")
                                                }
                                                disabled={
                                                    ticketStatuses[data.id] ||
                                                    data.status !== "PENDING"
                                                }
                                            >
                                                REJECT
                                            </MDButton>
                                            <MDButton
                                                className="eliminate_butn"
                                                onClick={() =>
                                                    handleStatus(
                                                        data.id,
                                                        "ELIMINATE"
                                                    )
                                                }
                                                disabled={
                                                    ticketStatuses[data.id] ||
                                                    data.status !== "PENDING"
                                                }
                                            >
                                                ELIMINATE
                                            </MDButton>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="col-md-2 car_status_butn  airlinetkt">
                                        <div className="col-md-12 text-end">
                                            <div className="hotel_detail_butns">
                                                <MDButton
                                                    className={`accept_butn ${data?.status === "ACCEPT" &&
                                                        "accepted_butn"
                                                        }`}
                                                    disabled={data?.status === "ACCEPT"}
                                                // onClick={() =>
                                                //     handleStatus(
                                                //         hotel.id,
                                                //         "ACCEPT"
                                                //     )
                                                // }
                                                >
                                                    {data?.status === "ACCEPT"
                                                        ? "ACCEPTED"
                                                        : "ACCEPT"}
                                                </MDButton>

                                                {data?.status !== "ACCEPT" &&
                                                    <>
                                                        <MDButton
                                                            className={`${data?.status === "REJECT"
                                                                ? "rejected_butn"
                                                                : "reject_butn"
                                                                }`}
                                                            disabled={data?.status === "REJECT"}
                                                        // onClick={() =>
                                                        //     handleStatus(
                                                        //         hotel.id,
                                                        //         "REJECT"
                                                        //     )
                                                        // }
                                                        >
                                                            {data?.status === "REJECT"
                                                                ? "REJECTED"
                                                                : "REJECT"}
                                                        </MDButton>

                                                        <MDButton
                                                            className={`${data?.status === "ELIMINATE"
                                                                ? "eliminated_butn"
                                                                : "eliminate_butn"
                                                                }`}
                                                            disabled={data?.status === "ELIMINATE"}
                                                        // onClick={() =>
                                                        //     handleStatus(
                                                        //         hotel.id,
                                                        //         "ELIMINATE"
                                                        //     )
                                                        // }
                                                        >
                                                            {data?.status === "ELIMINATE"
                                                                ? "ELIMINATED"
                                                                : "ELIMINATE"}
                                                        </MDButton>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                }
            </div>

        </>
    )
}

export default CarSubstitute;