import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { encodeIdWithType } from "utils/encoder";
import { getPermissionByID } from "utils/api";
import { bookingInformation } from "utils/api";
import FlightDetails from "components/FlightDetails";
import Hoteldetail from "components/HotelDetail";
import Cardetail from "components/CarDetails";
import { Form } from "react-bootstrap";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Flight Bookings", "Hotel Bookings", "Car Bookings"];

const booking_type = ["Live", "Past"];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const Bookings = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = useState("Flight Bookings");
  const [bookingType, setBookingType] = useState("Live");
  const [pageData, setPageData] = useState([]);
  const [deletPermision, setDeletPermision] = useState(false);
  const [updatePermision, setUpdatePermision] = useState(false);
  const [userType, setUserType] = useState(null);
  const [show, setShow] = useState(false);

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(pageData?.length / itemsPerPage);

  const displayedItems = pageData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const bookingDetails = () => {
    setLoading(true);
    bookingInformation("live")
      .then((res) => {
        setLoading(false);
        setUser(res.data);
        const filteredData = res.data.flight_data?.filter(
          (flight) => flight?.parent_booking === null && flight?.is_show_data
        )?.reverse();
        setPageData(filteredData);
      })
      .catch((error) => {
        setLoading(false);
        console.log("booking error", error);
      });
  };

  useEffect(() => {
    bookingDetails();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => { });

  const handleChange = (event) => {
    setPage(1);
    const {
      target: { value },
    } = event;
    setPersonName(value);
    if (value === "Car Bookings") {
      setPageData(user?.car_data?.reverse());
    } else if (value === "Hotel Bookings") {
      setPageData(user?.hotel_data?.filter(flight => flight?.is_show_data)?.reverse());
    } else {
      const filteredData = user?.flight_data?.filter(
        (flight) => flight?.parent_booking === null && flight?.is_show_data
      )?.reverse();
      setPageData(filteredData);
    }
  };

  const handleBookingType = (event) => {
    setPage(1);
    const {
      target: { value },
    } = event;
    setBookingType(value);
    if (value === "Past") {
      setLoading(true);
      bookingInformation("past")
        .then((res) => {
          setLoading(false);
          setUser(res.data);
          if (personName === "Car Bookings") {
            setPageData(res.data?.car_data);
          } else if (personName === "Hotel Bookings") {
            setPageData(res.data?.hotel_data);
          } else {
            setPageData(res.data?.flight_data);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("booking error", error);
        });
    } else if (value === "Live") {
      setLoading(true);
      bookingInformation("live")
        .then((res) => {
          setLoading(false);
          setUser(res.data);
          if (personName === "Car Bookings") {
            setPageData(res.data?.car_data);
          } else if (personName === "Hotel Bookings") {
            setPageData(res.data?.hotel_data);
          } else {
            setPageData(res.data?.flight_data);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("booking error", error);
        });
    }
  };

  const redirection = (id, type) => {
    let final_id = encodeIdWithType(id, type);
    navigate(`/booking-details/${final_id}`);
  };

  useEffect(() => {
    getUserPermission();
  }, []);

  const getUserPermission = () => {
    const user = JSON.parse(localStorage.getItem("active-user"));
    if (user) {
      setUserType(user.user_type);
      getPermissionByID(user.id)
        .then((res) => {
          const data = res?.data?.permissions;
          const updateUser = data?.filter((item) => {
            return item.codename === "change_user";
          });
          setUpdatePermision(updateUser?.[0]);
          const deleteUser = data?.filter((item) => {
            return item.codename === "delete_user";
          });
          setDeletPermision(deleteUser?.[0]);
        })
        .catch((error) => {
          console.error("Error fetching permission:", error);
        });
    }
  };

  const updatebooking = (id) => {
    // let final_id = encodeIdWithType(390, "flights");
    // navigate(`/price-monitor/${final_id}`);
    let final_id = encodeIdWithType(id);
    navigate(`/update-booking/${id}`);
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox my={2}>
          <div className="user_name_icons">
            <div className="user_detail_section">
              <MDTypography varient="h1" className="user_fullname">
                Bookings
              </MDTypography>
            </div>
          </div>

          <Card className="mt-4">
            <MDBox py={2} px={2}>
              <div className="filters_sections">
                <div className="d-flex gap-2">
                  {/* <MDInput placeholder="Search" className="category_filter" /> */}
                  <Form.Group className="status-select-container" controlId="booking-select">
                    <Form.Select
                      value={bookingType}
                      onChange={handleBookingType}
                      className="custom-status-select"
                    >
                      {booking_type?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="status-select-container" controlId="category-select">
                    <Form.Select
                      value={personName}
                      onChange={handleChange}
                      className="custom-status-select"
                    >
                      {names?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              {personName === "Flight Bookings" && (
                <div className="mt-5">
                  {displayedItems?.length > 0
                    ? displayedItems
                      ?.map((flight) => {
                        return (
                          <div className="user_flight_substitutes_bg py-3 ps-3">
                            <MDTypography variant="p"
                              color="white"
                              className="total_price ms-2 mb-1">Booking Confirmation: <strong>{flight?.confirmation_code}</strong></MDTypography>
                            <div onClick={() => redirection(flight?.id, "flights")} className="airline_header row justify-content-start align-items-center p-0">
                              <div className="col-md-8 col-lg-9 flight_detail_bg">
                                {
                                  flight?.children?.length > 0 ?
                                    <>
                                      <div className="outbound_section">
                                        <span className="outbound_booking">Outbound flight</span>
                                        <i onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          updatebooking(flight?.id)
                                        }
                                        } className="bi bi-pencil-square main_booking_edit_icon"></i>
                                        <FlightDetails item={flight} />
                                        {
                                          flight?.children?.sort((a, b) => a.id - b.id)?.map((child, i) => {
                                            if (child?.is_return_flight === false) {
                                              return (
                                                <FlightDetails item={child} />
                                              )
                                            }
                                          })
                                        }
                                      </div>

                                      {
                                        flight?.children?.map((child, i) => {
                                          const firstReturnFlightIndex = flight?.children?.findIndex(c => c?.is_return_flight === true);
                                          if (child?.is_return_flight === true) {
                                            return (
                                              <>
                                                {
                                                  i === firstReturnFlightIndex &&
                                                  <div className="outbound_section">
                                                    <div className="children_data"></div>
                                                    <span className="outbound_booking">Return flight</span>
                                                  </div>
                                                }
                                                <FlightDetails children={"children"} item={child} />
                                              </>
                                            )
                                          }
                                        })
                                      }
                                    </>
                                    :
                                    <div className="outbound_section">
                                      <span className="outbound_booking">Outbound flight</span>
                                      <i onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        updatebooking(flight?.id)
                                      }
                                      } className="bi bi-pencil-square main_booking_edit_icon"></i>
                                      <FlightDetails oneWay={"oneWay"} item={flight} />
                                    </div>
                                }

                              </div>
                              <div className="col-md-4 col-lg-3 main_price">
                                <div className="row px-2 align-items-center">
                                  <div className="col-10">
                                    <div className="price_section_paid ">
                                      <span><span className="total_price">{flight?.price ? "Price paid" : ""}</span></span>
                                      <MDTypography
                                        variant="h6"
                                        color="white"
                                        className="flight_details main_flight_price"
                                      >
                                        {`$${flight?.price?.replace("$", "")?.replace("USD", "")}`}
                                      </MDTypography>
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-right-circle-fill next_right_icon" viewBox="0 0 16 16">
                                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )

                      })
                    :
                    <div className="text-center mt-3">
                      <MDTypography variant="h5">You don't have any Bookings at the moment!</MDTypography>
                    </div>
                  }
                </div>
              )}

              {personName === "Hotel Bookings" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems.map((hotel, index) => {
                      return (
                        <div
                          className="hotel_detail_bg mt-4"
                          onClick={() => redirection(hotel?.id, "hotels")}
                        >
                          <Hoteldetail item={hotel} />
                        </div>
                      );
                    })
                    :
                    <div className="text-center mt-3">
                      <MDTypography variant="h5">You don't have any Bookings at the moment!</MDTypography>
                    </div>}
                </>
              )}

              {personName === "Car Bookings" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems?.map((car) => {
                      return (
                        <Card className="mt-4">
                          <div
                            className="car_main_header px-3 py-2"
                            onClick={() => redirection(car?.id, "cars")}
                          >
                            <Cardetail item={car} carBooking={"carBooking"} />

                          </div>
                        </Card>
                      );
                    })
                    : ""}
                </>
              )}

              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </MDBox>
          </Card>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default Bookings;
