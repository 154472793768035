import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Logo from "assets/images/auth/logo.png";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { passwordReset } from "utils/api";
import MDAlert from "components/MDAlert";
import { toast } from "react-toastify";

function ResetPassword() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });

  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false)

  const validate = (name, value) => {
    let error = "";

    if (name === "email") {
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(value)) {
        error = "Invalid email address";
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleFormdata = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
    validate(name, value);
  };

  const handleConfirm = () => {
    validate("email", formdata.email);

    if (!errors.email) {
      if (formdata?.email) {
        setLoading(true)
        passwordReset(formdata)
          .then((res) => {
            setLoading(false)
            if (res.status === 200) {
              toast.success(res.data.message, {
                theme : "colored"
              })
              setTimeout(() => {
                navigate("/login")
              }, 3000)
            } else {
              toast.error(res?.data?.email[0], {
                theme : "colored"
              });
            }
          })
          .catch((error) => {
            setLoading(false)
            console.log("error", error);
          });
      }
    } else {
      console.log("Validation errors", errors);
    }
  };


  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      <MDTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        {showAlert}
      </MDTypography>
    </MDTypography>
  );

  return (
    <CoverLayout>
      <>
        <div style={{ position: "absolute", right: "25px", top: "20px" }}>
          {showAlert && (
            <MDAlert color="info" dismissible>
              {alertContent("info")}
            </MDAlert>
          )}
          {errorAlert && (
            <MDAlert color="info" dismissible>
              {alertContent("error")}
            </MDAlert>
          )}
        </div>
        <div className="main_file">
          <div className="bg_login1">
            <div className="bg_image_login">
              <img src={Logo} />
            </div>
          </div>
          <div className="login_form">
            <div>
              <MDTypography
                variant="h3"
                fontWeight="medium"
                color="#344585"
                mt={1}
              >
                Forgot password?
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                color="blue"
                my={1}
              >
                Enter the email address you used when you joined and we’ll{" "}
                <br /> send you instructions to reset your password.
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                color="blue"
                my={1}
              >
                For security reasons, we do NOT store your password. So <br />{" "}
                rest assured that we will never send your password via email.
              </MDTypography>
              <div>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={formdata.email}
                  onChange={handleFormdata}
                  className="input_design"
                />

                {errors.email && (
                  <>
                    <br />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors.email}
                    </span>
                  </>
                )}
              </div>
              <div className="sign_butn">
                {
                  !loading ? (
                    <MDButton
                      onClick={() => {
                        handleConfirm();
                      }}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Confirm Email
                    </MDButton>
                  ) : (
                    <MDButton
                      disabled
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Confirming ..
                    </MDButton>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </>
    </CoverLayout>
  );
}

export default ResetPassword;
