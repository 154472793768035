import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { Button, createTheme } from "@mui/material";
import { userPermission } from "utils/api";
import { toast } from "react-toastify";
import { getPermissionByID } from "utils/api";
import Loader from "components/Loader";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";

function UpdatePermission() {
  const theme = useTheme();
  const [adminData, setAdminData] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    getPermissionByID()
      .then((res) => {

        const users = res?.data?.user_permissions || []; 
        setAdminData(users);
        const formattedPermissions = {};
        users.forEach((user) => {
          formattedPermissions[user?.email] = {
            add: user.permissions.some((perm) => perm.codename === "add_user"),
            view: user.permissions.some((perm) => perm.codename === "view_user"),
            change: user.permissions.some((perm) => perm.codename === "change_user"),
            delete: user.permissions.some((perm) => perm.codename === "delete_user"),
          };
        });

        setPermissions(formattedPermissions);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);



  const handleCheckboxChange = (userId, permissionType) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [userId]: {
        ...prevPermissions[userId],
        [permissionType]: !prevPermissions[userId][permissionType],
      },
    }));
  };

 

  const [selectedAdmins, setSelectedAdmins] = useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    const selectedItems = adminData
      .filter((item) => value.includes(item?.email))
      .map((item) => ({ id: item?.id, email: item?.email }));

    setSelectedAdmins(selectedItems);
    setLoading(true);
    getPermissionByID(selectedItems[0]?.id)
      .then((res) => {
        setLoading(false);
        if (res?.data?.permissions?.length > 0) {
          const updatedPermissions = {
            add: false,
            view: false,
            change: false,
            delete: false,
          };
          res?.data?.permissions?.forEach((permission) => {
            if (permission.codename.includes("add")) {
              updatedPermissions.add = true;
            } else if (permission.codename.includes("view")) {
              updatedPermissions.view = true;
            } else if (permission.codename.includes("change")) {
              updatedPermissions.change = true;
            } else if (permission.codename.includes("delete")) {
              updatedPermissions.delete = true;
            }
          });
          setPermissions(updatedPermissions);
        } else {
          setPermissions({
            add: false,
            view: false,
            change: false,
            delete: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  const handleSubmit = () => {
    const updatedPermissionsArray = Object.keys(permissions).map((email) => {
      const user = adminData.find((user) => user.email === email);
      if (!user) return null;

      const userId = user.user_id;
      const oldPermissions = user.permissions.map((perm) => perm.codename);
      const newPermissions = Object.entries(permissions[email])
        .filter(([_, hasPermission]) => hasPermission)
        .map(([key]) => `${key}_user`);

      const grantedPermissions = newPermissions.filter((perm) => !oldPermissions.includes(perm));
      const revokedPermissions = oldPermissions.filter((perm) => !newPermissions.includes(perm));

      const userPermissions = [];
      if (grantedPermissions.length > 0) {
        userPermissions.push({
          action: "grant",
          permissions_codenames: grantedPermissions,
        });
      }
      if (revokedPermissions.length > 0) {
        userPermissions.push({
          action: "revoke",
          permissions_codenames: revokedPermissions,
        });
      }

      return userPermissions.length > 0 ? { user_id: userId, permissions: userPermissions } : null;
    }).filter(Boolean);

    const formattedData = { users: updatedPermissionsArray };
    setLoading(true)
    userPermission(formattedData)
      .then((response) => {
        setLoading(false)
        if (response.status == 200) {
          toast.success("Permissions updated successfully", {
            theme: "colored"
          });
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error updating permissions:", error);
      });
  };



  const columns = [
    { name: "email", label: "Email" },
    {
      name: "add",
      label: "Add",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const userId = adminData[tableMeta.rowIndex]?.email;
          return (
            <Checkbox
              checked={permissions[userId]?.add || false}
              onChange={() => handleCheckboxChange(userId, "add")}
            />
          );
        },
      },
    },
    {
      name: "view",
      label: "List",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const userId = adminData[tableMeta.rowIndex]?.email;
          return (
            <Checkbox
              checked={permissions[userId]?.view || false}
              onChange={() => handleCheckboxChange(userId, "view")}
            />
          );
        },
      },
    },
    {
      name: "change",
      label: "Update",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const userId = adminData[tableMeta.rowIndex]?.email;
          return (
            <Checkbox
              checked={permissions[userId]?.change || false}
              onChange={() => handleCheckboxChange(userId, "change")}
            />
          );
        },
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const userId = adminData[tableMeta.rowIndex]?.email;
          return (
            <Checkbox
              checked={permissions[userId]?.delete || false}
              onChange={() => handleCheckboxChange(userId, "delete")}
            />
          );
        },
      },
    },
    // {
    //   name: "payment",
    //   label: "Payment",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       const userId = adminData[tableMeta.rowIndex]?.user_id;
    //       return (
    //         <Checkbox
    //           checked={permissions[userId]?.payment || false}
    //           onChange={() => handleCheckboxChange(userId, "payment")}
    //         />
    //       );
    //     },
    //   },
    // },
  ];


  const options = {
    // filterType: "textField", 
    selectableRows: "none",
    responsive: "standard",
    print: false,
    download: false,
    viewColumns: false,
    search: true,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              textAlign: "left", // left content of table cells
            },
            head: {
              fontWeight: "bold",
              textAlign: "left", // left header cells
            },
          },
        },
      },
    });


  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {/* <MDBox pt={3} mx={3} my={3}>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">Admin</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedEmails}
                  onChange={handleChange}
                  input={<OutlinedInput label="Email" />}
                >
                  {adminData?.length > 0 ? (
                    adminData?.map((item) => (
                      <MenuItem key={item.id} value={item.email}>
                        {item.email}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Data</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </MDBox> */}

          <MDBox mx={4}>
            <Grid container>
              {/* <Grid item md={3}>
                <MDTypography variant="h6">User</MDTypography>
              </Grid>
              <Grid item md={9}>
                <FormGroup className="permission_checkbox">
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.add}
                          onChange={handleCheckboxChange}
                          name="add"
                        />
                      }
                      label="Add"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.view}
                          onChange={handleCheckboxChange}
                          name="view"
                        />
                      }
                      label="List"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.change}
                          onChange={handleCheckboxChange}
                          name="change"
                        />
                      }
                      label="Update"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.delete}
                          onChange={handleCheckboxChange}
                          name="delete"
                        />
                      }
                      label="Delete"
                    />
                  </div>
                </FormGroup>
              </Grid> */}
            </Grid>
            {/* <Grid container>
                  <Grid item md={3}>
                    <MDTypography variant="h6">Booking</MDTypography>
                  </Grid>
                  <Grid item md={9}>
                    <FormGroup className="permission_checkbox_booking">
                      <div>
                        <FormControlLabel control={<Checkbox />} label="List" />
                      </div>
                    </FormGroup>
                  </Grid>
                </Grid> */}

          </MDBox>

          <div className="table-container">
            <div className="custom-table">
              {adminData?.length > 0 ? (
                <div className="tableContainer">
                  <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                      title=""
                      data={adminData}
                      options={options}
                      columns={columns}
                      className="user_data_table"
                    />
                  </ThemeProvider>
                </div>
              )
                :
                <div className="text-center mt-5">
                  <MDTypography variant="h3">No Data Found</MDTypography>
                </div>
              }
            </div>
            <div className="text-end mt-3 pe-2">
              <Button
                type="button"
                onClick={(e) => handleSubmit(e)}
                className="submit_buton"
              >
                Save
              </Button>
            </div>
            {/* <MDBox mt={3} display="flex" justifyContent="center" pb={2}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                      />
                    </MDBox> */}
          </div>
        </>
      )}
    </>
  );
}

export default UpdatePermission;
