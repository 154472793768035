import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import { Button, Card, TextField, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";
import { toast } from "react-toastify";
import { updateHotelStatus } from "utils/api";
import { Form, Modal } from "react-bootstrap";
import Hoteldetail from "components/HotelDetail";
import HotelSubstitute from "components/HotelDetail/hotelSubstitute";
import NoAccessModal from "components/NoAccessModal";
import Loader from "components/Loader";

function HotelBook({ hotels, loading, allBookingData }) {


  const updatedData = hotels?.filter(show => show?.is_show_data === true)?.map((hotel) => ({
    ...hotel,
    substitutes: hotel?.substitutes?.filter(
      (substitute) => substitute?.status !== "ELIMINATE"
    ),
  }));

  const [updateLoading, setUpdateLoading] = React.useState(false)
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("")
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case 'ELIMINATE':
        return [
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['NOT_ACCEPTABLE_HOTEL', 'Not Acceptable Hotel'],
          ['OTHER', 'Other'],
        ];
      case 'REJECT':
        return [
          ['WAITING', 'Waiting/Not Enough Saving'],
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['OTHER', 'Other'],
        ];
      default:
        return [
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['NOT_ACCEPTABLE_HOTEL', 'Not Acceptable Hotel'],
          ['OTHER', 'Other'],
          ['WAITING', 'Waiting/Not Enough Saving'],
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
        ];
    }
  }, [status]);

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== 'OTHER') {
      setOtherReasonText("");
    }
  };
  const navigate = useNavigate();

  const [ticketStatuses, setTicketStatuses] = React.useState({});

  const [show, setShow] = React.useState(false);


  const handleClose = () => {
    setShow(false);
    setSelectedReason("");
    setOtherReasonText("");
    setSelectMesage("");
  };

  const handleStatus = (id, status) => {
    setShow(id);
    setStatus(status);
  };

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT") {
      setSelectMesage("Please select any reason");
      return;
    }
    if (selectedReason === 'OTHER' && !otherReasonText.trim()) {
      setSelectMesage("Please provide a reason in the text field");
      return;
    }
    setUpdateLoading(true)
    updateHotelStatus(show, {
      status: status,
      additional_information: selectedReason,
      other_reason_text: selectedReason === 'OTHER' ? otherReasonText : null
    })
      .then((res) => {
        
        if (res.status === 200 || res.status === 201) {
          setUpdateLoading(false)
          setSelectedReason("");
          setOtherReasonText("");
          setSelectMesage("");
          setShow(false);

          toast.success(
            `Status ${status === "ELIMINATE"
              ? `${status?.toLowerCase()}d`
              : `${status?.toLowerCase()}ed`
            } successfully`,
            {
              theme: "colored",
            }
          );
          if (status === "ACCEPT") {
            setShow(false);
            setPaymentShow(true)
            setSelectedReason("");
            setOtherReasonText("");
            setSelectMesage("");
          } else {
            setShow(false);
            allBookingData();
            setSelectedReason("");
            setOtherReasonText("");
            setSelectMesage("");
          }
          setShow(false)
        } else {
          setUpdateLoading(false)
          toast.error(`Failed to ${status?.toLowerCase()} status`, {
            theme: "colored",
          });
          setShow(false);
          setSelectedReason("");
          setOtherReasonText("");
          setSelectMesage("");
        }
      })
      .catch((error) => {
        console.log(error);
        setShow(false);
        setUpdateLoading(false)
      });
  }

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "hotels");
    navigate(`/price-monitor/${final_id}`);
    localStorage.setItem("active-tab", 2)
  };


  return (
    <MDBox py={3}>
      <MDTypography variant="h6">My Bookings</MDTypography>
      {
        updateLoading && 
        <Loader/>
      }
      {loading ? (
        <div className="text-center d-flex justify-content-center mt-3">
          <div className="hotel-loader"></div>
        </div>
      )
        :
        <div>
          {updatedData?.length > 0
            ? updatedData?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))?.map((item, i) => {
              return (
                <Accordion className="mb-3 flight_substitutes_bg" defaultExpanded={i === 0} key={i} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="m-0 hotel_detail_section"
                  // onClick={()=>{getAmadeusHotels(item.id)}}
                  >
                    <Hoteldetail item={item} />
                  </AccordionSummary>
                  <AccordionDetails className="substitues_section">
                    <div className="substitutes my-2">
                      <MDTypography variant="h6" color="white">
                        Highest Scoring Matches
                      </MDTypography>
                      {item?.substitutes && item?.substitutes?.length > 0 ? (
                        <button
                          className="view_all"
                          onClick={() => {
                            redirection(item.id);
                          }}
                        >
                          View All
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {(!item?.substitutes) ? (
                      <>
                        <MDTypography
                          className="text-center d-flex gap-1 justify-content-center"
                          variant="h5"
                          color="white"
                        >
                          Loading <div className="loading_data"></div>
                        </MDTypography>
                      </>
                    ) : (
                      <>
                        {item.substitutes?.length > 0 ? (
                          item?.substitutes?.slice(0, 3)?.map((hotel, index) => {
                            return (
                              <Card className="hotel_cards my-2 position-relative">
                                <div className="high_matches_hotel">
                                  <p className="mb-0"> <strong className="match_percent">{hotel?.match_percentage} Score</strong></p>
                                </div>
                                <div className="mt-4">
                                  <HotelSubstitute hotel={hotel} index={index} ticketStatuses={ticketStatuses} handleStatus={handleStatus} />
                                </div>
                              </Card>
                            );
                          })
                        ) : (
                          <p className="no_substitute">
                            No substitute available
                          </p>
                        )}
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })
            :
            <div className="text-center py-3">
              <MDTypography variant="h4">You don't have any Bookings at the moment!</MDTypography>
            </div>
          }
        </div>
      }


      {
        paymentShow && (
          <NoAccessModal userdash={"userdash"} show={paymentShow} setShow={setPaymentShow} allBookingData={allBookingData} />
        )
      }


      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            <MDTypography variant="h5">Are you Sure?</MDTypography>
            {status === "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status === "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Please let us know why you are Rejecting this offer.
              </MDTypography>
            }

            {status === "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }

            {status !== "ACCEPT" &&
              <div className="my-3 text-left">
                <Form.Group className="select_reason_input" controlId="reason-select">
                  <Form.Select value={selectedReason} onChange={handleChange} className="custom-select">
                    <option value="">Select Reason</option> {/* Placeholder option */}
                    {REASON_CHOICES?.map((reason) => (
                      <option key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {selectedReason === 'OTHER' && (
                  <TextField
                    fullWidth
                    label="Please specify the reason"
                    variant="outlined"
                    value={otherReasonText}
                    onChange={(e) => {
                      setOtherReasonText(e.target.value);
                      setSelectMesage(""); // Clear any previous error messages
                    }}
                    className="other_reason_input mt-3"
                    multiline
                    rows={3}
                  />
                )}
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            }

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleUpdateStatus();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </MDBox>
  );
}

export default HotelBook;
