import React from 'react';
import './Features.css';
import trackingImage from 'assets/img/trackingImage.png';
import rebookingImage from 'assets/img/rebookingImage.png';
import managementImage from 'assets/img/managementImage.png';

const Features = () => {
  return (
    <div className="features-container">
    <h5 className="features-heading">Features</h5>
    <h2 className="features-subheading">Track, Save, Rebook – Your <br /> Travel, Simplified</h2>

    <div className="row">
      {/* Feature 1 */}
      <div className="col-12 col-md-3 mb-3 stylehov">
        <div className="feature-card text-center">
          <img 
            src={trackingImage} 
            alt="Automated Price Tracking" 
            className="feature-image " 
            height="106px" 
            width="100px" 
          />
          <h3>Easy inexpensive<br/> registration</h3>
          {/* <p className="featureptag">
            Stay updated on price changes for flights, hotels, and car rentals with automatic daily checks. Rebooker ensures you never miss an opportunity to save.
          </p> */}
          <button className="btn btn-primary">Know More</button>
        </div>
      </div>

      {/* Feature 2 */}
      <div className="col-12 col-md-3 mb-3 stylehov">
        <div className="feature-card text-center">
          <img 
            src={rebookingImage} 
            alt="Seamless Rebooking" 
            className="feature-image " 
          />
          <h3>Ease of monitoring<br/> through AI</h3>
          {/* <p className="featureptag">
            Receive instant notifications when a lower price is available. Easily rebook your reservations without the hassle of manual price monitoring.
          </p> */}
          <button className="btn btn-primary">Know More</button>
        </div>
      </div>

      {/* Feature 3 */}
      <div className="col-12 col-md-3 mb-3 stylehov">
        <div className="feature-card text-center">
          <img 
            src={managementImage} 
            alt="Centralized Travel Management" 
            className="feature-image " 
          />
          <h3>Logical<br/> saving</h3>
          {/* <p className="featureptag">
            Keep all your reservations in one place. Forward your confirmation emails to Rebooker, and the app will automatically log, track, and manage your bookings.
          </p> */}
          <button className="btn btn-primary">Know More</button>
        </div>
      </div>

      {/* Feature 4 */}
      <div className="col-12 col-md-3 mb-3 stylehov">
        <div className="feature-card text-center">
          <img 
            src={managementImage} 
            alt="Centralized Travel Management" 
            className="feature-image " 
          />
          <h3>Fair<br/> pricing model</h3>
          {/* <p className="featureptag">
            Keep all your reservations in one place. Forward your confirmation emails to Rebooker, and the app will automatically log, track, and manage your bookings.
          </p> */}
          <button className="btn btn-primary">Know More</button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Features;
