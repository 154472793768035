import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import Icon from "@mui/material/Icon";
import AdminManagment from "layouts/AdminManagment";
import Permissions from "layouts/Permissions";
import Bookings from "layouts/Booking";
import PaymentDetails from "layouts/PaymentDetail";
import SuperAuth from "authroutes/Super";

const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small" className="sidebar_icons">dashboard</Icon>,
    route: "/dashboard",
    component: <SuperAuth><Dashboard /></SuperAuth>,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "tables",
    icon: <Icon fontSize="small" className="sidebar_icons">table_view</Icon>,
    route: "/user-management",
    component: <SuperAuth> <Tables /></SuperAuth>,
  },
  {
    type: "collapse",
    name: "Admin Management",
    key: "admin",
    icon: <Icon fontSize="small" className="sidebar_icons">table_view</Icon>,
    route: "/admin-management",
    component: <SuperAuth><AdminManagment /></SuperAuth>,
  },
  {
    type: "collapse",
    name: "Bookings",
    key: "bookings",
    icon: <Icon fontSize="small" className="sidebar_icons">Booking</Icon>,
    route: "/booking",
    component: <SuperAuth><Bookings /></SuperAuth>,
  },
  {
    type: "collapse",
    name: "Permission",
    key: "permission",
    icon: <Icon fontSize="small" className="sidebar_icons">Permission</Icon>,
    route: "/permissions",
    component: <SuperAuth><Permissions /></SuperAuth>,
  },
  {
    type: "collapse",
    name: "Payment Details",
    key: "payment",
    icon: <Icon fontSize="small" className="sidebar_icons">payment</Icon>,
    route: "/payment",
    component: <SuperAuth><PaymentDetails /></SuperAuth>,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small" className="sidebar_icons">person</Icon>,
    route: "/profile",
    component: <SuperAuth> <Profile /></SuperAuth>,
  },
];

export default adminRoutes;
