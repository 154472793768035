import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MDTypography from "components/MDTypography";
import bgImage from "assets/images/logo.png";
import MDButton from "components/MDButton";
import { IconButton } from "@mui/material";
import MDAlert from "components/MDAlert";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Logo from "assets/images/auth/logo.png";
import { registerUser } from "utils/api";
import validator from "utils/validate";
import { verifyEmail } from "utils/api";
import { toast } from "react-toastify";
import Loader from "components/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
};

function Register() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [formdata, setFormdata] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    confirm_password: null,
    user_type: "2",
    term_and_privacy: false,
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill("")); // Assuming a 6-digit OTP
  const [email, setEmail] = useState(""); // Example email

  const handleToggle = (field) => {
    if (field == "password") setShowPassword(!showPassword);
    else setShowConfirm(!showConfirm);
  };

  const handleFormdata = (e) => {

    const { name, value, type, checked } = e.target;
    setFormdata((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value.replace(/^\s+$/, ""),
    }));

    validateField(name, value);
  };

  const validateField = (name, value) => {
    const trimmedValue = value?.trim();
    let error = "";

    if (!trimmedValue) {
      const fieldName = name === "term_and_privacy"
        ? "Please accept Terms of Use and Privacy Policy"
        : name.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

      error = `${fieldName} is required`;
    } else if ((name === "first_name" || name === "last_name") && trimmedValue.length < 2) {
      error = "Minimum 2 characters required";
    } else if (name === "password" && trimmedValue.length < 6) {
      error = "Password must be at least 6 characters long";
    } else if (name === "confirm_password" && trimmedValue !== formdata.password) {
      error = "Passwords do not match";
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Validate email format
      if (value.includes(" ")) {
        error = "Email should not contain spaces";
      } else if (!emailRegex.test(trimmedValue)) {
        error = "Invalid email address";
      }
    } else if (name == "term_and_privacy" && value == false) {
      error = "You must agree to proceed.";
    }

    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSignIn = (e) => {
    e.preventDefault();

    let newErrors = {};
    let isValid = true;

    // Validate all fields
    Object.keys(formdata).forEach((field) => {
      const value = typeof formdata[field] === "string" ? formdata[field].trim() : formdata[field];
      let error = "";

      if (!value) {
        const fieldName = field === "term_and_privacy"
          ? "Please accept Terms of Use and Privacy Policy"
          : field.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

        error = `${fieldName} is required`;
      } else if ((field === "first_name" || field === "last_name") && value.length < 2) {
        error = "Minimum 2 characters required";
      } else if (field === "password" && value.length < 6) {
        error = "Password must be at least 6 characters long";
      } else if (field === "confirm_password" && value !== formdata.password) {
        error = "Passwords do not match";
      } else if (field === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value.includes(" ")) {
          error = "Email should not contain spaces";
        } else if (!emailRegex.test(value)) {
          error = "Invalid email address";
        }
      } else if (field === "term_and_privacy" && value === false) {
        error = "You must agree to proceed.";
      }

      if (error) {
        newErrors[field] = error;
        isValid = false;
      }
    });


    setErrors(newErrors);

    if (isValid) {
      setLoading(true)
      registerUser(formdata)
        .then((res) => {
          setLoading(false)
          if (res.status === 200 || res.status === 201) {
            setEmail(formdata.email);
            setOpen(true);
            toast.success("Please check your email for verification");
          } else {
            toast.error(res.data.email[0]);
          }
        })
        .catch((error) => {
          setLoading(false)
          console.log(error);
        });
    }
  };


  const handleChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];
    if (/^\d*$/.test(value) && value.length <= 1) {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        const nextInput = document.querySelector(
          `input[name="otp-${index + 1}"]`
        );
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        const prevInput = document.querySelector(
          `input[name="otp-${index - 1}"]`
        );
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleSubmit = () => {
    const otpCode = otp.join("");
    if (otpCode.length == 6) {
      verifyEmail(otpCode).then((res) => {
        if (res.status == 200) {
          if (!res?.data?.is_subscription && res?.data?.user_type === 2) {
            navigate("/subscription")
          } else {
            setTimeout(() => {
              navigate("/login")
            }, 1000)
          }
          localStorage.setItem("user_access_token", res.data.access);

          setOpen(false)
          toast.success(res.data.message, {
            theme: "colored"
          })

        } else {
          toast.error(res.data.error, {
            theme: "colored"
          })
        }
      }).catch((error) => {
        console.log("error", error);
        toast.error("Something went wrong", {
          theme: "colored"
        })
      })
    }
  };

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      <MDTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        {showAlert}
        {errorAlert}
      </MDTypography>
    </MDTypography>
  );

  return (
    <BasicLayout>
      <div style={{ position: "absolute", right: "25px", top: "20px" }}>
        {showAlert && (
          <MDAlert color="info" dismissible>
            {alertContent("info")}
          </MDAlert>
        )}
        {errorAlert && (
          <MDAlert color="error" dismissible>
            {alertContent("error")}
          </MDAlert>
        )}
      </div>

      {
        loading &&
        <Loader />
      }
      <div className="main_file">
        <div className="bg_login1">
          <div className="bg_image_login">
            <img src={Logo} />
          </div>
        </div>
        <div className="login_form login_form_inner">
          <div className="login_scroll_form">
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="#344585"
              mt={1}
            >
              Let’s Sign Up 👇
            </MDTypography>
            <MDTypography display="block" variant="button" color="blue" my={1}>
              Please enter your account registration information and your account will
              be immediately activated.  Once payment details are provided, you can
              then send booking confirmations to airebooker6@gmail.com
              and receive monitoring and relevant substitutes!
            </MDTypography>
            <div>
              <input
                type="text"
                placeholder="First Name"
                name="first_name"
                value={formdata.first_name}
                onChange={handleFormdata}
                className="input_design"
              />

              {errors.first_name && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.first_name}
                  </span>
                </>
              )}
              <br />
              <input
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={formdata.last_name}
                onChange={handleFormdata}
                className="input_design"
              />

              {errors.last_name && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.last_name}
                  </span>
                </>
              )}
              <br />
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formdata.email}
                onChange={handleFormdata}
                className="input_design"
              />

              {errors.email && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.email}
                  </span>
                </>
              )}
              <br />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formdata.password}
                onChange={handleFormdata}
                className="input_design"
              />
              <IconButton
                size="small"
                disableRipple
                onClick={() => handleToggle("password")}
                style={{ marginLeft: "-40px" }}
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {errors.password && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.password}
                  </span>
                </>
              )}
              <input
                type={showConfirm ? "text" : "password"}
                placeholder="Password Confirmation"
                name="confirm_password"
                value={formdata.confirm_password}
                onChange={handleFormdata}
                className="input_design"
              />
              <IconButton
                size="small"
                disableRipple
                onClick={() => handleToggle("confirm")}
                style={{ marginLeft: "-40px" }}
              >
                {!showConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {errors.confirm_password && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.confirm_password}
                  </span>
                </>
              )}

              <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                <input
                  type="checkbox"
                  name="term_and_privacy"
                  checked={formdata.term_and_privacy}
                  onChange={handleFormdata}
                  className="checkbox_design"
                  style={{ marginRight: "8px" }}
                />
                <label style={{ fontSize: "14px" }}>
                  I Agree to the{" "}
                  AIRebooker.com
                  {" "}

                  Terms of Use
                  and{" "}

                  Privacy Policy
                </label>
              </div>

              {errors.term_and_privacy && (
                <span style={{ color: "red", fontSize: "14px", lineHeight:"normal", marginBottom:"0px" }}>{errors.term_and_privacy}</span>
              )}

            </div>
            <div className="sign_butn">
              <MDButton
                variant="gradient"
                onClick={(e) => handleSignIn(e)}
                color="info"
                fullWidth
              >
                Register
              </MDButton>
            </div>
            <div>
              <MDTypography
                display="block"
                variant="button"
                color="blue"
                my={1}
              >
                Already have an account? <Link to="/login">Login</Link>
              </MDTypography>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: "static" }}  //-- to handle the outside modal click
      >
        <Box sx={style}>
          <div className="verify_main">
            <div className="verify_card">
              <img src={bgImage} alt="logo" />
              <MDTypography
                variant="h3"
                fontWeight="medium"
                color="#344585"
                mt={3}
              >
                Verify Your Email
              </MDTypography>

              <MDTypography
                display="block"
                variant="button"
                color="blue"
                my={1}
              >
                Verification code has been sent to{" "}
                <span style={{ color: "#2196F3", fontWeight: "400" }}>
                  {formdata?.email}
                </span>
              </MDTypography>

              <div
                className="otp_input_container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    name={`otp-${index}`}
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace
                    onFocus={(e) => e.target.select()}
                    maxLength="1"
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      fontSize: "20px",
                      background: "#e1e1e1",
                      border: "none",
                      borderRadius: "10px",
                      color: "#344585",
                      fontWeight: "600",
                    }}
                  />
                ))}
              </div>
              <div style={{ marginTop: "30px" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSubmit}
                >
                  Confirm Code
                </MDButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </BasicLayout>
  );
}

export default Register;
