import Loader from "components/Loader";
import MyContext from "layouts/Context/context";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userSaving } from "utils/api";
import { paymentPay } from "utils/api";

const NoAccessModal = ({ show, setShow, navidash, userdash, allBookingData }) => {

    const { setSubId, setTotalAmount } = useContext(MyContext);
    const visible = useMemo(() => {
        return show
    }, show)

    const [data, setData] = useState([])

    const [tableLoader, setTableLoader] = useState(false)

    const pendingAmount = () => {
        setTableLoader(true)
        userSaving().then((response) => {
            if (response?.data?.length > 0) {
                const unpaidData = response?.data?.filter(item => item.is_payment === false);

                if (unpaidData?.length > 0) {
                    const totalSavedAmount = unpaidData?.reduce((acc, item) => {
                        const amount = parseFloat(item.saved_amount.replace("$", ""));
                        return acc + amount;
                    }, 0);
                    const tenPercent = (totalSavedAmount * 0.10).toFixed(2);
                    setTotalAmount(tenPercent)
                    setSubId(unpaidData)
                    setData(unpaidData)
                    setTableLoader(false)
                } else {
                    setData(null)
                    setTableLoader(false)
                }
            } else {
                setData(null)
                setTableLoader(false)
            }
        }).catch((error) => {
            console.log(error)
            setTableLoader(false)
        })
    }

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        pendingAmount()
    }, [show])

    const handlePayment = () => {
        setLoading(true)
        paymentPay().then((res) => {
            setLoading(false)
            if (res?.status == 200) {
                window.location.href = res?.data?.payment_url
                // setShow(false)
            } else {
                toast.success(res?.data?.message, {
                    theme: "colored",
                    autoClose: 3000,
                })
            }
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }

    const savePercent = (amount) => {
        const savedAmount = parseFloat(amount);
        const tenPercent = (savedAmount * 10) / 100;
        return tenPercent?.toFixed(2)
    }

    const navigate = useNavigate()

    const handlecancel = () => {
        if (navidash !== "navidash") {
            setShow(false)
        }
    }

    const handleClose = () => {
        setShow(false)
        if (navidash == "navidash") {
            navigate("/dashbaord")
        }
        if (userdash == "userdash") {
            allBookingData()
        }
    }

    return (
        <>

            {
                loading &&
                <Loader />
            }

            <div>
                <Modal show={visible} onHide={handlecancel} size="lg" centered className={`${loading ? "" : "no-access-modal"}`}>

                    <Modal.Body>
                        <div className="cancel_modal_section">
                            <i onClick={handleClose} className="bi bi-x-circle"></i>
                        </div>
                        <div className="no-access-section py-4 px-5">
                            <img src="/favicon.png" />
                            <h3 className="my-3 thanku_title">
                                Thank you for rebooking your reservation -
                                and saving money!
                            </h3>

                            {
                                tableLoader ?
                                    <div className="text-center d-flex justify-content-center mt-3">
                                        <div className="hotel-loader"></div>
                                    </div>
                                    :
                                    <>
                                        {data
                                            ?.filter(item => item.is_payment === false && (item.flight_layovers?.length > 0 || item?.payment_type == "FLIGHT"))?.length > 0 &&
                                            <>
                                                <h6 className="d-flex pending-amount">Flight Pending Amount:</h6>
                                                <table className="price-custom-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Departure Details</th>
                                                            <th>Arrival Details</th>
                                                            <th>Original Booking($)</th>
                                                            <th>Rebooking</th>
                                                            <th>Saving Amount</th>
                                                            <th>Paying Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data
                                                            ?.filter(item => item.is_payment === false && (item.flight_layovers?.length > 0 || item?.payment_type == "FLIGHT"))
                                                            ?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div className="departure_time_detail">
                                                                            <p>{moment(item?.flight_layovers?.[0]?.first_segment?.[0]?.departure?.at).format('LT')}</p>
                                                                            <p>{item?.flight_layovers?.[0]?.first_segment?.[0]?.departure?.iataCode}</p>
                                                                            <p>{moment(item?.flight_layovers?.[0]?.first_segment?.[0]?.departure?.at).format('L')}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="departure_time_detail">
                                                                            {
                                                                                <>
                                                                                    <p>{moment(item?.flight_layovers?.[0]?.first_segment?.[item?.flight_layovers?.[0]?.first_segment?.length - 1]?.arrival?.at).format('LT')}</p>
                                                                                    <p>{item?.flight_layovers?.[0]?.first_segment?.[item?.flight_layovers?.[0]?.first_segment?.length - 1]?.arrival?.iataCode}</p>
                                                                                    <p>{moment(item?.flight_layovers?.[0]?.first_segment?.[item?.flight_layovers?.[0]?.first_segment?.length - 1]?.arrival?.at).format('L')}</p>
                                                                                </>
                                                                             }
                                                                        </div>
                                                                    </td>
                                                                    <td>{item?.previouse_amount ? `$${parseFloat(item?.previouse_amount)?.toFixed(2)}` : ""}</td>
                                                                    <td>{item?.booking_amount ? `$${parseFloat(item?.booking_amount)?.toFixed(2)}` : ""}</td>
                                                                    <td>{item?.saved_amount ? `$${parseFloat(item?.saved_amount?.replace("$", ""))?.toFixed(2)}` : ""}</td>
                                                                    <td>{item?.saved_amount ? `$${savePercent(item?.saved_amount?.replace("$", ""))}` : ""}</td>
                                                                </tr>
                                                            ))}
                                                        <tr className="total-row">
                                                            <td colSpan="5" className="text-end font-bold"><strong>Amount Due:</strong></td>
                                                            <td className="font-bold">
                                                                ${data
                                                                    ?.filter(item => item.is_payment === false && item.flight_layovers?.length > 0)
                                                                    ?.reduce((total, item) => total + parseFloat(savePercent(item?.saved_amount?.replace("$", "")) || 0), 0)
                                                                    .toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </>
                                        }

                                        {
                                            data
                                                ?.filter(item => item.is_payment === false && item.hotel_substitutes)?.length > 0 &&
                                            <>
                                                <h6 className="mt-3 d-flex pending-amount">Hotel Pending Amount:</h6>
                                                <table className="price-custom-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Hotel Name</th>
                                                            {/* <th>Hotel Address</th> */}
                                                            <th>Check in date</th>
                                                            <th>Check out date</th>
                                                            <th>Original Booking($)</th>
                                                            <th>Rebooking</th>
                                                            <th>Saving Amount</th>
                                                            <th>Paying Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data
                                                            ?.filter(item => item.is_payment === false && item.hotel_substitutes)
                                                            ?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div className="departure_time_detail">
                                                                            <p><strong>{item?.hotel_substitutes?.hotel_name}</strong></p>
                                                                            {/* <p>{item?.hotel_substitutes?.hotel_address}</p> */}
                                                                        </div>
                                                                    </td>
                                                                    {/* <td>
                                                                <div className="departure_time_detail">
                                                                    <p>{item?.hotel_substitutes?.hotel_address}</p>
                                                                </div>
                                                            </td> */}
                                                                    <td>
                                                                        <div className="departure_time_detail">
                                                                            <p>{moment(item?.hotel_substitutes?.check_in_date).format('L')}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="departure_time_detail">
                                                                            <p>{moment(item?.hotel_substitutes?.check_out_date).format('L')}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item?.previouse_amount ? `$${parseFloat(item?.previouse_amount)?.toFixed(2)}` : ""}</td>
                                                                    <td>{item?.booking_amount ? `$${parseFloat(item?.booking_amount)?.toFixed(2)}` : ""}</td>
                                                                    <td>{item?.saved_amount ? `$${parseFloat(item?.saved_amount?.replace("$", ""))?.toFixed(2)}` : ""}</td>
                                                                    <td>{item?.saved_amount ? `$${savePercent(item?.saved_amount?.replace("$", ""))}` : ""}</td>
                                                                </tr>
                                                            ))}
                                                        <tr className="total-row">
                                                            <td colSpan="6" className="text-end font-bold"><strong>Amount Due:</strong></td>
                                                            <td className="font-bold">
                                                                ${data
                                                                    ?.filter(item => item.is_payment === false && item.hotel_substitutes)?.reduce((total, item) => total + parseFloat(savePercent(item?.saved_amount?.replace("$", "")) || 0), 0).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                        }


                                    </>
                            }



                            {/* <button onClick={() => handlecancel()} className="cancels_butn">Cancel</button> */}

                            <div className="text-end">
                                <button onClick={() => handlePayment()} className="payment_butn mt-3">Pay Now</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default NoAccessModal