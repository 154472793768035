import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import airlineName from "utils/airlinename.json"
import {
  Button,
  TextField,
} from "@mui/material";
import Loader from "components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";
import { updateSubstituteStatus } from "utils/api";
import { toast } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import FlightDetailModal from "components/FlightDetailModal";
import { layoversFlight } from "utils/api";
import FlightDetails from "components/FlightDetails";
import FlightSubstitute from "components/FlightDetails/flightSubstitute";
import StatusButtons from "components/FlightDetails/statusbutton";
import PriceDetails from "components/FlightDetails/priceDetails";
import { createFlightPair } from "utils/api";
import moment from "moment";
import CurrentStatus from "components/FlightDetails/currentStatus";
import { updateFlightPair } from "utils/api";
import NoAccessModal from "components/NoAccessModal";
import { currentPriceById } from "utils/api";
import { flightOffersById } from "utils/api";

function BookingCard({ flights, loading, setLoading, allBookingData, bookingId, accordionRefs }) {


  const visibleFlights = flights?.filter(show => show?.is_show_data === true);

  const navigate = useNavigate();
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [return_id, setReturnId] = React.useState(null);
  const [substituteId, setSubstituteId] = React.useState("")
  const [flightshow, setFlightShow] = React.useState(false);
  const [subId, setSubId] = React.useState(null)
  const [data, setData] = React.useState([])
  const [numStops, setNumStops] = React.useState(null)
  const [status, setStatus] = React.useState(null);
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [siteUrl, setSiteUrl] = React.useState("")
  const [layoverPrice, setLayoverPrice] = React.useState(null)
  const [layoverData, setLayoverData] = React.useState(null)
  const [acceptData, setAcceptData] = React.useState(null)
  const [currentUpdate, setCurrentUpdate] = React.useState(false)
  const [refreshLoader, setRefreshLoader] = React.useState({});
  const [refCurrentPrice, setRefCurrentPrice] = React.useState({})

  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case "ELIMINATE":
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["Downgrade", "I don’t want downgrade"],
          ["OTHER", "Other"],
        ];
      case "REJECT":
        return [
          ["WAITING", "Waiting"],
          ["LAYOVER", "Layover"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          // ["Downgrade", "I don’t want downgrade"],
          ["OTHER", "Other"],
        ];
      default:
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["WAITING", "Waiting"],
          ["POLICY_RESTRICTION", "Policy Restriction"],
          ["OTHER", "Other"],
        ];
    }
  }, [status]);

  // const [bookingId, setBookingId] = React.useState(null);

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== "OTHER") {
      setOtherReasonText("");
    }
  };

  const handleSubstituteDetail = (id, num, price, data, layover) => {
    setData(data)
    setNumStops(num)
    setFlightShow(true)
    setLayoverPrice(price)
    setLayoverData(layover)
    setSubId(id)
  }

  const handleClose = () => {
    setShow(false);
    setSelectedReason("");
    setOtherReasonText("");
    setSelectMesage("");
  };

  const flightHandleClose = () => {
    setFlightShow(false);
    setData([])
  };

  const getAirlineName = (airlineCode) => {
    const airline = airlineName?.find(item => item?.id === airlineCode);
    return airline ? airline?.name : airlineCode;
  };

  const handleStatus = (id, status, rid, url, data, item, current) => {
    setShow(id);
    setStatus(status);
    setSiteUrl(url)
    setReturnId(rid)
    setSubstituteId(id)
    setCurrentUpdate(current)
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let confirCode = "AI";

    for (let i = 0; i < 4; i++) {
      confirCode += letters[Math.floor(Math.random() * letters.length)];
    }

    const storedUser = localStorage.getItem("active-user");
    const user = JSON.parse(storedUser);

    if ((status === "ACCEPT" || status === "REINSTATE") && (current === "current")) {
      setShow(true)
      const currtPrice = item?.same_flight_sub?.total_price?.replace("$", "")
      setAcceptData({
        "id": item?.id,
        "price": currtPrice
      })
    } else {
      if ((status === "ACCEPT" || status === "REINSTATE")) {

        layoversFlight(id).then((res) => {
          const segment = res?.data?.[0]?.offer_data?.itineraries
          const offer_data = res?.data?.[0]?.offer_data
          const singleSegment = segment?.[0]?.segments?.[0]

          const childFlights = segment?.flatMap((flight, i) =>
            flight.segments
              .filter((_, index) => !(i === 0 && index === 0)) // Remove only the first segment from data[0]
              .map((segment) => ({
                message_id: user?.id,
                sender_email: user?.email,
                confirmation_code: confirCode,
                airline: segment?.carrierCode,
                departure_city: segment?.departure?.iataCode,
                destination_city: segment?.arrival?.iataCode,
                departure_date: segment?.departure?.at?.split("T")?.[0],
                return_date: segment?.arrival?.at?.split("T")?.[0],
                price: offer_data?.price?.total,
                flight_number: `${segment?.carrierCode}${segment?.number}`,
                flight_class: offer_data?.travelerPricings?.[0]?.fareDetailsBySegment?.[0]?.cabin,
                departure_time: moment(segment?.departure?.at?.split("T")?.[1], "HH:mm:ss").format(
                  "h:mm A"
                ),
                Arrives_time: moment(segment?.arrival?.at?.split("T")?.[1], "HH:mm:ss").format(
                  "h:mm A"
                ),
                "number_of_travelers": item?.number_of_travelers,
                is_return_flight: i === 0 ? false : true,
                is_show_data: true,
              }))
          );

          if (rid == true || rid == "true") {
            setAcceptData({
              "id": item?.id,
              "user": user?.id,
              "Flight_substitute": data?.id,
              "saved_amount": data?.saved_amount,
              "booking_amount": data?.price,
              "previouse_amount": item?.price,
              "parent_flight": {
                "message_id": user?.id,
                "sender_email": user?.email,
                "confirmation_code": confirCode,
                "airline": getAirlineName(data?.airline_code),
                "departure_city": data?.departure_iata_code,
                "destination_city": data?.arrival_iata_code,
                "departure_date": data?.departure_time?.split(" ")?.[0],
                "return_date": data?.arrival_time?.split(" ")?.[0],
                "price": data?.price,
                "number_of_travelers": item?.number_of_travelers,
                "flight_number": `${data?.airline_code}${data?.flight_number}`,
                "flight_class": data?.travel_class,
                "departure_time": `${data?.departure_time?.split(" ")?.[1]} ${data?.departure_time?.split(" ")?.[2]}`,
                "Arrives_time": `${data?.arrival_time?.split(" ")?.[1]} ${data?.arrival_time?.split(" ")?.[2]}`,
                "is_return_flight": false,
                "is_show_data": true
              },
              "child_flight": childFlights

            })
          } else {
            setAcceptData({
              "id": item?.id,
              "user": user?.id,
              "Flight_substitute": data?.id,
              "saved_amount": data?.saved_amount,
              "booking_amount": data?.price,
              "previouse_amount": item?.price,
              "parent_flight": {
                "message_id": user?.id,
                "sender_email": user?.email,
                "confirmation_code": confirCode,
                "airline": getAirlineName(data?.airline_code),
                "departure_city": data?.departure_iata_code,
                "destination_city": singleSegment?.arrival?.iataCode,
                "departure_date": data?.departure_time?.split(" ")?.[0],
                "return_date": singleSegment?.arrival?.at?.split("T")?.[0],
                "price": data?.price,
                "number_of_travelers": item?.number_of_travelers,
                "flight_number": `${data?.airline_code}${data?.flight_number}`,
                "flight_class": data?.travel_class,
                "departure_time": `${data?.departure_time?.split(" ")?.[1]} ${data?.departure_time?.split(" ")?.[2]}`,
                "Arrives_time": moment(singleSegment?.arrival?.at?.split("T")?.[1], "HH:mm:ss").format("h:mm A"),
                "is_return_flight": false,
                "is_show_data": true
              },
              "child_flight": childFlights

            })
          }


        }).catch((error) => {
          console.log(error)
        })
      } else {
        setShow(true)
      }
    }
  };

  const handleUpdateStatus = () => {

    if (!selectedReason && status !== "ACCEPT" && status !== "REINSTATE") {
      setSelectMesage("Please select any reason");
      return;
    }

    if (selectedReason === "OTHER" && !otherReasonText.trim()) {
      setSelectMesage("Please provide a reason in the text field");
      return;
    }

    const tickeId = return_id ? { id: show, r_id: return_id } : { id: show }

    if ((status === "ACCEPT" || status === "REINSTATE") && (currentUpdate === "current")) {
      setLoading(true)
      updateFlightPair(acceptData).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setLoading(false)
          setShow(false)
          setSelectedReason("");
          setOtherReasonText("");
          setSelectMesage("");
          allBookingData();
          toast.success(
            `Booking accepted
             successfully`,
            {
              theme: "colored",
            }
          );
          setStatus(null)
          return;
        } else {
          setLoading(false)
          setShow(false)
          toast.error(`Failed to accept status`, {
            theme: "colored",
          });
          setSelectedReason("");
          setOtherReasonText("");
          setSelectMesage("");
        }
      }).catch((error) => {
        console.log(error)
        toast.error(`Failed to accept status`, {
          theme: "colored",
        });
        setLoading(false)
        setShow(false)
      })
    } else {
      if ((status === "ACCEPT" || status === "REINSTATE") && acceptData?.child_flight?.length > 0) {
        setLoading(true)

        createFlightPair(acceptData).then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            setLoading(false)
            setShow(false)
            setPaymentShow(true)
            toast.success(
              `Booking accepted
              successfully`,
              {
                theme: "colored",
              }
            );
            allBookingData();
            setStatus(null)
            setSelectedReason("");
            setOtherReasonText("");
            setSelectMesage("");
          } else {
            setLoading(false)
            setShow(false)
            toast.error(`Failed to accept status`, {
              theme: "colored",
            });
          }
        }).catch((error) => {
          console.log(error)
          toast.error(`Failed to accept status`, {
            theme: "colored",
          });
          setLoading(false)
          setShow(false)
        })
        // }

      } else {
        setLoading(true)
        updateSubstituteStatus(substituteId, {
          status: status === "REINSTATE" ? "ACCEPT" : status,
          additional_information: selectedReason,
          other_reason_text: selectedReason === "OTHER" ? otherReasonText : null,
        })
          .then((res) => {
            setLoading(false)
            if (res.status === 200) {
              setSelectedReason("");
              setOtherReasonText("");
              setSelectMesage("");

              toast.success(
                `Status ${status === "ELIMINATE"
                  ? `${status?.toLowerCase()}d`
                  : `${status?.toLowerCase()}ed`
                } successfully`,
                {
                  theme: "colored",
                }
              );
              if (status === "ACCEPT" || status === "REINSTATE") {
                setPaymentShow(true)
                setShow(false);
              } else {
                setShow(false);
                allBookingData();
              }


            } else {
              setLoading(false)
              toast.error(`Failed to ${status?.toLowerCase()} status`, {
                theme: "colored",
              });
              setShow(false);
              setSelectedReason("");
              setOtherReasonText("");
              setSelectMesage("");
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false)
          });
      }
    }

  };

  const getTime = (departure, arrival) => {
    const departureDate = new Date(departure);
    const arrivalDate = new Date(arrival);

    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}hr ${minutes}min`;
  };

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "flights");
    navigate(`/price-monitor/${final_id}`);
    localStorage.setItem("active-tab", 1)
  };

  const updatebooking = (id) => {
    // let final_id = encodeIdWithType(390, "flights");
    // navigate(`/price-monitor/${final_id}`);
    let final_id = encodeIdWithType(id);
    navigate(`/update-booking/${id}`);
    localStorage.setItem("active-tab", 1)
  }

  React.useEffect(() => {
    if (!bookingId || visibleFlights.length === 0) return;

    const index = visibleFlights.findIndex((item) => item?.id === bookingId);

    if (index !== -1 && accordionRefs.current[index]) {
      setTimeout(() => {
        accordionRefs.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    }
  }, [bookingId]);

  const handleRefreshSub = (id, children) => {
    setRefreshLoader((prev) => ({ ...prev, [id]: "true" }));

    const childIds = (!children || children.length === 0) ? "" : children?.map(child => child.id).join(",");
    const refId = `${id}${childIds ? `,${childIds}` : ""}`;

    currentPriceById(refId)
      .then((res) => {
        if (res.status === 200) {
          flightOffersById(id)
            .then((res) => {
              setRefreshLoader((prev) => ({ ...prev, [id]: "Success" }));
              setTimeout(() => {
                setRefreshLoader((prev) => ({ ...prev, [id]: false }));
              }, 5000);
              if (res.status == 200) {
                setRefCurrentPrice((prev) => ({ ...prev, [id]: res?.data?.total_price }))
              }
            })
            .catch((error) => {
              console.log(error);
              setRefreshLoader((prev) => ({ ...prev, [id]: false }));
            });
        } else {
          setRefreshLoader((prev) => ({ ...prev, [id]: false }));
        }
      })
      .catch((error) => {
        console.log(error);
        setRefreshLoader((prev) => ({ ...prev, [id]: false }));
      });
  };

  return (
    <MDBox py={3}>
      <MDTypography variant="h6">My Bookings</MDTypography>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {visibleFlights?.length > 0 ?
            visibleFlights
              ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))?.map((item, i) => {

                return (
                  <Accordion
                    ref={(el) => (accordionRefs.current[i] = el)}
                    className="mb-3 flight_substitutes_bg"
                    sx={{
                      ...(!(item?.substitutes && item?.substitutes?.length > 0) && {
                        backgroundColor: "#f5f5f5",
                        opacity: 0.7,
                      }),
                    }}
                    defaultExpanded={bookingId ? bookingId == item?.id : i === 0}
                    key={i}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel1-content`}
                      id={`panel1-header`}
                      onClick={() => {
                        accordionRefs.current[i]?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                    >
                      <div className="airline_header row justify-content-start align-items-center p-0">
                        <div className="d-flex justify-content-between">
                          <MDTypography variant="p"
                            color="white"
                            className="total_price mb-1">Booking Confirmation: <strong className="ms-1">{item?.confirmation_code}</strong></MDTypography>
                          {
                            !refreshLoader[item.id] &&
                            <svg onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleRefreshSub(item?.id, item?.children);
                            }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-clockwise refresh_icon" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                            </svg>
                          }

                          {
                            refreshLoader[item?.id] === "true" &&
                            <div className="refresh_loader_outer">
                              <div className="refresh_loader"></div>
                            </div>
                          }

                          {
                            refreshLoader[item.id] === "Success" &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-check-lg refresh_icon" viewBox="0 0 16 16">
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          }

                        </div>
                        <div className="col-md-8 col-lg-9 flight_detail_bg ">
                          {
                            item?.children?.length > 0 ?
                              <>
                                <div className="outbound_section">
                                  <span className="outbound_booking">Outbound flight</span>
                                  <i onClick={() => updatebooking(item?.id)} className="bi bi-pencil-square main_booking_icon"></i>
                                  <FlightDetails item={item} getTime={getTime} />
                                  {
                                    item?.children?.map((child, i) => {
                                      if (child?.is_return_flight === false) {
                                        return (
                                          <FlightDetails item={child} getTime={getTime} />
                                        )
                                      }
                                    })
                                  }
                                </div>


                                {
                                  item?.children?.sort((a, b) => a?.id - b?.id)?.map((child, i) => {
                                    const firstReturnFlightIndex = item?.children?.findIndex(c => c?.is_return_flight === true);
                                    if (child?.is_return_flight === true) {
                                      return (
                                        <React.Fragment key={i}>
                                          {i === firstReturnFlightIndex && (
                                            <div className="outbound_section">
                                              <div className="children_data"></div>
                                              <span className="outbound_booking">Return flight</span>
                                            </div>
                                          )}
                                          <FlightDetails children={"children"} item={child} getTime={getTime} />
                                        </React.Fragment>
                                      )
                                    }
                                  })
                                }
                                {/* <FlightDetails children={"children"} item={item?.children?.[0]} getTime={getTime} /> */}
                              </>
                              :
                              <div className="outbound_section">
                                <span className="outbound_booking">Outbound flight</span>
                                <i onClick={() => updatebooking(item?.id)} className="bi bi-pencil-square main_booking_icon"></i>
                                <FlightDetails oneWay={"oneWay"} item={item} getTime={getTime} />
                              </div>
                          }

                        </div>
                        <div className="col-md-4 col-lg-3 py-2">
                          <div className="row px-2">
                            <div className="col-6 px-1">
                              <div className="price_section_paid ">
                                <p className="total_price mb-0" style={{ lineHeight: "1.2", whiteSpace: "break-spaces" }}>{item?.price ? <>
                                  Price paid {" "}
                                  <span>{(item?.payment_method && item?.payment_method == "miles") ? `(${item?.payment_method})` : ""}</span>
                                </> : ""}
                                </p>
                                <MDTypography
                                  variant="h6"
                                  color="white"
                                  className="flight_details main_flight_price mt-2"
                                >
                                  {`$${item?.price?.replace("$", "")?.replace("USD", "")}`}
                                </MDTypography>
                              </div>
                            </div>

                            {((item?.children?.length + 1) === item?.same_flight_sub?.flight_offers?.length || refCurrentPrice[item?.id]) &&
                              <div className="col-6 px-1">
                                <div className="price_section_paid">
                                  <p className="total_price mb-0" style={{ lineHeight: "1.2" }}>{(item?.same_flight_sub?.total_price || refCurrentPrice[item?.id]) ? "Current Price" : ""}</p>
                                  <MDTypography
                                    variant="h6"
                                    color="white"
                                    className="flight_details mt-2"
                                  >
                                    {""} {item?.same_flight_sub?.total_price || refCurrentPrice[item?.id]}
                                  </MDTypography>
                                </div>
                              </div>
                            }

                          </div>
                          {/* <p className="total_price mt-1">{item?.payment_method ?
                            <>Paid by : <strong>{item?.payment_method}</strong></> : ""}
                          </p> */}
                          {(item?.children?.length + 1) === item?.same_flight_sub?.flight_offers?.length &&
                            <div className="d-flex justify-content-center mt-2">
                              <CurrentStatus paidPrice={item?.price} currentPrice={item?.same_flight_sub?.total_price} samePrice={"samePrice"} returnID={item?.retSubstitues} ticketStatuses={ticketStatuses} item={item} ticket={item?.same_flight_sub} handleStatus={handleStatus} />
                            </div>
                          }

                        </div>
                      </div>

                    </AccordionSummary>

                    <AccordionDetails className="substitues_section">
                      <div className="">
                        <div className="substitutes my-2">
                          <MDTypography variant="h6" color="white">
                            Highest Scoring Matches
                          </MDTypography>

                          {(item?.substitutes?.length > 0) ?
                            (
                              <button
                                className="view_all"
                                onClick={() => redirection(item?.id)}
                              >
                                View All
                              </button>
                            ) : (
                              <MDTypography variant="h6" color="white">No substitutes available</MDTypography>
                            )}
                        </div>
                        {(!item?.substitutes) ? (
                          <>
                            <MDTypography
                              className="text-center d-flex gap-1 justify-content-center"
                              variant="h5"
                              color="white"
                            >
                              Loading <div className="loading_data"></div>
                            </MDTypography>
                          </>
                        ) : (
                          <>
                            {(item?.children?.length + 1) === item?.same_flight_sub?.flight_offers?.length &&
                              <div
                                className="container-fluid p-3 mb-3 subtiktmain position-relative"
                              >
                                <button className="current_butn">Current</button>
                                <div
                                  className="row align-items-center"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-md-6 col-lg-8">
                                    {
                                      item?.same_flight_sub?.flight_offers?.length > 0 ?
                                        item?.same_flight_sub?.flight_offers?.map((same, index) => {
                                          const returnFlightCount = item?.children?.filter(child => child?.is_return_flight === true)?.length || 0;
                                          const targetIndex = item?.same_flight_sub?.flight_offers?.length - returnFlightCount;
                                          // console.log("targetIndextargetIndex",targetIndex)
                                          return (
                                            <>
                                              {index === targetIndex &&
                                                <div className="position-relative">
                                                  <div className="children_data_same"></div>
                                                  <span className="return_booking_same">Return flight</span>
                                                </div>
                                              }
                                              <FlightSubstitute childrenLen={returnFlightCount} itemLength={item?.same_flight_sub?.flight_offers?.length - 1} index={index} returnSub={"returnSub"} item={same} ticket={same} getTime={getTime}
                                                handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                                ticketStatuses={ticketStatuses} />
                                            </>
                                          )
                                        })
                                        :
                                        <></>
                                      // <div className="col-md-6 col-lg-7">
                                      //   <FlightSubstitute returnSub={"returnSub"} item={item} ticket={item?.same_flight_sub?.outbound_offer} getTime={getTime}
                                      //     handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                      //     ticketStatuses={ticketStatuses} />
                                      // </div>
                                    }
                                  </div>
                                  <PriceDetails current={"current"} item={item} ticket={item?.same_flight_sub} getTime={getTime} />
                                  {/* <CurrentStatus samePrice={"samePrice"} returnID={item?.retSubstitues} ticketStatuses={ticketStatuses} item={item} ticket={item?.same_flight_sub} handleStatus={handleStatus} /> */}
                                </div>
                              </div>
                            }

                            {
                              item?.substitutes &&
                              item?.substitutes.length > 0 &&
                              item?.substitutes?.filter(ticket => ticket?.outbound_offer?.status !== "ELIMINATE")
                                ?.slice(0, 2).map((ticket, index) => {
                                  if (ticket?.outbound_offer.status === "ELIMINATE") {
                                    return null;
                                  }

                                  return (
                                    <div key={index} className="container-fluid p-3 mb-3 subtiktmain position-relative">
                                      <div className="high_matches">
                                        <p className="mb-0"> <strong className="match_percent">{ticket?.match_percentage} Score</strong>  </p>
                                      </div>
                                      <div className="row align-items-center" style={{ alignItems: "center", marginTop: "30px" }}>

                                        <div className="col-md-6 col-lg-7">
                                          <FlightSubstitute
                                            item={item}
                                            ticket={ticket?.outbound_offer}
                                            getTime={getTime}
                                            handleSubstituteDetail={handleSubstituteDetail}
                                            handleStatus={handleStatus}
                                            ticketStatuses={ticketStatuses}
                                            index={index}
                                          />
                                        </div>

                                        <PriceDetails
                                          item={item}
                                          ticket={ticket?.return_offer ? ticket : ticket?.outbound_offer}
                                          getTime={getTime}
                                          handleSubstituteDetail={handleSubstituteDetail}
                                          handleStatus={handleStatus}
                                          ticketStatuses={ticketStatuses}
                                          index={index}
                                        />
                                        <StatusButtons
                                          returnID={item?.retSubstitues}
                                          ticketStatuses={ticketStatuses}
                                          index={index}
                                          item={item}
                                          ticket={ticket?.outbound_offer}
                                          handleStatus={handleStatus}
                                        />
                                      </div>
                                    </div>
                                  );
                                })
                            }
                          </>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )

              })

            :
            <>
              <div className="text-center py-3">
                <MDTypography variant="h4">You don't have any Bookings at the moment!                </MDTypography>
              </div>
            </>
          }
        </div>
      )}

      {
        paymentShow && (

          <NoAccessModal data={subId} userdash={"userdash"} show={paymentShow} setShow={setPaymentShow} allBookingData={allBookingData} />
        )
      }

      <FlightDetailModal layoverData={layoverData} layoverPrice={layoverPrice} numStops={numStops} data={data} subId={subId} setSubId={setSubId} flightshow={flightshow} setFlightShow={setFlightShow} handleClose={flightHandleClose} />

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {status === "ACCEPT" && (
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            )}

            {status === "REJECT" && (
              <MDTypography variant="p" className="delete_msj">
                Please let us know why you are Rejecting this offer.
              </MDTypography>
            )}

            {status === "ELIMINATE" && (
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            )}
            {
              status === "REINSTATE" && (
                <MDTypography variant="p" className="delete_msj">
                  Are you sure? Would you like to reinstate this flight?
                </MDTypography>
              )
            }

            {status !== "ACCEPT" && status !== "REINSTATE" && (
              <div className="my-3 text-left">
                <Form.Group className="select_reason_input" controlId="reason-select">
                  {/* <Form.Label>Select Reason</Form.Label> */}
                  <Form.Select value={selectedReason} onChange={handleChange} className="custom-select">
                    <option value="">Select Reason</option> {/* Placeholder option */}
                    {REASON_CHOICES?.map((reason) => (
                      <option key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {selectedReason === "OTHER" && (
                  <TextField
                    fullWidth
                    label="Please specify the reason"
                    variant="outlined"
                    value={otherReasonText}
                    onChange={(e) => {
                      setOtherReasonText(e.target.value);
                      setSelectMesage("");
                    }}
                    className="other_reason_input mt-3"
                    multiline
                    rows={3}
                  />
                )}
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            )}

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              {status === "ACCEPT" ? "No" : "Cancel"}
            </Button>
            {
              status === "ACCEPT" ?
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => handleUpdateStatus()}
                >
                  Yes
                </Button>
                :
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                > Update
                </Button>
            }
          </div>
        </Modal.Body>
      </Modal>
    </MDBox>
  );
}

export default BookingCard;