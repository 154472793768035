import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import Loader from "components/Loader";
import Hoteldetail from "components/HotelDetail";
import HotelSubstitute from "components/HotelDetail/hotelSubstitute";

const HotelBooking = ({ booking, substitutes, loading }) => {
  const navigate = useNavigate();
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const [status, setStatus] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const handleStatus = (id, status) => {
    setShow(id);
    setStatus(status);
  };
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div>
          <MDBox py={3}>
            <div className="user_detail_section">
              <MDTypography variant="p" className="p-title">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={() => handleBackButtonClick()} width="34" height="34" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                </svg>
                {/* <ArrowBackIosNewIcon onClick={handleBackButtonClick} />{" "} */}
                {booking?.hotel_name}
              </MDTypography>
            </div>
            <Card className="container-fluid mb-2 p-3 mt-2 hotel_detail_bg">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <MDTypography
                    variant="p"
                    color="white"
                    className="Current-Plane"
                  >
                    Your Current Hotel Booking
                  </MDTypography>
                </div>
                <div className="col-md-8 dotted-gradient-border"></div>

                <Hoteldetail item={booking} />
              </div>
            </Card>
          </MDBox>
          {substitutes.length > 0 ? (
            <>
              <MDTypography variant="p" className="p-title">
                Substitute Tickets
              </MDTypography>
              {substitutes.map((hotel, index) => (
                <div
                  key={index}
                  className="container-fluid p-3 mb-3 subtiktmain"
                >
                  <HotelSubstitute hotel={hotel} index={index} userStatus={"userStatus"} ticketStatuses={ticketStatuses} handleStatus={handleStatus} />

                </div>
              ))}
            </>
          ) : (
            <MDTypography variant="h6" color="textSecondary">
              No Substitutes Found
            </MDTypography>
          )}
        </div>
      )}
    </>
  );
};

export default HotelBooking;
