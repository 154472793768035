import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MDTypography from "components/MDTypography";
import { Pagination } from "@mui/material";
import FlightSubstitute from "components/FlightDetails/flightSubstitute";
import PriceDetails from "components/FlightDetails/priceDetails";
import UserStatus from "components/FlightDetails/userStatus";
import FlightDetailModal from "components/FlightDetailModal";

const FlightSubstitutes = ({ substitutes, loading, current_price }) => {
  const getSaving = (item_price, ticket_price) => {
    const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (itemPrice - ticketPrice).toFixed(2);
    return parseFloat(savings);
  };

  const [subId, setSubId] = React.useState(null)
  const [flightshow, setFlightShow] = React.useState(false);
  const [data, setData] = React.useState([])
  const [numStops, setNumStops] = React.useState(null)
  const [layoverPrice, setLayoverPrice] = React.useState(null)
  const [layoverData, setLayoverData] = React.useState(null)

  const itemsPerPage = 10;
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(substitutes?.length / itemsPerPage);
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const displayedItems = substitutes?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const flightHandleClose = () => {
    setFlightShow(false);
    setData([])
  };

  const handleSubstituteDetail = (id, num, price, data, layover) => {
    setData(data)
    setNumStops(num)
    setFlightShow(true)
    setLayoverPrice(price)
    setLayoverData(layover)
    setSubId(id)
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  const handleStatus = () => { }

  return (
    <>
      <MDTypography variant="p" className="p-title">
        Highest Scoring Matches
      </MDTypography>
      {loading ? (
        <>
          <h5>Loading ..</h5>
        </>
      ) : (
        <>
          {displayedItems.length > 0 ? (
            displayedItems?.map((ticket, index) => (
              <div key={index} className="container-fluid p-3 mb-3 subtiktmain position-relative">
                <div className="high_matches">
                  <p className="mb-0"> <strong className="match_percent">{ticket?.outbound_offer?.match_percentage} Score</strong></p>
                </div>
                <div
                  className="row align-items-center"
                  style={{ alignItems: "center", marginTop:"30px" }}
                >
                  {
                    ticket?.return_offer ?
                      <div className="col-md-6 col-lg-7">
                        <FlightSubstitute item={displayedItems} ticket={ticket?.outbound_offer} getTime={getTime}
                          handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                          ticketStatuses={ticketStatuses} index={index} />

                        <FlightSubstitute item={displayedItems} ticket={ticket?.return_offer} getTime={getTime}
                          handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                          ticketStatuses={ticketStatuses} index={index} />

                      </div>
                      :
                      <div className="col-md-6 col-lg-7">
                        <FlightSubstitute item={displayedItems} ticket={ticket?.outbound_offer} getTime={getTime}
                          handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                          ticketStatuses={ticketStatuses} index={index} />
                      </div>
                  }

                  <PriceDetails item={displayedItems} ticket={ticket?.return_offer ? ticket : ticket?.outbound_offer} getTime={getTime}
                    handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                    ticketStatuses={ticketStatuses} index={index} />
                  <UserStatus ticketStatuses={ticketStatuses} index={index} item={displayedItems} ticket={ticket?.outbound_offer} handleStatus={handleStatus} />
                </div>
              </div>
            ))
          ) : (
            <p>No substitute offers available</p>
          )}

          {displayedItems?.length > 0 && (
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          )}
        </>
      )}

      <FlightDetailModal numStops={numStops} data={data} subId={subId} setSubId={setSubId} flightshow={flightshow} setFlightShow={setFlightShow} handleClose={flightHandleClose} />

    </>
  );
};

export default FlightSubstitutes;
