import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "https://mail.airebooker.com/";

// https://mail.airebooker.com/register
// http://18.119.134.210:8000

export const registerUser = async (data) => {
  try {
    const response = await axios.post("/register/", data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const loginUser = async (data) => {
  try {
    const response = await axios.post("/login/", data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const userSaving = async () => {
  try {
    const response = await axios.get("/user_savings/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const paymentPay = async () => {
  try {
    const response = await axios.get(`/payment/pay/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const passwordReset = async (data) => {
  try {
    const response = await axios.post(`/password-reset/`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const setNewPassword = async (data) => {
  const { userId, token, formData } = data;
  try {
    const response = await axios.post(
      `/password-reset-confirm/${userId}/${token}/`,
      formData
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const verifyEmail = async (data) => {
  try {
    const response = await axios.post(`/verify_otp/`, { otp: data });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getProfile = async () => {
  try {
    const response = await axios.get(`/user_profile/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateProfile = async (data) => {
  try {
    const response = await axios.put(`/user_profile/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchBookings = async () => {
  try {
    const response = await axios.get(`/flights_details/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchBookingsById = async (id) => {
  try {
    const response = await axios.get(`/flights_details?id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const fetchReturnById = async (id) => {
  try {
    const response = await axios.get(`/rwwwwwwwwww/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const updateBookingsById = async (id, data) => {
  try {
    const response = await axios.put(`/flights_update/${id}/`, data , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const updateHotelBookingsById = async (id, data) => {
  try {
    const response = await axios.put(`/hotal_update/${id}/`, data , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const updateCarBookingsById = async (id, data) => {
  try {
    const response = await axios.put(`/update_car_bookings/${id}/`, data , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};


export const flightAmadeusHotels = async (id) => {
  try {
    const response = await axios.get(`/amadeus_hotels/?booking_id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const flightBookingDetails = async (id) => {
  try {
    const response = await axios.get(`/flight_booking_details/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};
export const fetchFlightOffers = async (id) => {
  try {
    const response = await axios.get(`/flight_substitutes/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchFlightRoundTripSub = async (id) => {
  try {
    const response = await axios.get(`/round_trip/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchFlightSingleTripSub = async (id) => {
  try {
    const response = await axios.get(`/single_trip/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateSubstituteStatus = async (id, data) => {
  try {
    const response = await axios.put(`/flight_substitutes/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateHotelStatus = async (id, data) => {
  try {
    const response = await axios.put(`/hotal_substitutes/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const HotelBookingDetails = async (id) => {
  try {
    const response = await axios.get(`/hotel_booking_flight_details/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const CarBookingDetails = async (id) => {
  try {
    const response = await axios.get(`/car_booking_details/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const fetchSubstitueHotel = async (id) => {
  try {
    const response = await axios.get(`/hotal_substitutes/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchCarSubstitutes = async (id) => {
  try {
    const response = await axios.get(`/car_substitutes/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateUserPassword = async (data) => {
  try {
    const response = await axios.put(`/change-password/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAdminList = async () => {
  try {
    const response = await axios.get(`/admin_users/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAdminByID = async (id) => {
  try {
    const response = await axios.get(`/admin_users/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateAdmin = async (id, data) => {
  try {
    const response = await axios.put(`/admin_users/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteAdminData = async (id) => {
  try {
    const response = await axios.delete(`/admin_users/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const userPermission = async (data) => {
  try {
    const response = await axios.post(`/assign_user_permissions/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPermissionByID = async () => {
  try {
    const response = await axios.get(`/get_user_permissions/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};


export const updateUserStatus = async (id, data) => {
  try {
    const response = await axios.post(`/users_deactivate/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const contactUs = async (data) => {
  try {
    const response = await axios.post(`/contact_us/`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const bookingInformation = async (data) => {
  try {
    const response = await axios.get(`/booking_information/?status=${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const planDescription = async () => {
  try {
    const response = await axios.get(`/payment/subscription-items/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_payment_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const planAmountChange = async (id, data) => {
  try {
    const response = await axios.put(`/payment/subscription-items/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};


export const planCreate = async () => {
  try {
    const response = await axios.post(`/payment/create/`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_payment_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const paymentExecute = async (data) => {
  try {
    const response = await axios.get(`/payment/execute/?paymentId=${data?.paymentId}&token=${data?.token}&PayerID=${data?.PayerID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const savingPaymentSucess = async (data) => {
  try {
    const response = await axios.get(`/payment/payment-success/?paymentId=${data?.paymentId}&token=${data?.token}&PayerID=${data?.PayerID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const pastFlightsDetails = async () => {
  try {
    const response = await axios.get(`/past_flights_details/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const fetchPastBookingsById = async (id) => {
  try {
    const response = await axios.get(`/past_flights_details?id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};

export const flightOffersById = async (id) => {
  try {
    const response = await axios.get(`/flight-offers/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const layoversFlight = async (id) => {
  try {
    const response = await axios.get(`/layovers_flight/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getFlightMatchssss = async (id) => {
  try {
    const response = await axios.get(`/current_price_by_id/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const subscribedUsers = async () => {
  try {
    const response = await axios.get(`/subscribed_users/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};


export const getPieData = async () => {
  try {
    const response = await axios.get(`/flight_substitutes_count/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const userFlightsHistory = async () => {
  try {
    const response = await axios.get(`/user_flights_history/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createFlightPair = async (body) => {
  try {
    const response = await axios.post(`/create_flight_pair/`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateFlightPair = async (body) => {
  try {
    const response = await axios.put(`/create_flight_pair/`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const userPaidAmounts = async () => {
  try {
    const response = await axios.get(`/user_paid_amounts/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// flight-substitutes/

export const getUserFlightsubstitutes = async () => {
  try {
    const response = await axios.get(`/flight-substitutes/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getHotelBookingCount = async () => {
  try {
    const response = await axios.get(`/hotel_substitutes_count/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};



export const currentPriceById = async (id) => {
  try {
    const response = await axios.get(`/current_price_by_id/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

// hotel-substitutes/
export const hotelStatusDetails = async () => {
  try {
    const response = await axios.get(`/hotel-substitutes/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const transferOffers = async (id) => {
  try {
    const response = await axios.get(`/transfer_offers/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return (
      error.response || { status: 500, data: { message: "Unknown error" } }
    );
  }
};