import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import React from "react";
import Icon1 from "assets/user-dash/file_icon.png"
import Icon2 from "assets/user-dash/setting_icon.png"
import Icon3 from "assets/user-dash/check_icon.png"
import Icon4 from "assets/user-dash/custome_icon.png"

const UserSatusdetail = ({ users, pieData }) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="light"
                            icon={Icon4}
                            title=" No. Of Paying Customers"
                            count={users ? users?.length : 0}
                            percentage={{
                                color: "success",
                                amount: "36,006",
                                label: " No. Of Paying Customers",
                            }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            icon={Icon3}
                            title="Accepted Bookings"
                            count={pieData?.data?.accept ? pieData?.data?.accept : 0}
                            percentage={{
                                color: "success",
                                amount: "+3%",
                                label: "Accepted Bookings",
                            }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="primary"
                            icon={Icon3}
                            title="Rejected Bookings"
                            count={pieData?.data?.reject ? pieData?.data?.reject : 0}
                            percentage={{
                                color: "success",
                                amount: "+1%",
                                label: "Rejected Bookings",
                            }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="primary"
                            icon={Icon4}
                            title="Eliminated Bookings"
                            count={pieData?.data?.eliminate ? pieData?.data?.eliminate : 0}
                            percentage={{
                                color: "success",
                                amount: "+1%",
                                label: "Eliminated Bookings",
                            }}
                        />
                    </MDBox>
                </Grid>
            </Grid>
        </>
    )
}

export default UserSatusdetail;