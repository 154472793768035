import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const EmailData = () => {

    const [show, setShow] = useState(true)

    const navigate = useNavigate()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    useEffect(() => {
        const token = localStorage.getItem("user_access_token");


        setTimeout(() => {
            if (token) {
                if (location.pathname?.includes("/send-car-notification")) {
                    localStorage.setItem("active-tab", 3)
                } else if (location.pathname?.includes("/send-hotel-notification")) {
                    localStorage.setItem("active-tab", 2)
                } else if (location.pathname?.includes("/send-notification")) {
                    localStorage.setItem("active-tab", 1)
                }
                navigate("/dashboard", { state: id })
            } else {
                navigate("/login")
            }
        }, 4000)
    }, [])

    const handlecancel = () => {
        setShow(false)
        const token = localStorage.getItem("user_access_token");
        localStorage.setItem("active-tab", 1)
        if (token) {
            navigate("/dashboard", { state: id })
            if (location.pathname?.includes("/send-car-notification")) {
                localStorage.setItem("active-tab", 3)
            } else if (location.pathname?.includes("/send-hotel-notification")) {
                localStorage.setItem("active-tab", 2)
            } else if (location.pathname?.includes("/send-notification")) {
                localStorage.setItem("active-tab", 1)
            }
        } else {
            navigate("/login")
        }
    }

    return (
        <>
            <div className="bg_image_home"></div>
            <Modal show={show} centered className="layover_modal">
                {/* <Modal.Header closeButton>
                    
                </Modal.Header> */}
                <Modal.Body>
                    <div className="cancel_modal_section">
                        <i onClick={handlecancel} className="bi bi-x-circle"></i>
                    </div>
                    <div className="p-5 text-center">
                        <img className="mb-3" src="/favicon.png" />
                        <h4 className="received_msj">
                            We've received your Booking Confirmation and
                            will get back to you with the best alternatives shortly!
                        </h4>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default EmailData