import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { fetchFlightOffers } from "utils/api";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import { decodeIdWithType } from "utils/encoder";
import { fetchSubstitueHotel } from "utils/api";
import UserFlightBooking from "./booking";
import UserSubstitute from "./substitute";
import { flightBookingDetails } from "utils/api";
import { HotelBookingDetails } from "utils/api";
import HotelDetail from "./HotelDetail";
import CarDetails from "./CarDetails";
import { CarBookingDetails } from "utils/api";

function FlightDetail() {
  const params = useParams();
  const [loadingBookings, setLoadingBookings] = useState(true);
  const [error, setError] = useState(null);
  const { type, id } = decodeIdWithType(params.id);
  const [booking_data, setBookingData] = useState([]);
  const [substitutes, setSubstitutes] = useState([]);

  const userId = localStorage.getItem("user-sub-id")

  useEffect(() => {
    if (id) {
      if (type === "flights") {
        flightDetails()
      } else if (type === "hotels") {
        getHotelDetails()
      } else if (type === "cars") {
        setLoadingBookings(true);
        CarBookingDetails(id)
          .then((response) => {
            setBookingData(response?.data);
            setLoadingBookings(false);
          })
          .catch((error) => {
            setLoadingBookings(false);
            console.log(error);
          });
        setSubstitutes([]);
      }
    } else {
      setError(new Error("No flight ID provided."));
      setLoadingBookings(false);
    }
  }, [id]);

  const flightDetails = () => {
    flightBookingDetails(id)
      .then((response) => {
        setBookingData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
    fetchFlightOffers(id).then((res) => {
      setLoadingBookings(false);
      if (res?.data) {
        setSubstitutes(Array.isArray(res?.data?.paired_offers) ? res?.data?.paired_offers : []);
      } else {
        setSubstitutes([]);
      }
    });
  }

  const getHotelDetails = () => {
    HotelBookingDetails(id)
      .then((response) => {
        setBookingData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
    fetchSubstitueHotel(id).then((res) => {
      setLoadingBookings(false);
      if (res.data) {
        setSubstitutes(Array.isArray(res.data) ? res.data : []);
      } else {
        setSubstitutes([]);
      }
    });
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {loadingBookings ? (
          <Loader />
        ) : (
          <>
            {type === "flights" ? (
              <>
                <UserFlightBooking details={booking_data} userId={userId} />
                {substitutes.length > 0 ? (
                  <UserSubstitute
                    userId={userId}
                    substitutes={substitutes}
                    loading={loadingBookings}
                    details={booking_data}
                    allBookingData={flightDetails}
                    setLoading={setLoadingBookings}
                    current_price={booking_data[0]?.price}
                  />
                ) : (
                  <MDTypography variant="h6" color="textSecondary">
                    No Substitutes Found
                  </MDTypography>
                )}
              </>
            ) : type === "hotels" ? (
              <>
                <HotelDetail
                  allBookingData={getHotelDetails}
                  booking={booking_data}
                  substitutes={substitutes}
                  loading={loadingBookings}
                />
              </>
            ) : type === "cars" ? (
              <>
                <CarDetails
                  booking={booking_data}
                  substitutes={substitutes}
                  loading={loadingBookings}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default FlightDetail;
