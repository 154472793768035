import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { addUser, getUserByID, updateUser } from "utils/userApi";
import MDButton from "components/MDButton";
import { Grid, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function AddUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const params = useLocation()
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    first_name: null,
    last_name: null,
    email: null,
    user_type: 2,
    phone_number: null,
    password: null,
    confirm_password: null,
  });

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    password: null,
    confirm_password: null,
  });

  useEffect(() => {
    if (!!id) {
      setLoading(true)
      const fetchUser = async () => {
        try {
          const response = await getUserByID(id);
          setLoading(false)
          const userData = response.data;
          setUser({
            first_name: userData.first_name || "",
            last_name: userData.last_name || "",
            email: userData.email || "",
            user_type: userData.user_type || "User",
            phone_number: userData?.phone_number,
            password: "",
            confirm_password: "",
          });
        } catch (error) {
          setLoading(false)
          console.log("Failed to fetch user data");
        }
      };
      fetchUser();
    }
  }, [id]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validate = (name, value) => {
    let errorMsg = "";

    if (name === "first_name" || name === "last_name") {
      if (!value?.trim()) errorMsg = name.replace("_", " ") + " is required.";
    }

    if (name === "phone_number") {
      if (!value?.trim()) {
        errorMsg = "Phone number is required.";
      } else if (value?.length < 7 || value?.length > 15) {
        errorMsg = "Please enter valid phone number";
      }
    }

    if (name === "email") {
      if (!value?.trim()) {
        errorMsg = "Email is required.";
      } else if (!emailRegex.test(value)) {
        errorMsg = "Enter a valid email address.";
      }
    }

    if (name === "password") {
      if (!value?.trim()) {
        errorMsg = "Password is required.";
      } else if (value?.length < 6) {
        errorMsg = "Password must be at least 6 characters.";
      }
    }

    if (name === "confirm_password") {
      if (!value?.trim()) {
        errorMsg = "Confirm Password is required.";
      } else if (value !== user.password) {
        errorMsg = "Passwords do not match.";
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    validate(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let formValid = true;
    const newErrors = { ...errors };
    let obj = user;
    if (id) {
      delete obj["password"];
      delete obj["confirm_password"]
    }
    // Validate each field
    Object.keys(obj).forEach((key) => {
      validate(key, obj[key]);
      if (errors[key] || !obj[key]) formValid = false;
    });


    if (!formValid) {
      setLoading(false);
      toast.error("Please fill the form before submitting.", {
        theme: "colored",
      });
      return;
    }


    const isFormEmpty = Object.values(user).every(value => {
      if (typeof value === "string") return value.trim() === ""; // Trim strings
      return value === null || value === undefined;
    });

    if (isFormEmpty) {
      setLoading(false);
      toast.error("Form cannot be empty. Please fill in the required fields.", {
        theme: "colored",
      });
      return;
    }

    try {
      const userData = { ...user };
      if (!!id && !user.password) {
        delete userData.password;
        delete userData.confirm_password;
      }

      let response;
      if (!!id) {
        response = await updateUser(id, userData);
      } else {
        response = await addUser(userData);
      }

      if (response && (response.status === 200 || response.status === 201)) {
        setLoading(false);
        toast.success(response?.data?.message || "Successfully added", {
          theme: "colored",
        });
        navigate("/user-management");
      } else if (response?.data?.email) {
        toast.error(response?.data?.email[0], {
          theme: "colored",
        });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(`Failed to ${!!id ? "update" : "add"} user`, {
          theme: "colored",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Failed to ${!!id ? "update" : "add"} user`, {
        theme: "colored",
      });
    }
  };


  const handleBackButon = () => {
    navigate(-1);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false)

  const handlePasswordToggle = (type) => {
    if (type == "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirm(!showConfirm);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={() => handleBackButon()} width="24" height="24" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
              </svg>  {!!id ? "Edit" : "Add New User"}
            </MDTypography>
          </MDBox>
          {loading && (
            <Loader />
          )}
          <Grid p={3} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box component="form" autoComplete="off">
                <TextField
                  fullWidth
                  margin="normal"
                  label="First Name"
                  name="first_name"
                  value={user.first_name}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: !!user.first_name }}
                />
                {errors.first_name && (
                  <>
                    <p style={{ color: "red", fontSize: "14px", marginBottom: "0px" }}>
                      {errors.first_name}
                    </p>
                  </>
                )}
                <TextField
                  fullWidth
                  margin="normal"
                  label="Last Name"
                  name="last_name"
                  value={user.last_name}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: !!user.last_name }}
                />
                {errors.last_name && (
                  <>
                    <p style={{ color: "red", fontSize: "14px", marginBottom: "0px" }}>
                      {errors.last_name}
                    </p>
                  </>
                )}
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: !!user.email }}
                  disabled={id}
                />
                {errors.email && (
                  <>
                    <p style={{ color: "red", fontSize: "14px", marginBottom: "0px" }}>
                      {errors.email}
                    </p>
                  </>
                )}
                <TextField
                  fullWidth
                  margin="normal"
                  label="Phone"
                  name="phone_number"
                  value={user.phone_number}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: !!user.phone_number }}
                />
                {errors.phone_number && (
                  <>
                    <p style={{ color: "red", fontSize: "14px", marginBottom: "0px" }}>
                      {errors.phone_number}
                    </p>
                  </>
                )}
                {!id && (
                  <>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={user.password}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: !!user.password }}
                    />
                    <IconButton
                      size="small"
                      disableRipple
                      onClick={() => handlePasswordToggle("password")}
                      style={{ marginLeft: "-40px",marginTop: "25px" }}
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {errors.password && (
                      <>
                        <p style={{ color: "red", fontSize: "14px", marginBottom: "0px" }}>
                          {errors.password}
                        </p>
                      </>
                    )}
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Confirm Password"
                      name="confirm_password"
                      type={showConfirm ? "text" : "password"}
                      value={user.confirm_password}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: !!user.confirm_password }}
                    />
                    <IconButton
                      size="small"
                      disableRipple
                      onClick={() => handlePasswordToggle("confirm")}
                      style={{ marginLeft: "-40px",marginTop: "25px" }}
                    >
                      {!showConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {errors.confirm_password && (
                      <>
                        <p style={{ color: "red", fontSize: "14px", marginBottom: "0px" }}>
                          {errors.confirm_password}
                        </p>
                      </>
                    )}
                  </>
                )}
                <Box mt={2} display="flex" justifyContent="space-between">
                  <MDButton
                    className="cancel_butn"
                    onClick={() => navigate("/user-management", { state: params?.state })}
                  >
                    Cancel
                  </MDButton>
                  <MDButton color="info" type="button" onClick={(e) => handleSubmit(e)}>
                    {!!id ? "Update " : "Add User"}
                  </MDButton>

                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddUser;
