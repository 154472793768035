import React from "react"
import MDButton from "components/MDButton";


const UserStatus = ({ ticket, returnID, item, handleStatus }) => {

    const status = [
        { name: "TIME_DIFFERENCE_TOO_GREAT", value: "Time difference too great" },
        { name: "AIRLINE_UNACCEPTABLE", value: "Airline is unacceptable" },
        { name: "Downgrade", value: "I don’t want downgrade" },
        { name: "WAITING", value: "Waiting" },
        { name: "POLICY_RESTRICTION", value: "Policy Restriction" },
    ]
    return (
        <>
            <div className="col-md-3 col-lg-3 col-5">
                <div className="col-md-12 d-flex justify-content-end  airlinetkt ">
                    <div className="col-md-12 text-end">
                        <div className="flight_detail_butns">
                            {ticket?.status === "PENDING" ?
                                <MDButton
                                    color="white"
                                    className="accept_butn"
                                >
                                    PENDING
                                </MDButton>
                                :
                                <>
                                    <MDButton
                                        color="white"
                                        onClick={() =>
                                            handleStatus(
                                                ticket?.id,
                                                "ACCEPT",
                                                returnID, ticket?.airline_website, ticket, item
                                            )
                                        }
                                        className={`${ticket?.status === "ACCEPT" ? "accepted_butn" : "accept_butn"}`}
                                    >
                                        {ticket?.status === "ACCEPT"
                                            ? "ACCEPTED"
                                            : "ACCEPT"}
                                    </MDButton>

                                    <MDButton
                                        color="white"
                                        className={`${ticket?.status === "REJECT" ? "rejected_butn" : "accept_butn"}`}
                                        onClick={() =>
                                            handleStatus(
                                                ticket?.id,
                                                "REJECT",
                                                returnID
                                            )
                                        }
                                    >
                                        {ticket?.status === "REJECT"
                                            ? "REJECTED"
                                            : "REJECT"}
                                    </MDButton>
                                    <MDButton
                                        color="white"
                                        className={`${ticket?.status === "ELIMINATE" ? "eliminated_butn" : "accept_butn"}`}
                                        onClick={() =>
                                            handleStatus(
                                                ticket?.id,
                                                "ELIMINATE",
                                                returnID
                                            )
                                        }
                                    >
                                        {ticket?.status === "ELIMINATE"
                                            ? "ELIMINATED"
                                            : "ELIMINATE"}
                                    </MDButton>
                                </>
                            }

                            {
                                ticket?.additional_information === "OTHER" ?
                                    <p className="status_reason mb-0">{ticket?.other_reason_text}</p>
                                    :
                                    <>
                                        {
                                            status?.map((item, i) => {
                                                if (item?.name === ticket?.additional_information) {
                                                    return (
                                                        <>
                                                            <p key={i} className="status_reason mb-0">{item?.value}</p>
                                                        </>
                                                    )
                                                }
                                            })
                                        }

                                    </>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserStatus;