import { useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MDTypography from "components/MDTypography";
import MUIDataTable from "mui-datatables";
import { getUserFlightsubstitutes } from "utils/api";
import moment from "moment";
import { Form } from "react-bootstrap";
import { getUser } from "utils/userApi";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["All", "Accepted", "Rejected", "Eliminated"];


function Projects({ user }) {

  const [menu, setMenu] = useState(null);
  const [users, setUser] = useState([]);
  const [data, setData] = useState([])
  const [reasonData, setReasonData] = useState([])
  const [personName, setPersonName] = useState("All");
  const [reasonName, setReasonName] = useState("");
  const [count, setCount] = useState(user?.flight_substitute_counts?.accepted);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  const [statusData] = useState({
    rejected: [
      { name: "All", value: "All" },
      { name: "AIRLINE_UNACCEPTABLE", value: "Airline Unacceptable" },
      { name: "LAYOVER", value: "Layover" },
      { name: "WAITING", value: "Waiting" },
      { name: "OTHER", value: "Other" },
    ],
    eliminated: [
      { name: "All", value: "All" },
      { name: "TIME_DIFFERENCE_TOO_GREAT", value: "Time difference too great" },
      { name: "AIRLINE_UNACCEPTABLE", value: "Airline Unacceptable" },
      { name: "Downgrade", value: "I don’t want downgrade" },
      { name: "OTHER", value: "Other" },
    ],
  });

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    setPersonName(selectedStatus);
    setReasonName("");
    let filteredUsers = [];
    if (selectedStatus === "Accepted") {
      filteredUsers = users?.filter((flight) => flight.status === "ACCEPT");
    } else if (selectedStatus === "Rejected") {
      filteredUsers = users?.filter((flight) => flight.status === "REJECT");
    } else if (selectedStatus === "Eliminated") {
      filteredUsers = users?.filter((flight) => flight.status === "ELIMINATE");
    } else {
      filteredUsers = users;
    }

    setData(filteredUsers);
    setReasonData(filteredUsers)
  };

  const getUserStatus = async () => {
    let flightSubstituteData = await getUserFlightsubstitutes();
    if (flightSubstituteData && flightSubstituteData?.data) {
      let userList = await getUser();
      if (userList && userList?.data) {
        let all_list = [];
        for (let i = 0; i < flightSubstituteData.data.length; i++) {
          const obj = flightSubstituteData.data[i];
          for (let j = 0; j < userList.data.length; j++) {
            const item = userList.data[j];
            if ((obj.status == "ACCEPT" || obj.status === "REJECT" || obj.status == "ELIMINATE") && (obj.flight_information.sender_email === item?.email)) {
              all_list.push({ ...obj, full_name: item.first_name + " " + item.last_name, id: item.id });
              break;
            }
            // if ((obj.flight_information.sender_email === item?.email) && obj.status == "ACCEPT") {
            //   list.push({ ...obj, full_name: item.first_name + " " + item.last_name })
            // }
          }
        }
        setData(all_list);
        setUser(all_list);
      }
    }
  }

  useEffect(() => {
    getUserStatus()
  }, [])


  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              textAlign: "left",
            },
            head: {
              fontWeight: "bold",
              textAlign: "left",
            },
          },
        },
      },
    });

  const options = {
    // filterType: "checkbox",
    filter: false,
    selectableRows: "none",
    responsive: "stacked",
    print: false,
    download: false,
    viewColumns: false,
    onRowClick: (rowData) => {
      const userId = rowData.find((_, index) => columns[index].name === "id");
      if (userId) {
        navigate(`/user-details/${userId}`);
        localStorage.setItem("user-sub-id", userId);
      }
    },
  };



  const userData = data?.map((flight) => ({
    id: flight?.id,
    full_name: flight?.full_name,
    email: flight.flight_information.sender_email,
    flight_Detail: flight,
    status: flight.status,
    date_time: flight.flight_information?.updated_at,
    other_reason_text: flight.other_reason_text || "N/A",
    additional_information: flight.additional_information || "N/A",
  }));

  const columns = useMemo(() => {
    let baseColumns = [
      {
        name: "id",
        label: "ID",
        options: { display: "false" }, // Hide ID column in UI but keep it for reference
      },
      {
        name: "full_name",
        label: "Name",
        options: {
          customBodyRender: (value) => (
            <div>
              <p style={{marginBottom:"0px", fontWeight:"500"}}>{value}</p>
            </div>
          ),
        },
      },

      // {
      //   name: "email",
      //   label: "Email",
      //   options: {
      //     customBodyRender: (value) => (
      //       <MDTypography variant="caption" color="text" fontWeight="medium">
      //         {value}
      //       </MDTypography>
      //     ),
      //   },
      // },
      {
        name: "flight_Detail",
        label: "Departure Detail",
        options: {
          customBodyRender: (value) => (
            <div className="flight_detail_user">
              <div>
                <p>{moment(value?.departure_time).format('LT')}</p>
                <p>{value?.departure_iata_code}</p>
                <p>{moment(value?.departure_time).format('L')}</p>
              </div>
            </div>
          ),
        },
      },
      {
        name: "flight_Detail",
        label: "Arrival Detail",
        options: {
          customBodyRender: (value) => (
            <div className="flight_detail_user">
              <div>
                <p>{moment(value?.arrival_time).format('LT')}</p>
                <p>{value?.arrival_iata_code}</p>
                <p>{moment(value?.arrival_time).format('L')}</p>
              </div>
            </div>
          ),
        },
      },
      {
        name: "date_time",
        label: "Date & Time",
        options: {
          customBodyRender: (value) => {
            return (
              <div className="flight_detail_user">
                <div>
                  <p>{moment(value).format('L')}</p>
                  <p>{moment(value).format('LT')}</p>
                </div>
              </div>
            );
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          customBodyRender: (value) => (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {value == "ELIMINATE" ? `${value}D` : `${value}ED`}
            </MDTypography>
          ),
        },
      },

    ];

   if (personName === "Rejected" || personName === "Eliminated") {
      baseColumns.push({
        name: "additional_information",
        label: "Reason",
        options: {
          customBodyRender: (value) => {
            const reasons = value

            const formatReason = (reason) =>
              reason
                .toLowerCase()
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase());

            return (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {reasons ? formatReason(reasons) : ""}
              </MDTypography>
            );
          },
        },
      });
    }

    if (reasonName === "OTHER") {
      baseColumns.push({
        name: "other_reason_text",
        label: "Other Reason",
        options: {
          customBodyRender: (value) => (value ? value : "N/A"),
        },
      });
    }

    return baseColumns;
  }, [personName]);



  const handleReasonChange = (e) => {
    const reason = e.target.value;
    setReasonName(reason);

    const filteredUsers = reasonData?.filter(
      (flight) =>
        flight.status === (personName === "Rejected" ? "REJECT" : "ELIMINATE") &&
        flight.additional_information?.includes(reason)
    );

    setData(filteredUsers);
  };



  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Substitutes Report
          </MDTypography>
          {/* <MDBox display="flex" alignItems="center" lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>30 done</strong> this month
            </MDTypography>
          </MDBox> */}
        </MDBox>
        <MDBox color="text" px={2} style={{ display: "flex" }}>
          <Form.Group className="status-select-container mx-2" style={{width:"150px"}} controlId="status-select">
            <Form.Select
              value={personName}
              onChange={handleChange}
              className="custom-status-select "
            >
              {/* <option value="">{personName}</option> Placeholder option */}
              {names.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {(personName === "Rejected" || personName === "Eliminated") && (
            <Form.Group className="status-select-container mx-2" controlId="reason-select">
              <Form.Select
                value={reasonName}
                onChange={handleReasonChange}
                className="custom-status-select "
              >
                {statusData[personName?.toLowerCase()]?.map((item) => (
                  <option key={item.name} value={item.name}>
                    {item.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </MDBox>
      </MDBox>
      <MDBox>
        {userData?.length > 0 ? (
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={""}
              data={userData}
              columns={columns}
              options={options}
              className="user_data_table"
            />
          </ThemeProvider>
        )
          :
          <div className="text-center my-5">
            <MDTypography variant="h4">No Substitutes Record Found</MDTypography>
          </div>
        }
      </MDBox>
    </Card >
  );
}

export default Projects;
