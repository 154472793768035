import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function VerifyEmail() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(Array(6).fill("")); 

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];

    if (/^\d*$/.test(value) && value.length <= 1) {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        const nextInput = document.querySelector(
          `input[name="otp-${index + 1}"]`
        );
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };


  const handleSubmit = () => {
    const otpCode = otp.join("");
  };

  return (
    <CoverLayout>
      <>
        <div className="verify_main">
          <div className="verify_card">
            <img src={bgImage} alt="logo" />
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="#344585"
              mt={3}
            >
              Verify Your Email
            </MDTypography>

            <MDTypography display="block" variant="button" color="blue" my={1}>
              Verification code has been sent on codyFisher@hello.com
            </MDTypography>

            <div
              className="otp_input_container"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  name={`otp-${index}`}
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  maxLength="1"
                  style={{
                    width: "40px",
                    height: "40px",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                />
              ))}
            </div>
            <div style={{ marginTop: "30px" }}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit}
              >
                Confirm Code
              </MDButton>
            </div>
          </div>
        </div>
      </>
    </CoverLayout>
  );
}

export default VerifyEmail;
