import React from 'react'
import { Navigate } from 'react-router-dom';

const AdminAuth = ({ children }) => {
    const token = localStorage.getItem("user_access_token");
    const user = JSON.parse(localStorage.getItem("active-user"));


    return (token && (user?.user_type == 3)) ? (
        children
    ) : (
        <Navigate to={"/login"} />
    )
}

export default AdminAuth