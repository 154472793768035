import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Navlogo from "assets/img/Navlogo.png";
import { Link, useNavigate } from "react-router-dom";
import { getProfile } from "utils/api";

const Navbar = () => {
  const token = localStorage.getItem("user_access_token");
  const [profile, setProfile] = useState("");
  const subscritipn = localStorage.getItem("is_subscription");
  const navigate = useNavigate();
  const [toggel, setToggel] = useState(false)

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const token = localStorage.getItem("user_access_token");
    const userdata = localStorage.getItem("active-user");
    const newdata = JSON.parse(userdata)
    if (token) {
      setProfile(newdata);
    }
  }, []);

  const handleToggel = () => {
    setToggel(!toggel)
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={Navlogo} alt="AiRebooker Logo" className="navbar-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => handleToggel()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="navbarnavhome">
              <ul className="navbar-nav ">
                <li className="nav-item home_name">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item home_name">
                  <Link className="nav-link" to="/about-us">
                    About
                  </Link>
                </li>
                {/* <li className="nav-item home_name">
                <Link className="nav-link" to="/">
                  Services
                </Link>
              </li>
              <li className="nav-item home_name">
                <Link className="nav-link" to="/">
                  Features
                </Link>
              </li> */}
                {
                  token &&
                  <li className="nav-item home_name">
                    <Link className="nav-link" to="/subscription">
                      Pricing
                    </Link>
                  </li>
                }
                <li className="nav-item home_name">
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="mobile_buttons">
            {(profile?.user_type === 1 || profile?.user_type === 3 || subscritipn === "true" || subscritipn === true) ? (
              <div className="dashboard_logout">
                <div>
                  <Link to="/dashboard">
                    <button className="buttonhead">Dashboard</button>
                  </Link>
                </div>

                <div
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <button className="buttonhead">Logout</button>
                </div>
              </div>
            ) : (
              <>
                <Link to="/login">
                  <button className="buttonhead">Login</button>
                </Link>
                <Link to="/register">
                  <button className="buttonhead">Register</button>
                </Link>
              </>
            )}
          </div>


        </div>

      </nav>
      {
        toggel &&
        <>
          <div className="collapse navbar-collapse new_navbar" id="navbarNav">
            <div className="navbarnavhome">
              <ul className="navbar-nav mobile_nav">
                <li className="nav-item new_navbar_list">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item new_navbar_list">
                  <Link className="nav-link" to="/about-us">
                    About
                  </Link>
                </li>
                {/* <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Features
                    </Link>
                  </li> */}
                {
                  token &&
                  <li className="nav-item new_navbar_list">
                    <Link className="nav-link" to="/subscription">
                      Pricing
                    </Link>
                  </li>
                }
                <li className="nav-item new_navbar_list">
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              {(profile?.user_type === 1 || profile?.user_type === 3 || subscritipn === "true" || subscritipn === true) ? (
                <div className="dashboard_logout">
                  <div>
                    <Link to="/dashboard">
                      <button className="new_navbar_butns">Dashboard</button>
                    </Link>
                  </div>

                  <div
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    <button className="new_navbar_butns">Logout</button>
                  </div>
                </div>
              ) : (
                <div className="dashboard_logout">
                  <Link to="/login">
                    <button className="new_navbar_butns">Login</button>
                  </Link>
                  <Link to="/register">
                    <button className="new_navbar_butns">Register</button>
                  </Link>
                </div>
              )}
            </div>
          </div>

        </>
      }
    </>
  );
};

export default Navbar;
