import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { useEffect, useRef, useState } from "react";
import { fetchBookings } from "utils/api";
import RebookCards from "./components/rebook";
import BookingCard from "./components/booking";
import HotelBook from "./components/hotelbook";
import CarRental from "./components/carRental";
import { fetchSubstitueHotel } from "utils/api";
import { fetchFlightOffers } from "utils/api";
import { flightOffersById } from "utils/api";
import { flightAmadeusHotels } from "utils/api";
import { useLocation } from "react-router-dom";
import { fetchCarSubstitutes } from "utils/api";

function UserDashboard() {
  const [profile, setProfile] = useState("");
  const [active_tab, setActiveTab] = useState(1);
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [carData, setCarData] = useState([]);
  const [hotelLoader, setHotelLoader] = useState(false)
  const [carLoader, setCarLoader] = useState(false)

  useEffect(() => {
    setLoading(true);
    allBookingData();
  }, []);

  const allBookingData = () => {
    setLoading(true);
    setHotelLoader(true)
    fetchBookings()
      .then(async (response) => {
        getFlights(response.data.flights)
        getHotels(response.data.hotels)
        getCars(response?.data?.cars)
      })
      .catch((error) => {
        setLoading(false);
      });

  };

  const getFlights = async (data) => {
    if (data?.length > 0) {
      setFlights(data);
      setLoading(false);

      const flightsWithSubstitutes = [];

      for (const item of data) {
        try {
          const firstReturnFlightIndex = item?.children?.findIndex(
            (c) => c?.is_return_flight === true
          );

          // let newres;
          // if (item?.children?.length > 0 && firstReturnFlightIndex >= 0) {
          //   newres = await fetchFlightRoundTripSub(item.id);
          // } else {
          //   newres = await fetchFlightSingleTripSub(item.id);
          // }

          const res = await fetchFlightOffers(item.id);
          const flightOffer = await flightOffersById(item.id);

          const retSubstitues = item?.children?.length > 0 && firstReturnFlightIndex >= 0;

          const substitutes = Array.isArray(res.data.paired_offers)
            ? res.data.paired_offers.map((offer) => ({
              outbound_offer: offer.outbound_offer,
              return_offer: offer.return_offer,
              total_price: offer.total_price,
              match_percentage: offer.match_percentage,
            }))
            : [];

          const same_flight_sub = flightOffer?.data;

          const flightWithSubstitutes = {
            ...item,
            substitutes,
            retSubstitues,
            same_flight_sub: flightOffer.status === 200 ? same_flight_sub : null,
          };

          flightsWithSubstitutes.push(flightWithSubstitutes);

          // Update the flights state incrementally
          setFlights((prevFlights) =>
            prevFlights.map((f) => (f.id === item.id ? flightWithSubstitutes : f))
          );
        } catch (error) {
          console.error(`Error fetching flight offers for ${item.id}:`, error);
        }
      }
    } else {
      setLoading(false);
    }
  }

  const getHotels = async (data) => {
    if (data?.length > 0) {
      setHotels(data);
      setTimeout(() => {
        setHotelLoader(false)
      }, 5000)
      const hotelsWithSubstitutes = [];
      for (const item of data) {
        try {
          // const newres = await flightAmadeusHotels(item.id)
          // console.log('newres-----------' , newres)
          const res = await fetchSubstitueHotel(item.id);
          // console.log('res-----------', res?.data)
          const hotelWithSubstitutes = {
            ...item,
            substitutes: res?.data?.length > 0 ? res.data : [],
          };
          hotelsWithSubstitutes.push(hotelWithSubstitutes);
          setHotels(hotelsWithSubstitutes);
        } catch (error) {
          hotelsWithSubstitutes.push({
            ...item,
            substitutes: [],
          });
          setHotels(hotelsWithSubstitutes);
        }
      }
      setHotelLoader(false)
      setHotels(hotelsWithSubstitutes);
    } else {
      setHotelLoader(false)
    }
  }

  const getCars = async (data) => {
    if (data?.length > 0) {
      setCarData(data);
      setTimeout(() => {
        setCarLoader(false)
      }, 5000)
      const carsWithSubstitutes = [];
      for (const item of data) {
        try {
          const res = await fetchCarSubstitutes(item.id);
          const carWithSubstitutes = {
            ...item,
            substitutes: res?.data?.length > 0 ? res.data : [],
          };
          carsWithSubstitutes.push(carWithSubstitutes);
          setCarData(carsWithSubstitutes);
        } catch (error) {
          carsWithSubstitutes.push({
            ...item,
            substitutes: [],
          });
          setCarData(carsWithSubstitutes);
        }
      }
      setCarData(carsWithSubstitutes);
    } else {
      setTimeout(() => {
        setCarLoader(false)
      }, 5000)
    }
  }


  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("active-user")));
    const tabItem = localStorage.getItem("active-tab")
    if (tabItem) {
      setActiveTab(Number(tabItem))
    } else {
      setActiveTab(1)
    }

    // const clearSearchOnRefresh = () => {
    //   localStorage.removeItem("active-tab");
    // };

    // window.addEventListener("beforeunload", clearSearchOnRefresh);

    // return () => {
    //   window.removeEventListener("beforeunload", clearSearchOnRefresh);
    // };
  }, []);

  const accordionRefs = useRef([]);

  const location = useLocation();
  const bookingId = Number(location?.state);

  useEffect(() => {
    if (!bookingId || flights.length === 0) return;

    const index = flights.findIndex((item) => item?.id === bookingId);

    if (index !== -1 && accordionRefs.current[index]) {
      setTimeout(() => {
        accordionRefs.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200); // Adding delay to ensure UI is rendered
    }
  }, [bookingId]); // Ensure `flights` is part of dependencies

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDTypography className="dashboard_title">
          Welcome{" "}
          <strong style={{ textTransform: "capitalize" }}>{`${profile?.first_name ?? ""} ${profile?.last_name ?? ""
            }`}</strong>
        </MDTypography>
        <RebookCards active={active_tab} handleChange={setActiveTab} />
        {active_tab === 1 && (
          <BookingCard
            accordionRefs={accordionRefs}
            bookingId={bookingId}
            flights={flights}
            loading={loading}
            setLoading={setLoading}
            allBookingData={allBookingData}
          />
        )}
        {active_tab === 2 && (
          <HotelBook
            hotels={hotels}
            // loading={loading}
            loading={hotelLoader}
            allBookingData={allBookingData}
          />
        )}
        {active_tab === 3 && (
          <CarRental
            carData={carData}
            // loading={loading}
            loading={carLoader}
            setLoading={setCarLoader}
            allBookingData={allBookingData}
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default UserDashboard;
