import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
    Button,
    FormControl,
    OutlinedInput,
    Pagination,
    InputLabel,
    MenuItem,
    Select,
    Card,
} from "@mui/material";
import { updateHotelStatus } from "utils/api";
import CarSubstitute from "components/CarDetails/carSubstitute";
import Cardetail from "components/CarDetails";
import { useNavigate } from "react-router-dom";

const CarBooking = ({
    booking,
    substitutes,
    fetchAllSubstitute,
    passBooking,
}) => {

    const [show, setShow] = React.useState(false);
    const [status, setStatus] = React.useState(null);
    const [selectedReason, setSelectedReason] = React.useState("");
    const [selectMessage, setSelectMesage] = React.useState("");
    const [pageData, setPageData] = useState([]);
    const [bookingType, setBookingType] = useState("");
    const itemsPerPage = 10;
    const [page, setPage] = useState(1);
    const sortOptions = [
        "Price: Low to High",
        "Price: High to Low",
    ];

    const navigate = useNavigate()

    useEffect(() => {
        const filteredData = substitutes
        setPageData(filteredData)
    }, [substitutes])

    const handleClear = () => {
        setPageData(substitutes)
        setBookingType("")
    }

    const pageCount = Math.ceil(pageData?.length / itemsPerPage);

    const displayedItems = pageData?.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleBookingType = (event) => {
        const selectedOption = event.target.value;
        setBookingType(selectedOption);
        const sortedData = [...pageData];
        if (selectedOption === "Price: Low to High") {
            sortedData.sort((a, b) => a.price_total - b.price_total);
        } else if (selectedOption === "Price: High to Low") {
            sortedData.sort((a, b) => b.price_total - a.price_total);
        } else if (selectedOption === "Hotel Name") {
            sortedData.sort((a, b) =>
                a.hotel_name.localeCompare(b.hotel_name)
            );
        } else if (selectedOption === "Distance") {
            sortedData.sort((a, b) => a.distance_to_main_location - b.distance_to_main_location);
        }
        setPageData(sortedData);
    };

    const REASON_CHOICES = [
        ["INCOMPLETE_RECORDS", "Incomplete Records"],
        ["TECHNICAL_ISSUES", "Technical Issues"],
        ["POLICY_RESTRICTIONS", "Policy Restrictions"],
        ["HUMAN_ERROR", "Human Error"],
        ["NOT_RELEVANT", "Not Relevant"],
        ["OTHER", "Other"],
    ];

    const handleChange = (event) => {
        setSelectedReason(event.target.value);
        setSelectMesage("");
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleStatus = (id, status) => {
        setShow(id);
        setStatus(status);
    };

    const handleUpdateStatus = () => {
        if (!selectedReason && status !== "ACCEPT") {
            setSelectMesage("Please select any reason");
        } else {
            updateHotelStatus(show, {
                status: status,
            })
                .then((res) => {
                    if (res.status == 200) {
                        toast.success(`Status ${status == "ELIMINATE"
                            ? `${status?.toLowerCase()}d`
                            : `${status?.toLowerCase()}ed`
                            } successfully`, {
                            theme: "colored",
                        });
                        fetchAllSubstitute();
                    } else {
                        toast.error(`Failed to ${status?.toLowerCase()} status`, {
                            theme: "colored",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const getSaving = (item_price, ticket_price) => {
        // const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
        const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
        const savings = (ticketPrice - item_price).toFixed(2);
        return parseFloat(savings);
    };

    const handleBackButon = () => {
        navigate(-1)
    }

    return (
        <>
            <MDBox py={3}>
                <div className="d-flex gap-2 mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={() => handleBackButon()} width="24" height="24" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                    <MDTypography variant="p" className="p-title">Price Monitor</MDTypography>
                </div>
                <div className="container-fluid mb-4 mainpricemonitor">
                    <div className="row align-items-center px-3 py-2">
                        {/* {booking.length > 0 ? (
                            booking.map((item, index) => (
                                <div key={index}> */}
                        <Cardetail item={booking?.[0]} />

                        {/* </div>
                            ))
                        ) : (
                            <p>No data available</p>
                        )} */}
                    </div>
                </div>
            </MDBox>
            {displayedItems?.length > 0 ? (
                <>
                    <div className="substitute_heading mb-3">
                        <MDTypography variant="p" className="p-title">
                            Substitute Tickets
                        </MDTypography>
                        <div className="search_clear">
                            <FormControl sx={{ width: 200 }} className="me-1">
                                <InputLabel id="demo-multiple-booking_type-label">Sort by</InputLabel>
                                <Select
                                    className="sort_by_select"
                                    labelId="demo-multiple-booking_type-label"
                                    id="demo-multiple-booking_type"
                                    value={bookingType}
                                    onChange={handleBookingType}
                                    input={<OutlinedInput label="Booking" />}
                                >
                                    {sortOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <button className="clear_butn" onClick={() => handleClear()}>Clear</button>
                        </div>
                    </div>
                    {
                        displayedItems?.map((cars, i) => {
                            return (
                                <Card className="hotel_cards my-2">
                                    <CarSubstitute data={cars} />
                                </Card>
                            )
                        })
                    }


                    <Pagination
                        count={pageCount}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                    />
                </>
            ) : (
                <MDTypography variant="h6" color="textSecondary">
                    No Substitutes Found
                </MDTypography>
            )}

            <Modal show={show} onHide={handleClose} centered>
                {/* <Modal.Header closeButton>
        </Modal.Header> */}
                <Modal.Body>
                    <div className="text-center p-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            className="bi bi-check-circle-fill status_icon"
                            viewBox="0 0 16 16"
                        >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                        {status == "ACCEPT" &&
                            <MDTypography variant="p" className="delete_msj">
                                We are glad to know, you would like to Accept this Offer!
                            </MDTypography>
                        }

                        {status == "REJECT" &&
                            <MDTypography variant="p" className="delete_msj">
                                Please let us know why you are Rejecting this offer.
                            </MDTypography>
                        }

                        {status == "ELIMINATE" &&
                            <MDTypography variant="p" className="delete_msj">
                                Are you sure? You would like to Eliminate this offer.
                            </MDTypography>
                        }
                        {status !== "ACCEPT" && (
                            <div className="my-3 text-left">
                                <FormControl fullWidth>
                                    <InputLabel id="reason-select-label">
                                        Select Reason
                                    </InputLabel>
                                    <Select
                                        labelId="reason-select-label"
                                        value={selectedReason}
                                        onChange={handleChange}
                                        label="Select Reason"
                                        className="select_reason_input"
                                    >
                                        {REASON_CHOICES?.map((reason) => (
                                            <MenuItem key={reason[0]} value={reason[0]}>
                                                {reason[1]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className="error_message my-1">{selectMessage}</div>
                            </div>
                        )}
                        <Button
                            variant="secondary"
                            className="cancel_butn"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            className="delete_butn"
                            onClick={() => {
                                handleUpdateStatus();
                            }}
                        >
                            Update
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CarBooking;
