import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Airline from "assets/user-dash/airport.png";
import CarImage from "assets/user-dash/car_img.png";
import HotelImage from "assets/user-dash/hotel_img.png";
import RightArrow from "assets/user-dash/rightArrow.png";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";

function RebookCards({ handleChange }) {
  return (
    <MDBox>
      <Card className="main_card">
        <Grid container className="dashboard_outer">
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={2} className="rebook_card_space">
              <Grid item xs={4} md={4} lg={4}>
                <Card className="card_bg" onClick={() => {
                  handleChange(1)
                  localStorage.setItem("active-tab", 1)
                }}>
                  <div className="rebook_cards">
                    <div>
                      {" "}
                      <MDTypography variant="h6">Rebook <br /> Flight</MDTypography>
                      <img src={RightArrow} alt="right-arrow" />
                    </div>
                    <div className="card_image_section">
                      <img src={Airline} alt="airlin-logo" />
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Card className="card_bg" onClick={() => {
                  handleChange(3)
                  localStorage.setItem("active-tab", 3)
                }}>
                  <div className="rebook_cards">
                    <div>
                      {" "}
                      <MDTypography variant="h6">Rent <br /> Car</MDTypography>
                      <img src={RightArrow} alt="right-arrow" />
                    </div>
                    <div className="card_image_section">
                      <img src={CarImage} alt="car-logo" />
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Card className="card_bg" onClick={() => {
                  handleChange(2)
                  localStorage.setItem("active-tab", 2)
                }}>
                  <div className="rebook_cards">
                    <div>
                      {" "}
                      <MDTypography variant="h6">Rebook <br /> Hotel</MDTypography>
                      <img src={RightArrow} alt="right-arrow" />
                    </div>
                    <div className="card_image_section">
                      <img src={HotelImage} alt="hotel-logo" />
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}

export default RebookCards;
