import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import FlightBooking from "./Components/FlightBooking";
import SubstituteTicket from "./Components/SubstituteTicket";
import { useEffect, useState } from "react";
import { fetchFlightOffers, fetchBookingsById } from "utils/api";
import { useLocation, useParams } from "react-router-dom";
import Loader from "components/Loader";
import { decodeIdWithType } from "utils/encoder";
import { fetchSubstitueHotel } from "utils/api";
import HotelBooking from "./Components/HotelBooking";
import { pastFlightsDetails } from "utils/api";
import { fetchPastBookingsById } from "utils/api";
import { flightOffersById } from "utils/api";
import CarBooking from "./Components/Carbooking";
import { fetchFlightRoundTripSub } from "utils/api";
import { fetchFlightSingleTripSub } from "utils/api";
import { fetchReturnById } from "utils/api";
import { layoversFlight } from "utils/api";
import { fetchCarSubstitutes } from "utils/api";

function PriceMonitor() {
  const params = useParams();
  const location = useLocation()
  const [loadingBookings, setLoadingBookings] = useState(true);
  const [error, setError] = useState(null);
  const { type, id } = decodeIdWithType(params.id);
  const [booking_data, setBookingData] = useState([]);
  const [substitutes, setSubstitutes] = useState([]);
  const [totalPrice, setTotalPrice] = useState("")
  const [same_sub, setSameSub] = useState("")
  const [retData, setRetData] = useState(false)

  useEffect(() => {
    fetchAllSubstitute();
  }, [id]);

  const fetchAllSubstitute = () => {
    if (location?.state?.passBooking == "pass_booking") {
      if (id) {
        setLoadingBookings(true);
        fetchPastBookingsById(id)
          .then((response) => {
            if (response.status === 200) {
              const { cars, flights, hotels } = response?.data;
              if (cars.length > 0 || flights.length > 0 || hotels.length > 0) {
                if (type === "flights") {

                  const firstReturnFlightIndex = response?.data?.flights?.[0]?.children?.findIndex(c => c?.is_return_flight === true);
                  // const newres = response?.data?.flights?.[0]?.children?.length > 0 && firstReturnFlightIndex >= 0 ? true : false


                  if (firstReturnFlightIndex >= 0) {
                    setRetData(true)
                    // fetchFlightRoundTripSub(id).then((trip) => {
                    // }).catch((error) => {
                    //   console.log(error)
                    // })
                    fetchReturnById(id).then((trip) => {
                      // setRetData(trip?.data)
                    }).catch((error) => {
                      console.log(error)
                    })
                  } else {

                    // fetchFlightSingleTripSub(id).then((trip) => {
                    //   return trip
                    // }).catch((error) => {
                    //   console.log(error)
                    // })

                  }

                  flightLayoverData(id)

                  setBookingData(response?.data?.flights || []);
                  flightOffersById(id).then((resp) => {
                    if (resp.data.error) {
                      setSameSub(null)
                    } else {
                      setTotalPrice(resp?.data?.total_price)
                      setSameSub(resp?.data)
                      setRetData(true)
                    }

                  }).catch((error) => {
                    console.log(error)
                  })

                } else if (type === "hotels") {
                  setBookingData(response.data.hotels || []);
                  
                  fetchSubstitueHotel(id).then((res) => {
                    setLoadingBookings(false);
                    if (res.data) {
                      setSubstitutes(Array.isArray(res.data) ? res.data : []);
                    } else {
                      setSubstitutes([]);
                    }
                  });
                } else if (type === "cars") {
                  setBookingData(response.data.cars || []);
                  setSubstitutes([]);
                  setLoadingBookings(false);
                }
              } else {
                setError(new Error("No booking data found"));
                return;
              }
            } else {
              setError(
                new Error(
                  "Failed to fetch bookings. Status: " +
                  response.status +
                  ". Message: " +
                  (response.data?.message || "No message")
                )
              );
            }
          })
          .catch((error) => setError(error))
          .finally(() => setLoadingBookings(false));
      } else {
        setError(new Error("No flight ID provided."));
        setLoadingBookings(false);
      }
    } else {
      if (id) {
        setLoadingBookings(true);
        fetchBookingsById(id)
          .then((response) => {
            if (response.status === 200) {
              const { cars, flights, hotels } = response?.data;
              if (cars.length > 0 || flights.length > 0 || hotels.length > 0) {
                if (type === "flights") {
                  const firstReturnFlightIndex = response?.data?.flights?.[0]?.children?.findIndex(c => c?.is_return_flight === true);

                  if (firstReturnFlightIndex >= 0) {
                    // fetchFlightRoundTripSub(id).then((trip) => {
                    // }).catch((error) => {
                    //   console.log(error)
                    // })
                    fetchReturnById(id).then((trip) => {
                      setRetData(trip?.data)
                    }).catch((error) => {
                      console.log(error)
                    })
                  } else {
                    fetchFlightSingleTripSub(id).then((trip) => {
                      return trip
                    }).catch((error) => {
                      console.log(error)
                    })

                  }

                  flightLayoverData(id)

                  // fetchFlightOffers(id).then((res) => {
                  //   setTimeout(() => {
                  //     setLoadingBookings(false);
                  //   }, 3000)
                  //   if (res?.data) {
                  //     setSubstitutes(Array.isArray(res?.data?.paired_offers) ? res?.data?.paired_offers : []);
                  //   } else {
                  //     setSubstitutes([]);
                  //   }
                  // });

                  setBookingData(response.data.flights || []);
                  flightOffersById(id).then((resp) => {
                    if (resp.data.error) {
                      setSameSub(null)
                    } else {
                      setTotalPrice(resp?.data?.total_price)
                      setSameSub(resp?.data)
                    }
                  }).catch((error) => {
                    console.log(error)
                  })

                } else if (type === "hotels") {
                  setBookingData(response.data.hotels || []);
                  fetchSubstitueHotel(id).then((res) => {
                    setLoadingBookings(false);
                    if (res.data) {
                      setSubstitutes(Array.isArray(res.data) ? res.data : []);
                    } else {
                      setSubstitutes([]);
                    }
                  });
                } else if (type === "cars") {
                  setBookingData(response.data.cars || []);
                  fetchCarSubstitutes(id).then((res) => {
                    setLoadingBookings(false);
                    if (res.data) {
                      setSubstitutes(Array.isArray(res.data) ? res.data : []);
                    } else {
                      setSubstitutes([]);
                    }
                  });
                  setLoadingBookings(false);
                }
              } else {
                setError(new Error("No booking data found"));
                return;
              }
            } else {
              setError(
                new Error(
                  "Failed to fetch bookings. Status: " +
                  response.status +
                  ". Message: " +
                  (response.data?.message || "No message")
                )
              );
            }
          })
          .catch((error) => setError(error))
          .finally(() => setLoadingBookings(false));
      } else {
        setError(new Error("No flight ID provided."));
        setLoadingBookings(false);
      }
    }

  };

  const parseDuration = (isoDuration) => {
    const match = isoDuration.match(/PT(\d+H)?(\d+M)?/);
    const hours = match[1] ? parseInt(match[1].replace("H", ""), 10) : 0;
    const minutes = match[2] ? parseInt(match[2].replace("M", ""), 10) : 0;
    return { hours, minutes };
  };


  const flightLayoverData = async (id) => {
    setTimeout(() => {
      setLoadingBookings(false);
    }, 3000)

    try {
      const res = await fetchFlightOffers(id);

      if (res?.data?.paired_offers?.length > 0) {
        const substitutes = await Promise.all(
          res.data.paired_offers.map(async (offer) => {
            try {
              const response = await layoversFlight(offer?.outbound_offer?.id);
              let totalHours = 0;
              let totalMinutes = 0;
              let formattedDuration = null;

              if (response.data?.[0]?.substitute_flight === offer?.outbound_offer?.id) {
                const itineraries = response.data?.[0]?.offer_data?.itineraries || [];

                if (itineraries.length > 0) {
                  const duration1 = parseDuration(itineraries[0]?.duration);
                  totalHours += duration1.hours;
                  totalMinutes += duration1.minutes;
                }
                if (itineraries.length > 1) {
                  const duration2 = parseDuration(itineraries[1]?.duration);
                  totalHours += duration2.hours;
                  totalMinutes += duration2.minutes;
                }

                // Convert excess minutes to hours
                totalHours += Math.floor(totalMinutes / 60);
                totalMinutes = totalMinutes % 60;

                // Format duration as "Xh Ym"
                formattedDuration = `${totalHours}h ${totalMinutes}m`;
              }

              return {
                outbound_offer: offer.outbound_offer,
                duration: formattedDuration,
                total_price: offer.total_price,
                match_percentage: offer.match_percentage,
              };
            } catch (error) {
              console.error("Error fetching layover data:", error);
              return {
                outbound_offer: offer.outbound_offer,
                duration: null, // Set duration as null in case of error
                total_price: offer.total_price,
                match_percentage: offer.match_percentage,
              };
            }
          })
        );

        setSubstitutes(substitutes);
      } else {
        setSubstitutes([]);
      }
    } catch (error) {
      console.error("Error fetching flight offers:", error);
      setSubstitutes([]);
    }
  };

  const isLoading = loadingBookings;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isLoading && (
          <Loader />
        )}
        <>
          {type === "flights" ? (
            <>
              <FlightBooking details={booking_data} loading={isLoading}
                setLoading={setLoadingBookings} same_flight_sub={same_sub} totalPrice={totalPrice} />
              {(substitutes.length > 0 || retData?.length > 0 || same_sub) ? (
                <SubstituteTicket
                  retData={retData}
                  details={booking_data}
                  substitutes={substitutes}
                  loading={isLoading}
                  setLoading={setLoadingBookings}
                  passBooking={location?.state?.passBooking}
                  fetchAllSubstitute={fetchAllSubstitute}
                  current_price={booking_data[0].price}
                  same_flight_sub={same_sub}
                />
              ) : (
                <MDTypography variant="h6" color="textSecondary">
                  No Substitutes Found
                </MDTypography>
              )}
            </>
          ) : type === "hotels" ? (
            <>
              <HotelBooking
                fetchAllSubstitute={fetchAllSubstitute}
                booking={booking_data}
                passBooking={location?.state?.passBooking}
                substitutes={substitutes}
              />
            </>
          ) : type === "cars" ? (
            <>
              <CarBooking fetchAllSubstitute={fetchAllSubstitute}
                booking={booking_data}
                passBooking={location?.state?.passBooking}
                substitutes={substitutes}
              />
            </>
          )
            :
            <></>
          }
        </>
      </MDBox>
    </DashboardLayout>
  );
}

export default PriceMonitor;
