import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";
import { encodeIdWithType } from "utils/encoder";
import Cardetail from "components/CarDetails";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import CarSubstitute from "components/CarDetails/carSubstitute";

const CarRental = ({ carData, loading, setLoading }) => {

  const navigate = useNavigate()

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "cars");
    navigate(`/price-monitor/${final_id}`);
    localStorage.setItem("active-tab", 3)
  };

  return (
    <MDBox py={3}>
      <MDTypography variant="h6">My Bookings</MDTypography>
      {loading ? (
        <div className="text-center d-flex justify-content-center mt-3">
          <div className="hotel-loader"></div>
        </div>
      ) : (
        <div>
          {carData?.length > 0 ?
            carData?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
              ?.map((item, index) => (
                <Accordion
                  className="mb-3 flight_substitutes_bg"
                  defaultExpanded="0"
                  key={index}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel1-content`}
                    id={`panel1-header`}
                  >
                    <Cardetail item={item} />
                  </AccordionSummary>
                  <AccordionDetails className="substitues_section">
                    {/* <Card className="hotel_cards"> */}
                    <div className="substitutes my-2">
                      <MDTypography variant="h6" color="white">
                        Substitutes Tickets
                      </MDTypography>
                      {item?.substitutes?.length > 0 && <button
                        className="view_all"
                        onClick={() => {
                          redirection(item.id);
                        }}
                      >
                        View All
                      </button>}
                    </div>

                    {item?.substitutes ?
                      item?.substitutes?.length > 0 ?
                        item?.substitutes?.slice(0, 3)?.map((car, index) => {
                          return (
                            <Card className="hotel_cards my-2 position-relative">
                              <div className="high_matches_hotel">
                                <p className="mb-0"> <strong className="match_percent">
                                  {parseFloat(car?.saved_amount)?.toFixed(2)} Score</strong> </p>
                              </div>
                              <div className="mt-4">
                                <CarSubstitute data={car} />
                              </div>
                            </Card>
                          )
                        })

                        :
                        <div className="text-center py-1">
                          <MDTypography variant="h5" color="white">No substitutes available</MDTypography>
                        </div>

                      :
                      <>
                        <MDTypography
                          className="text-center d-flex gap-1 justify-content-center"
                          variant="h5"
                          color="white"
                        >
                          Loading <div className="loading_data"></div>
                        </MDTypography>
                      </>
                    }


                    {/* </Card> */}
                  </AccordionDetails>
                </Accordion>
              ))
            :
            <>
              <div className="text-center py-3">
                <MDTypography variant="h4">You don't have any Bookings at the moment!                </MDTypography>
              </div>
            </>}
        </div>
      )}
    </MDBox>
  );
};

export default CarRental;
