import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getAdminList } from "utils/api";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteAdminData } from "utils/api";
import Loader from "components/Loader";
import MUIDataTable from "mui-datatables";
import Icon from "@mui/material/Icon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { updateUserStatus } from "utils/api";

const ITEMS_PER_PAGE = 5;

function AdminManagment() {
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [togalStatus, setTogalStatus] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handleClose = () => {
    setShow(false);
    setOpenModal(false);
  };

  const [totalPages, setTotalPages] = useState(0);

  const handleOpenModal = (id, status) => {
    setOpenModal(id);
    setTogalStatus(status);
  };

  const handleStatusToggle = () => {
    setLoading(true);
    updateUserStatus(openModal, { is_active: togalStatus })
      .then((res) => {
        setLoading(false);
        if (res.status == 200) {
          toast.success(res?.data?.message, {
            theme: "colored",
          });
          fetchUsers();
          setOpenModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await getAdminList();
      if (response.status == 200) {
        setLoading(false);
        setUsers(response.data);
        setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE));
      } else {
        setLoading(false);
        toast.error("Failed to fetch users");
        setUsers([]);
      }
    } catch (error) {
      setUsers([]);
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    const searchText = localStorage.getItem("admin_search_value");

    if (searchText) {
      setSearchValue(searchText);
    } else if (location.state?.search) {
      setSearchValue(location.state.search);
    } else {
      setSearchValue("");
    }

    const clearSearchOnRefresh = () => {
      localStorage.removeItem("admin_search_value");
      navigate(location.pathname, { replace: true, state: null });
    };

    window.addEventListener("beforeunload", clearSearchOnRefresh);

    return () => {
      window.removeEventListener("beforeunload", clearSearchOnRefresh);
    };
  }, []);

  const handleDeleteAdmin = async () => {
    try {
      setLoading(true);
      await deleteAdminData(show);
      fetchUsers();
      setShow(false);
      setLoading(false);
    } catch (error) {
      console.error("Error deleting user:", error);
      setShow(false);
      setLoading(false);
    }
  };

  const handleEditAdmin = (id) => {
    navigate(`/update-admin/${id}`, {state : searchValue});
    if(searchValue){
      localStorage.setItem("admin_search_value", searchValue)
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              textAlign: "left", // left content of table cells
            },
            head: {
              fontWeight: "bold",
              textAlign: "left", // left header cells
            },
          },
        },
      },
    });


  const handleDeleteUser = (id) => {
    setShow(id);
  };

  const columns = [
    {
      name: "sr_no",
      label: "Sr. No.",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "90px", textAlign: "left", paddingLeft: "20px" } }),
        setCellHeaderProps: () => ({ style: { width: "90px", textAlign: "left", paddingLeft: "20px" } }),
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "first_name",
      label: "First Name",
    },
    {
      name: "last_name",
      label: "Last Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "is_active",
      label: "Active Status",
      options: {
        sort: false,
        setCellProps: () => ({ style: { width: "130px" } }),
        setCellHeaderProps: () => ({ style: { width: "130px" } }),
        customBodyRender: (value, tableMeta) => {
          const userId = tableMeta.rowData[5];
          return (
            <Button
              variant="contained"
              size="large"
              startIcon={<Icon>{value ? "toggle_on" : "toggle_off"}</Icon>}
              style={{
                color: value ? "green" : "red",
                backgroundColor: "transparent",
                boxShadow: "none",
                minWidth: "auto",
                padding: "8px 5px",
              }}
              onClick={() => handleOpenModal(userId, !value)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "120px" } }),
        setCellHeaderProps: () => ({ style: { width: "120px" } }),
        customBodyRender: (value) => {
          return (
            <MDBox display="flex" justifyContent="left">
              {/* View Button */}

              <Button
                className="user_eye_icon"
                onClick={() => {
                  handleEditAdmin(value);
                }}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                  />
                </svg>
              </Button>
              <Button
                className="user_eye_icon"
                onClick={() => handleDeleteUser(value)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                </svg>
              </Button>
            </MDBox>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    download: false,
    viewColumns: false,
    searchText: searchValue,
    search: true,
    onSearchChange: (searchedText) => {
      setSearchValue(searchedText);
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Admin Management
                </MDTypography>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => navigate("/add-admin")}
                >
                  Add Admin
                </Button>
              </MDBox>
              {loading ? (
                <Loader />
              ) : (
                <MDBox pt={3}>
                  <div className="table-container">
                    <div className="custom-table">
                      {users?.length > 0 ? (
                        <div className="tableContainer">
                          <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                              title={""}
                              data={users}
                              columns={columns}
                              options={options}
                              className="user_data_table"
                            />
                          </ThemeProvider>
                        </div>
                      )
                        :
                        <div className="text-center mt-5">
                          <MDTypography variant="h3">No Data Found</MDTypography>
                        </div>
                      }
                    </div>
                  </div>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Modal show={show} onHide={handleClose} centered className="layover_modal">
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              className="bi bi-x-circle cancel_icon mb-4"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
            <MDTypography variant="h5">Are you Sure?</MDTypography>
            <MDTypography variant="p" className="delete_msj">
              Do you really want to delete this Admin?
            </MDTypography>
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleDeleteAdmin();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={openModal} onHide={handleClose} centered className="layover_modal">
        <Modal.Body>
          <div className="text-center p-4">
            <img src="/favicon.png" width={100} className="mb-4" />
            <MDTypography variant="h5">Are you Sure?</MDTypography>
            <MDTypography variant="p" className="delete_msj">
              Do you want to {!togalStatus ? "Deactivate" : "Active"} the Admin?
            </MDTypography>
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleStatusToggle();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </DashboardLayout>
  );
}

export default AdminManagment;
