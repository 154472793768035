import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import FlightDetails from "components/FlightDetails";

const UserFlightBooking = ({ details }) => {
  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const totalPrice = (price1, price2) => {
    return Number(price1) + Number(price2)
  }

  const getDestinationCity = (details) => {
    if (details?.children?.length > 0) {
      const nonReturnFlights = details.children.filter(child => !child.is_return_flight);

      if (nonReturnFlights.length > 0) {
        return nonReturnFlights[nonReturnFlights.length - 1].destination_city;
      }
    }
    return details?.destination_city;
  };

  const getFirstReturnFlightDepartureCity = (details) => {
    if (details?.children?.length > 0) {
      const firstReturnFlight = details?.children?.sort((a, b) => a.id - b.id)?.find(child => child.is_return_flight);
      return firstReturnFlight ? firstReturnFlight?.departure_city : details?.departure_city;
    }
    return details?.departure_city;
  };

  const getLastReturnFlightDestinationCity = (details) => {
    if (details?.children?.length > 0) {
      const returnFlights = details?.children?.sort((a, b) => a.id - b.id)?.filter(child => child.is_return_flight);
      if (returnFlights?.length > 0) {
        return returnFlights[returnFlights?.length - 1]?.destination_city;
      }
    }
    return details?.destination_city;
  };

  return (
    <MDBox py={3}>
      <div className="user_detail_section">
        <MDTypography variant="p" className="p-title">
          <svg onClick={() => handleBackButtonClick()} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} width="34" height="34" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
          {details?.departure_city}-{getDestinationCity(details)}
          {details?.children?.length > 0 ?
            <>
              {" "}to{" "}
              {getFirstReturnFlightDepartureCity(details)}-{getLastReturnFlightDestinationCity(details)}
            </>
            : ""}

        </MDTypography>
      </div>
      <div className="container-fluid mb-4 mainpricemonitor">
        <div className="row align-items-center">
          <div className='col-md-3'>
            <p className="Current-Plane">Your Current Plane Ticket   </p>
            <MDTypography variant="p"
              color="white"
              className="total_price ms-2 mb-1">Booking Confirmation: <strong>{details?.confirmation_code}</strong></MDTypography>
          </div>
          <div className="col-md-8 dotted-gradient-border">

          </div>
          {details ? (
            // details?.map((flight, index) => (
            <div className="airline_header row justify-content-start align-items-center ps-4 ">
              <div className="col-md-8 col-lg-10 flight_detail_bg">
                <>
                  {
                    details?.children?.length > 0 ?
                      <>
                        <div className="outbound_section">
                          <span className="outbound_booking">Outbound flight</span>
                          <FlightDetails item={details} />
                          {
                            details?.children?.map((child, i) => {
                              if (child?.is_return_flight == false) {
                                return (
                                  <FlightDetails item={child} />
                                )
                              }
                            })
                          }
                        </div>

                        {
                          details?.children?.sort((a, b) => a.id - b.id)?.map((child, i) => {
                            const firstReturnFlightIndex = details?.children?.findIndex(c => c?.is_return_flight === true);
                            if (child?.is_return_flight == true) {
                              return (
                                <>
                                  {
                                    i == firstReturnFlightIndex &&
                                    <div className="outbound_section">
                                      <div className="children_data"></div>
                                      <span className="outbound_booking">Return flight</span>
                                    </div>
                                  }
                                  <FlightDetails children={"children"} item={child} />
                                </>
                              )
                            }
                          })
                        }
                      </>
                      :
                      <FlightDetails oneWay={"oneWay"} item={details} />
                  }
                </>
              </div>
              <div className="col-md-4 col-lg-2 main_price">
                <div className="col-12 px-1">
                  <div className="price_section_paid ">
                    <span><span className="total_price">{details?.price ? "Price paid" : ""}</span></span>
                    <MDTypography
                      variant="h6"
                      color="white"
                      className="flight_details main_flight_price"
                    >
                      {`$${details?.price?.replace("$", "")?.replace("USD", "")}`}
                    </MDTypography>
                  </div>
                </div>
                {/* <MDTypography
                  variant="h4"
                  color="white"
                  className="flight_details"
                >
                  {details?.children?.length > 0 ?
                    `$${totalPrice(details?.children?.[0]?.price?.replace("$", "")?.replace("USD", ""), details?.price?.replace("$", "")?.replace("USD", ""))}`
                    : `$${details?.price?.replace("$", "")?.replace("USD", "")}`}
                </MDTypography>
                <div className="d-flex justify-content-end mt-2 align-items-center">
                  <span className="total_price">{details?.same_flight_sub?.total_price ? "Now: " : ""}</span>
                  <MDTypography
                    variant="h6"
                    color="white"
                    className="flight_details"
                  >
                    {""} {details?.same_flight_sub?.total_price}
                  </MDTypography>
                </div> */}
              </div>
            </div>
            // ))
          ) : (
            <p>No flight available</p>
          )}
        </div>
      </div>
    </MDBox>
  );
};

export default UserFlightBooking;
