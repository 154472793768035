import React from "react";

const PriceDetails = ({ ticket, current, retprice }) => {

    return (
        <>
            <div className={` col-7 ${!current ? "col-md-3" : "col-md-2"} col-lg-2 mb-1 tooltip-container`}>
                <div className="d-flex align-items-center ">
                <p className="ticket_price_flight d-flex">{ticket?.price ? `$${ticket?.price}` : `$${ticket?.total_price?.replace("$", "")}`}
                </p>
                {!current && <p
                    className="ticktptag21"
                    style={{ fontSize: "13px", marginBottom: "0px !important" }}
                >
                    <>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-info-circle mx-2"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                        <div className="tooltip-box">
                            <ul>
                                {
                                    ticket?.total_price ?
                                        <>
                                            {ticket?.outbound_offer?.percentage_changes?.split(",")?.map((value, index) =>
                                                value.trim() !== "" ? <li key={index}>{value?.replace(/['\[\]:']/g, "")}</li> : null
                                            )}
                                            {ticket?.return_offer?.percentage_changes?.split(",")?.map((value, index) =>
                                                value.trim() !== "" ? <li key={index}>{value?.replace(/['\[\]:']/g, "")}</li> : null
                                            )}

                                        </>
                                        :
                                        <>
                                            {ticket?.percentage_changes?.split(",")?.map((value, index) =>
                                                value.trim() !== "" ? <li key={index}>{value?.replace(/['\[\]:']/g, "")}</li> : null
                                            )}
                                        </>
                                }

                                {
                                    retprice &&
                                    <>
                                        {ticket?.percentage_changes?.split(",")?.map((value, index) =>
                                            value.trim() !== "" ? <li key={index}>{value?.replace(/['\[\]:']/g, "")}</li> : null
                                        )}
                                    </>
                                }
                            </ul>
                        </div>
                    </>
                </p>}
                </div>
                {
                    ticket?.saved_amount &&
                    <p
                        className="ticktptag21"
                        style={{ fontSize: "13px", marginBottom: "0px !important" }}
                    >
                        You can save&nbsp;
                        <span
                            style={{
                                color: "#61a108",
                                fontWeight: "bold",
                                fontSize: "13px",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {ticket?.saved_amount}
                        </span>
                    </p>
                }

            </div>
        </>
    )
}

export default PriceDetails;