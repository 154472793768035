import React, { useEffect, useState } from "react";
import airlinrlogo from "assets/img/airlinrlogo.png";
import plane from "assets/img/plane.svg";
import moment from "moment";
import airports from "utils/airport.json"
import { layoversFlight } from "utils/api";
import airlineName from "utils/airlinename.json"

const LayoverDetails = ({ id, handleSubstituteDetail, getTime, ticket }) => {
    const [layovers, setLayovers] = useState([]);
    const [layoverData, setLayoverData] = useState({
        cabin: "",
        aircode: ""
    })


    const getAirlineName = (airlineCode) => {
        const airline = airlineName?.find(item => item?.id === airlineCode);
        return airline ? airline?.name : airlineCode;
    };

    const fetchTimeZone = (city) => {
        const airport = airports.find(airport => airport.code === city);

        return airport ? airport.tz : 'America\/New_York';
    };

    const getTimeDiff = (departure, arrive) => {
        const departureDateTime = new Date(departure);
        const arrivalDateTime = new Date(arrive);

        const diffInMs = arrivalDateTime - departureDateTime;
        const hours = Math.floor(diffInMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}hr ${minutes}min`
    }

    useEffect(() => {
        const fetchLayovers = async () => {
            try {
                const res = await layoversFlight(id);
                if (res?.status === 200) {
                    const offer_data = res?.data?.[0]?.offer_data?.itineraries || [];
                    setLayovers(offer_data);
                    setLayoverData({
                        cabin: res?.data?.[0]?.offer_data?.travelerPricings?.[0]?.fareDetailsBySegment?.[0]?.cabin,
                        aircode: res?.data?.[0]?.offer_data?.validatingAirlineCodes
                    })
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchLayovers();
    }, [id]);

    const formatDuration = (duration) => {
        const hoursMatch = duration?.match(/(\d+)H/);
        const minutesMatch = duration?.match(/(\d+)M/);
        const hours = hoursMatch ? `${hoursMatch[1]}h` : "";
        const minutes = minutesMatch ? `${minutesMatch[1]}m` : "";
        return `${hours} ${minutes}`?.trim();
    };

    const getAirlineLogo = (airlineCode) => {
        const airline = airlineName?.find(item => item?.name === airlineCode || item?.id === airlineCode);
        return airline ? airline?.logo : airlinrlogo;
    }

    return (
        <>
            <div className="row pt-4 pb-2 position-relative">
                <span className="outbound_booking_sub">Outbound flight</span>

                <div className={`col-md-12 col-lg-7 mb-0`}>
                    <div className="row align-item-center">
                        <div className="col-4">
                            <div >
                                <p className="time_difference mb-0">
                                    {layovers?.[0]?.segments?.[0]?.departure?.at ? moment(layovers?.[0]?.segments?.[0]?.departure.at).format('LT') : ""}
                                </p>
                                <p className="depature_code">
                                    {layovers?.[0]?.segments?.[0]?.departure?.iataCode}
                                </p>
                                <p className="time_difference mt-0 mb-0">
                                    {layovers?.[0]?.segments?.[0]?.departure?.at ? moment(layovers?.[0]?.segments?.[0]?.departure.at).format('L') : ""}
                                </p>
                            </div>

                        </div>
                        <div className="col-4 d-flex">
                            <div style={{ lineHeight: "normal" }}>
                                <p className="stops_count">STOPS: <span><span className="ticket_number">{layovers?.[0]?.segments?.length - 1}</span></span>
                                    <span className="p-1 ps-0" onClick={() => handleSubstituteDetail(id, layovers?.[0]?.segments?.length - 1, formatDuration(layovers?.[0]?.duration), layovers?.[0], layoverData)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill view_stops_count" viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg>
                                    </span>
                                </p>
                                <img
                                    src={plane}
                                    alt="Custom Plane"
                                    width="68px"
                                />
                                <p className="text-center time_difference mb-0 mt-0">
                                    {
                                        layovers?.[0]?.segments?.length > 1 ?
                                            <>
                                                {layovers?.[0]?.duration ? formatDuration(layovers?.[0]?.duration) :
                                                    getTimeDiff(
                                                        moment.tz(`${layovers?.[0]?.segments?.[0]?.departure.at}`, 'YYYY-MM-DD hh:mm A', fetchTimeZone(layovers?.[0]?.segments?.[0]?.departure?.iataCode)).format(),
                                                        moment.tz(`${layovers?.[0]?.segments?.[layovers?.segments?.length - 1]?.arrival.at}`, 'YYYY-MM-DD hh:mm A', fetchTimeZone(layovers?.[0]?.segments?.[layovers?.[0]?.segments?.length - 1]?.arrival?.iataCode)).format()
                                                    )}
                                            </>
                                            :
                                            <>
                                                {layovers?.[0]?.duration ? formatDuration(layovers?.[0]?.duration) :

                                                    getTimeDiff(
                                                        moment.tz(`${layovers?.[0]?.segments?.[0]?.departure.at}`, 'YYYY-MM-DD hh:mm A', fetchTimeZone(layovers?.[0]?.segments?.[0]?.departure?.iataCode)).format(),
                                                        moment.tz(`${layovers?.[0]?.segments?.[0]?.arrival.at}`, 'YYYY-MM-DD hh:mm A', fetchTimeZone(layovers?.[0]?.segments?.[0]?.arrival?.iataCode)).format()
                                                    )}
                                            </>
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="col-4 text-end">
                            <ul className="ul-list">
                                {
                                    layovers?.[0]?.segments?.length > 1 ?
                                        <>
                                            <p className="time_difference mb-0">
                                                {layovers?.[0]?.segments?.[layovers?.[0]?.segments?.length - 1]?.arrival?.at ? moment(layovers?.[0]?.segments?.[layovers?.[0]?.segments?.length - 1]?.arrival.at).format('LT') : ""}
                                            </p>

                                            <p className="depature_code">
                                                {layovers?.[0]?.segments?.[layovers?.[0]?.segments?.length - 1]?.arrival?.iataCode}
                                            </p>

                                            <p className="time_difference mb-0 mt-1">
                                                {layovers?.[0]?.segments?.[layovers?.[0]?.segments?.length - 1]?.arrival?.at ? moment(layovers?.[0]?.segments?.[layovers?.[0]?.segments?.length - 1]?.arrival.at).format('L') : ""}
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p className="time_difference mb-0">
                                                {layovers?.[0]?.segments?.[0]?.arrival?.at ? moment(layovers?.[0]?.segments?.[0]?.arrival.at).format('LT') : ""}
                                            </p>

                                            <p className="depature_code">
                                                {layovers?.[0]?.segments?.[0]?.arrival?.iataCode}
                                            </p>

                                            <p className="time_difference mb-0 mt-1">
                                                {layovers?.[0]?.segments?.[0]?.arrival?.at ? moment(layovers?.[0]?.segments?.[0]?.arrival.at).format('L') : ""}
                                            </p>
                                        </>
                                }

                            </ul>
                        </div>
                    </div>
                </div>

                <div
                    className={`col-md-12 col-lg-5`}
                >
                    <div
                        className="d-flex"
                        style={{ alignItems: "center" }}
                    >
                        <img
                            src={getAirlineLogo(layovers?.[0]?.segments?.[0]?.carrierCode)}
                            alt=""
                            className="sub_airline_logo"
                        />
                        <div className="ticktnam mx-2">
                            <p className="ticktptag12">
                                {getAirlineName(layovers?.[0]?.segments?.[0]?.carrierCode)}
                            </p>
                            <p className="flight_class_name">
                                {ticket?.travel_class} <strong>{`${layovers?.[0]?.segments?.[0]?.carrierCode}${layovers?.[0]?.segments?.[0]?.number}`}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {
                layovers?.length > 1 &&
                <>
                    <div className="row py-2 position-relative">
                        <div className="children_data_sub"></div>
                        <span className="return_booking_sub">Return flight</span>
                        <div className={`col-md-12 col-lg-7 mb-0`}>
                            <div className="row align-item-center">
                                <div className="col-4">
                                    <div >
                                        <p className="time_difference mb-0">
                                            {layovers?.[1]?.segments?.[0]?.departure?.at ? moment(layovers?.[1]?.segments?.[0]?.departure.at).format('LT') : ""}
                                        </p>
                                        <p className="depature_code">
                                            {layovers?.[1]?.segments?.[0]?.departure?.iataCode}
                                        </p>
                                        <p className="time_difference mt-0 mb-0">
                                            {layovers?.[1]?.segments?.[0]?.departure?.at ? moment(layovers?.[1].segments?.[0]?.departure.at).format('L') : ""}
                                        </p>
                                    </div>

                                </div>
                                <div className="col-4 d-flex">
                                    <div style={{ lineHeight: "normal" }}>
                                        <p className="stops_count">STOPS: <span><span className="ticket_number">{layovers?.[1]?.segments?.length - 1}</span></span>
                                            <span className="p-1 ps-0" onClick={() => handleSubstituteDetail(layovers?.[1]?.segments?.[0]?.id, layovers?.[1]?.segments?.length - 1, formatDuration(layovers?.[1]?.duration), layovers?.[1], layoverData)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill view_stops_count" viewBox="0 0 16 16">
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                                </svg>
                                            </span>
                                        </p>
                                        <img
                                            src={plane}
                                            alt="Custom Plane"
                                            width="68px"
                                        />
                                        <p className="text-center time_difference mb-0 mt-0">
                                            {layovers?.[1]?.duration ? formatDuration(layovers?.[1]?.duration) :
                                                getTime(moment(layovers?.[1]?.segments?.[0]?.departure.at).format(),
                                                    moment(layovers?.[1]?.segments?.[layovers?.segments?.length - 1]?.arrival.at).format()
                                                )
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <ul className="ul-list">
                                        <p className="time_difference mb-0">
                                            {
                                                layovers?.[1]?.segments?.length > 1 ?
                                                    moment(layovers?.[1]?.segments?.[layovers?.[1]?.segments?.length - 1]?.arrival.at).format('LT')
                                                    :
                                                    moment(layovers?.[1]?.segments?.[0]?.arrival?.at).format('LT')
                                            }
                                        </p>

                                        <p className="depature_code">
                                            {
                                                layovers?.[1]?.segments?.length > 1 ?
                                                    layovers?.[1]?.segments?.[layovers?.[1]?.segments?.length - 1]?.arrival?.iataCode
                                                    :
                                                    layovers?.[1]?.segments?.[0]?.arrival?.iataCode
                                            }
                                        </p>

                                        <p className="time_difference mb-0 mt-1">
                                            {
                                                layovers?.[1]?.segments?.length > 1 ?
                                                    moment(layovers?.[1]?.segments?.[layovers?.[1]?.segments?.length - 1]?.arrival.at).format('L')
                                                    :
                                                    moment(layovers?.[1]?.segments?.[0]?.arrival.at).format('L')
                                            }
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`col-md-12 col-lg-5`}
                        >
                            <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                            >
                                <img
                                    src={getAirlineLogo(layovers?.[1]?.segments?.[0]?.carrierCode)}
                                    alt=""
                                    className="sub_airline_logo"
                                />
                                <div className="ticktnam mx-2">
                                    <p className="ticktptag12">
                                        {getAirlineName(layovers?.[1]?.segments?.[0]?.carrierCode)}
                                        {/* {getFlightName(ticket?.airline_website, ticket?.airline_code)} */}
                                    </p>
                                    <p className="flight_class_name">
                                        {ticket?.travel_class} <strong>{`${layovers?.[1]?.segments?.[0]?.carrierCode}${layovers?.[1]?.segments?.[0]?.number}`}</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

const FlightSubstitute = ({ ticket, returnSub, item, itemLength, index, handleSubstituteDetail, ticketStatuses }) => {

    const getAirlineLogo = (airlineCode) => {
        const airline = airlineName?.find(item => item?.name === airlineCode || item?.id === airlineCode);
        return airline ? airline?.logo : airlinrlogo;
    }

    const getTime = (departure, arrival) => {

        const departureDate = new Date(departure);
        const arrivalDate = new Date(arrival);

        const differenceInMilliseconds = arrivalDate - departureDate;
        const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}hr ${minutes}min`;
    };

    const getFlightName = (url, code) => {
        try {
            const domain = new URL(url).hostname;
            const airlineName = domain.replace(/^www\./, "").split(".")[0];
            return airlineName.charAt(0).toUpperCase() + airlineName.slice(1);
        } catch (error) {
            return code;
        }
    };

    function convertToAmPm(time) {
        let [hours, minutes, seconds] = time.split(":").map(Number);
        let amPm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        return `${hours}:${minutes.toString().padStart(2, "0")} ${amPm}`;
    }

    const getLayoverSub = async (id) => {
        try {
            const res = await layoversFlight(id);
            if (res?.status === 200) {
                const offer_data = res?.data?.[0]?.offer_data?.itineraries?.[0]
                return (
                    <div>
                        <p className="time_difference mb-0">
                            {
                                offer_data?.segments?.[0]?.departure?.at ? moment(offer_data?.segments?.[0]?.departure?.at).format('LT') : ""
                            }
                        </p>

                        <p className="depature_code">
                            {offer_data?.segments?.[0]?.departure?.iataCode}
                        </p>

                        <p className="time_difference mt-0 mb-0">
                            {
                                offer_data?.segments?.[0]?.departure?.at ? moment(offer_data?.segments?.[0]?.departure?.at).format('L') : ""
                            }
                        </p>
                    </div>
                )
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTimeZone = (city) => {
        const airport = airports.find(airport => airport.code === city);

        return airport ? airport.tz : 'America\/New_York';
    };

    const getTimeDiff = (departure, arrive) => {
        const departureDateTime = new Date(departure);
        const arrivalDateTime = new Date(arrive);

        const diffInMs = arrivalDateTime - departureDateTime;
        const hours = Math.floor(diffInMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}hr ${minutes}min`
    }

    const getAirlineName = (airlineCode) => {
        const airline = airlineName?.find(item => item?.id === airlineCode);
        return airline ? airline?.name : airlineCode;
    };


    return (

        <>
            <div className="row py-2">
                {
                    returnSub ?
                        <>
                            <div className={`col-md-12 col-lg-7 mb-0`}>
                                <div className="row align-item-center">
                                    <div className="col-4">
                                        <div>
                                            <p className="time_difference mb-0">
                                                {
                                                    ticket?.departure_date ? convertToAmPm(ticket?.departure_time) : moment(ticket?.departure_time).format('LT')
                                                }
                                            </p>

                                            <p className="depature_code">
                                                {ticket?.departure_iata_code}
                                            </p>

                                            <p className="time_difference mt-0 mb-0">
                                                {moment(ticket?.departure_date ? ticket?.departure_date : ticket?.departure_time).format('L')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-4 d-flex">
                                        <div style={{ lineHeight: "normal" }}>
                                            <img
                                                src={plane}
                                                alt="Custom Plane"
                                                width="68px"
                                            />
                                            <p className="text-center time_difference mb-0 mt-0">
                                                {
                                                    getTimeDiff(
                                                        moment.tz(`${ticket?.departure_date}`, 'YYYY-MM-DD hh:mm A', fetchTimeZone(ticket?.departure_iata_code)).format(),
                                                        moment.tz(`${ticket?.arrival_date}`, 'YYYY-MM-DD hh:mm A', fetchTimeZone(ticket?.arrival_iata_code)).format()
                                                    )
                                                }

                                                {/* {getTime(moment(ticket?.departure_date ? ticket?.departure_date : ticket?.departure_time).format(),
                                                    moment(ticket?.arrival_date ? ticket?.arrival_date : ticket?.arrival_time).format()
                                                )} */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <ul className="ul-list">
                                            <p className="time_difference mb-0">
                                                {
                                                    ticket?.arrival_date ? convertToAmPm(ticket?.arrival_time) : moment(ticket?.arrival_time).format('LT')
                                                }
                                            </p>

                                            <p className="depature_code">
                                                {ticket?.arrival_iata_code}
                                            </p>

                                            <p className="time_difference mb-0 mt-1">

                                                {itemLength == index ?
                                                    moment(ticket?.departure_date ? ticket?.departure_date : ticket?.departure_time).format('L')
                                                    :
                                                    moment(ticket?.arrival_date ? ticket?.arrival_date : ticket?.arrival_time).format('L')
                                                }

                                                {/* {moment(ticket?.arrival_date ? ticket?.arrival_date : ticket?.arrival_time).format('L')} */}

                                            </p>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`col-md-12 col-lg-5`}
                            >
                                <div
                                    className="d-flex"
                                    style={{ alignItems: "center" }}
                                >
                                    <img
                                        src={getAirlineLogo(ticket?.airline_code)}
                                        alt=""
                                        className="sub_airline_logo"
                                    />
                                    <div className="ticktnam mx-2">
                                        <p className="ticktptag12">
                                            {getAirlineName(ticket?.airline_code)}
                                            {/* {getFlightName(ticket?.airline_website, ticket?.airline_code)} */}
                                        </p>
                                        <p className="flight_class_name">
                                            {ticket?.travel_class} <strong>{`${ticket?.airline_code}${ticket?.flight_number}`}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>

                        :
                        <LayoverDetails ticket={ticket} getFlightName={getFlightName} getTime={getTime} id={ticket?.id} handleSubstituteDetail={handleSubstituteDetail} />
                }



            </div>

        </>
    )
}

export default FlightSubstitute;