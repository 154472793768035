import { Button, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Navbar from "layouts/Front-Page/Navbar";
import NewsletterFooter from "layouts/Front-Page/NewsletterFooter";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!elements) return;

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    const res = await fetch("/create-intent", {
      method: "POST",
    });
    const { client_secret: clientSecret } = await res.json();

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (error) setErrorMessage(error.message);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="pay_submit_section">
        <Button type="submit" disabled={!stripe || !elements}>
          Pay
        </Button>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const Pricing = () => {
  const location = useLocation();
  const value = location?.state?.replace("$","");


  const options = {
    mode: "payment",
    amount: 1000,
    currency: "usd",
    appearance: {
      /* Customize appearance here */
    },
  };

  return (
    <BasicLayout>
      <div className="pt-1">
        <Navbar />
      </div>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={5} lg={5}>
          <MDBox pt={6}>
            {/* <div className="my-2 text-center">
              <MDTypography variant="h5">Pricing Plans</MDTypography>
            </div> */}
            <Card>
              <Elements stripe={stripePromise} options={options}>
                <MDBox p={3}>
                  <CheckoutForm />
                </MDBox>
              </Elements>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={10}>
          <NewsletterFooter />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Pricing;
