
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Profile from "layouts/profile";
import Icon from "@mui/material/Icon";
import PaymentDetails from "layouts/PaymentDetail";
import Bookings from "layouts/Booking";
import AdminAuth from "authroutes/Admin";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small" className="sidebar_icons">dashboard</Icon>,
    route: "/dashboard",
    component: <AdminAuth><Dashboard /></AdminAuth>,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "tables",
    icon: <Icon fontSize="small" className="sidebar_icons">table_view</Icon>,
    route: "/user-management",
    component: <AdminAuth><Tables /></AdminAuth>,
  },
  {
    type: "collapse",
    name: "Bookings",
    key: "bookings",
    icon: <Icon fontSize="small" className="sidebar_icons">Booking</Icon>,
    route: "/booking",
    component: <AdminAuth><Bookings /></AdminAuth>,
  },
  {
    type: "collapse",
    name: "Payment Details",
    key: "payment",
    icon: <Icon fontSize="small" className="sidebar_icons">payment</Icon>,
    route: "/payment",
    component:<AdminAuth> <PaymentDetails /></AdminAuth>,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small" className="sidebar_icons">person</Icon>,
    route: "/profile",
    component: <AdminAuth><Profile /></AdminAuth>,
  },
];

export default routes;
