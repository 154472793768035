import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserByID } from "utils/userApi";
import AvatorImg from "assets/images/defaultAvatar.jpg";
import MDInput from "components/MDInput";
import Loader from "components/Loader";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { encodeIdWithType } from "utils/encoder";
import { getPermissionByID } from "utils/api";
import { deleteUser } from "utils/userApi";
import { toast } from "react-toastify";
import FlightDetails from "components/FlightDetails";
import Hoteldetail from "components/HotelDetail";
import Cardetail from "components/CarDetails";
import { Form } from "react-bootstrap";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Flight Bookings", "Hotel Bookings", "Car Bookings"];

const UserDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [personName, setPersonName] = useState("Flight Bookings");
  const [pageData, setPageData] = useState([]);
  const [deletPermision, setDeletPermision] = useState(false);
  const [updatePermision, setUpdatePermision] = useState(false);
  const [userType, setUserType] = useState(null);
  const [show, setShow] = useState(false);

  const params = useLocation()

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(pageData?.length / itemsPerPage);

  const displayedItems = pageData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  console.log("displayedItemsdisplayedItems", pageData)

  const handleDeleteUser = async (id) => {
    setShow(id);
  };

  const handleClose = () => setShow(false);

  const handleDeleteAdmin = async () => {
    try {
      setLoading(true);
      await deleteUser(show);
      toast.success("User successfully deleted", {
        theme: "colored"
      })
      setShow(false);
      setLoading(false);
      navigate("/user-management")
    } catch (error) {
      toast.error("Failed to deleted", {
        theme: "colored"
      })
      setShow(false);
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setLoading(true);
    getUserByID(id)
      .then((res) => {
        setLoading(false);
        setUser(res.data);
        const filteredData = res.data?.children?.flight_data?.filter(
          (flight) => flight?.parent_booking === null && flight?.is_show_data
        )?.reverse();
        setPageData(filteredData);
        // setPageData(res.data?.children?.flight_data?.filter(item => item.is_show_data === true));
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [id]);

  const handleBackButtonClick = () => {
    navigate("/user-management", { state: params?.state });
  };

  const handleChange = (event) => {
    setPage(1);
    const {
      target: { value },
    } = event;
    setPersonName(value);
    if (value === "Car Bookings") {
      setPageData(user?.children?.car_data);
    } else if (value === "Hotel Bookings") {
      setPageData(user?.children?.hotel_data?.filter(item => item.is_show_data === true));
    } else {
      const filteredData = user?.children?.flight_data?.filter(
        (flight) => flight?.parent_booking === null && flight?.is_show_data
      )?.reverse();
      setPageData(filteredData);
    }
  };

  const redirection = (id, type) => {
    let final_id = encodeIdWithType(id, type);
    navigate(`/flight-details/${final_id}`, { state: id });
  };

  const updatebooking = (id) => {
    // let final_id = encodeIdWithType(390, "flights");
    // navigate(`/price-monitor/${final_id}`);
    let final_id = encodeIdWithType(id);
    navigate(`/update-booking/${id}`);
  }


  useEffect(() => {
    getUserPermission();
  }, []);

  const getUserPermission = () => {
    const user = JSON.parse(localStorage.getItem("active-user"));
    const id = user?.id
    if (user) {
      setUserType(user.user_type);
      getPermissionByID()
        .then((res) => {
          const userData = res?.data?.user_permissions
          const filterdata = userData?.filter((item) => {
            return item?.user_id === id
          })
          const data = filterdata[0]?.permissions;
          const updateUser = data?.filter((item) => {
            return item.codename === "change_user";
          });
          setUpdatePermision(updateUser?.[0]);
          const deleteUser = data?.filter((item) => {
            return item.codename === "delete_user";
          });
          setDeletPermision(deleteUser?.[0]);
        })
        .catch((error) => {
          console.error("Error fetching permission:", error);
        });
    }
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      // Check if dateTimeStr is null, undefined, or not a string
      if (!dateTimeStr || typeof dateTimeStr !== 'string') {
        console.warn('Invalid date time string:', dateTimeStr);
        return null;
      }

      // Split the date time string, with error handling
      const parts = dateTimeStr.split(" ");
      if (parts.length < 3) {
        console.warn('Unexpected date time format:', dateTimeStr);
        return null;
      }

      const [date, time, modifier] = parts;

      // Additional checks for time and modifier
      if (!time || !modifier) {
        console.warn('Missing time or modifier:', dateTimeStr);
        return null;
      }

      let [hours, minutes] = time.split(":");
      if (!hours || !minutes) {
        console.warn('Invalid time format:', time);
        return null;
      }

      // Convert hours based on modifier
      hours = parseInt(hours, 10);
      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      try {
        return new Date(`${date}T${hours.toString().padStart(2, '0')}:${minutes}:00`);
      } catch (error) {
        console.warn('Error creating date:', error);
        return null;
      }
    };

    // Format departure and arrival dates
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);

    // If either date is invalid, return an empty string
    if (!departureDate || !arrivalDate) {
      return '';
    }


    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  const totalPrice = (price1, price2) => {
    return Number(price1) + Number(price2)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox my={2}>
          <div className="user_name_icons">
            <div className="user_detail_section">
              <svg onClick={() => handleBackButtonClick()} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} width="34" height="34" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
              </svg>
              <MDTypography
                varient="h1"
                className="user_fullname"
              >{`${user?.first_name} ${user?.last_name}`}</MDTypography>
            </div>
            <div className="user_action_icons">
              {(
                updatePermision ||
                userType === 1) && (
                  <span onClick={() => {
                    navigate(`/edit-user/${id}`, { state: params?.state })
                  }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      fill="currentColor"
                      className="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fillRule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>
                  </span>
                )}

              {(
                deletPermision ||
                userType === 1) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    className="bi bi-trash3-fill"
                    viewBox="0 0 16 16"
                    onClick={() => {
                      handleDeleteUser(user?.id);
                    }}
                  >
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                  </svg>
                )}
            </div>
          </div>

          <Card className="mt-4">
            <Grid container className="user_profile_container">
              <Grid item xs={12} md={4} lg={3} className="user_detail_image">
                <div className="user_image_name">
                  <img
                    src={
                      user?.image
                        ? `${process.env.REACT_APP_API_URL}${user?.image}`
                        : AvatorImg
                    }
                    className="user_newimage"
                  />
                  <MDTypography varient="h1" className="user_detail_fullname">
                    {`${user?.first_name} ${user?.last_name}`}
                  </MDTypography>
                </div>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                {/* <MDTypography varient="p" className="general_info_text">
                  General Info
                </MDTypography> */}
                <div className="user_details_section">
                  <Grid container className="user_info_grid">
                    <Grid item xs={12} md={6} lg={4}>
                      <div className="my-1">
                        <MDTypography
                          varient="p"
                          color="white"
                          className="info_label"
                        >
                          Full Name
                        </MDTypography>
                        <MDInput
                          value={`${user?.first_name} ${user?.last_name}`}
                          className="user_details_input"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <div className="my-1">
                        <MDTypography
                          varient="p"
                          color="white"
                          className="info_label"
                        >
                          Email
                        </MDTypography>
                        <MDInput
                          value={user?.email}
                          className="user_details_input"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <div className="my-1">
                        <MDTypography
                          varient="p"
                          color="white"
                          className="info_label"
                        >
                          Phone Number
                        </MDTypography>
                        <MDInput
                          value={user?.phone_number}
                          className="user_details_input"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Card>

          <Card className="mt-4">
            <MDBox py={2} px={2}>
              <div className="filters_sections">
                <Form.Group className="status-select-container mx-1" controlId="category-select">
                  <Form.Select
                    value={personName}
                    onChange={handleChange}
                    className="custom-status-select"
                  >
                    {/* <option value="">Select Category</option> Placeholder */}
                    {names?.map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>


              {personName === "Flight Bookings" && (
                <div className="mt-4">
                  {displayedItems?.length > 0
                    ? displayedItems?.map((flight) => {
                      if (flight?.is_show_data === true) {
                        return (
                          <div className="user_flight_substitutes_bg py-3 ps-3">
                            <MDTypography variant="p"
                              color="white"
                              className="total_price ms-2 mb-1">Booking Confirmation: <strong>{flight?.confirmation_code}</strong></MDTypography>
                            <div onClick={() => redirection(flight?.id, "flights")} className="airline_header row justify-content-start align-items-center p-0">
                              <div className="col-md-8 col-lg-9 flight_detail_bg">
                                {
                                  flight?.children?.length > 0 ?
                                    <>
                                      <div className="outbound_section">
                                        <span className="outbound_booking">Outbound flight</span>
                                        <i onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          updatebooking(flight?.id)
                                        }} className="bi bi-pencil-square main_booking_edit_icon"></i>
                                        <FlightDetails item={flight} />
                                        {
                                          flight?.children?.map((child, i) => {
                                            if (child?.is_return_flight === false) {
                                              return (
                                                <FlightDetails item={child} />
                                              )
                                            }
                                          })
                                        }
                                      </div>

                                      {
                                        flight?.children?.sort((a, b) => a.id - b.id)?.map((child, i) => {
                                          const firstReturnFlightIndex = flight?.children?.findIndex(c => c?.is_return_flight === true);
                                          if (child?.is_return_flight === true) {
                                            return (
                                              <>
                                                {
                                                  i === firstReturnFlightIndex && (
                                                    <div className="outbound_section">
                                                      <div className="children_data"></div>
                                                      <span className="outbound_booking">Return flight</span>
                                                    </div>)
                                                }
                                                <FlightDetails children={"children"} item={child} />
                                              </>
                                            )
                                          }
                                        })
                                      }
                                    </>
                                    :

                                    <div className="outbound_section">
                                      <span className="outbound_booking">Outbound flight</span>
                                      <i onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        updatebooking(flight?.id)
                                      }} className="bi bi-pencil-square main_booking_edit_icon"></i>
                                      <FlightDetails oneWay={"oneWay"} item={flight} />
                                    </div>

                                }

                              </div>
                              <div className="col-md-4 col-lg-3 main_price">
                                <div className="row align-items-center">
                                  <div className="col-10">
                                    <div className="price_section_paid ">
                                      <span><span className="total_price">{flight?.price ? "Price paid" : ""}</span></span>
                                      <MDTypography
                                        variant="h6"
                                        color="white"
                                        className="flight_details main_flight_price"
                                      >
                                        {`$${flight?.price?.replace("$", "")?.replace("USD", "")}`}
                                      </MDTypography>
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-right-circle-fill next_right_icon" viewBox="0 0 16 16">
                                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                    </svg>
                                  </div>

                                </div>

                                {/* <MDTypography
                                variant="h4"
                                color="white"
                                className="flight_details"
                              >
                                {flight?.children?.length > 0 ?
                                  `$${totalPrice(flight?.children?.[0]?.price?.replace("$", "")?.replace("USD", ""), flight?.price?.replace("$", "")?.replace("USD", ""))}`
                                  : `$${flight?.price?.replace("$", "")?.replace("USD", "")}`}
                              </MDTypography>
                              <div className="d-flex justify-content-end mt-2 align-items-center">
                                <span className="total_price">{flight?.same_flight_sub?.total_price ? "Now: " : ""}</span>
                                <MDTypography
                                  variant="h6"
                                  color="white"
                                  className="flight_details"
                                >
                                  {""} {flight?.same_flight_sub?.total_price}
                                </MDTypography>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                    :
                    <div className="text-center">
                      <MDTypography variant="h5">You don't have any Bookings at the moment! </MDTypography>
                    </div>}
                </div>
              )}

              {personName === "Hotel Bookings" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems?.map((hotel, index) => {
                      return (
                        <div className="mt-4">
                          <div className="hotel_detail_bg"
                            onClick={() => redirection(hotel?.id, "hotels")}
                          >
                            <Hoteldetail item={hotel} />
                          </div>
                        </div>
                      );
                    })
                    :
                    <div className="text-center">
                      <MDTypography variant="h5">You don't have any Bookings at the moment! </MDTypography>
                    </div>
                  }
                </>
              )}

              {personName === "Car Bookings" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems?.map((car) => {
                      return (
                        <Card className="mt-4">
                          <div className="car_main_header  p-3"
                            onClick={() => redirection(car?.id, "cars")}                          >
                            <Cardetail item={car} carBooking={"carBooking"} />
                          </div>
                        </Card>
                      );
                    })
                    :
                    <div className="text-center">
                      <MDTypography variant="h5">You don't have any Bookings at the moment! </MDTypography>
                    </div>
                  }
                </>
              )}

              {displayedItems?.length > 0 &&
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              }

            </MDBox>
          </Card>
        </MDBox>
      )}

      <Modal show={show} onHide={handleClose} centered className="layover_modal">
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              className="bi bi-x-circle cancel_icon mb-4"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
            <MDTypography variant="h5">Are you Sure?</MDTypography>
            <MDTypography variant="p" className="delete_msj">
              Do you really want to delete this User?
            </MDTypography>
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleDeleteAdmin();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </DashboardLayout>
  );
};

export default UserDetails;
