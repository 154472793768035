import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import AirlineLogo from "assets/user-dash/airlineLogo.png";
import plane from "assets/img/flight-white-image.png";
import moment from 'moment-timezone';
import airports from "utils/airport.json"
import airlineName from "utils/airlinename.json"

// const FlightMatch = ({ id }) => {
//     const [layovers, setLayovers] = useState([]);

//     useEffect(() => {
//         const fetchLayovers = async () => {
//             try {
//                 const res = await getFlightMatchssss(id);
//                 if (res?.status === 200) {
//                     const offer_data = res?.data?.[0]?.offer_data?.itineraries?.[0] || [];
//                     setLayovers(offer_data);
//                 }
//             } catch (error) {
//                 console.error(error);
//             }
//         };

//         fetchLayovers();
//     }, [id]);

// };

const FlightDetails = ({ item, getTime, children, oneWay }) => {


    const fetchTimeZone = (city) => {
        const airport = airports?.find(airport => airport?.code === city);

        return airport ? airport.tz : 'America\/New_York';
    };

    const getTimeDiff = (departure, arrive) => {
        const departureDateTime = new Date(departure);
        const arrivalDateTime = new Date(arrive);

        const diffInMs = arrivalDateTime - departureDateTime;
        const hours = Math.floor(diffInMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}hr ${minutes}min`
    }

    const calculateTimeDifference = (departureDate, departureTime, arrivesDate, arrivesTime) => {

        const departure = new Date(`${departureDate} ${departureTime}`);
        const arrival = new Date(`${arrivesDate} ${arrivesTime}`);

        const diffInMs = arrival.getTime() - departure.getTime();

        const hours = Math.floor(diffInMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}hr ${minutes}min`;
    };

    const formattedDate = (dateStr) => {
        const [year, month, day] = dateStr?.split('-');
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate
    }

    const getAirlineName = (airlineCode) => {
        const airline = airlineName?.find(item => item?.id === airlineCode);
        return airline ? airline?.name : airlineCode;
    };

    const getAirlineLogo = (airlineCode, flightNo, airCraft) => {
        const airNo = airCraft?.length === 2 ? airCraft : ""
        const airline = airlineName?.find(item => item?.name === airlineCode ||
            item?.id === airlineCode || item?.id === flightNo?.slice(0, 2)
            || item?.id === airNo);
        return airline ? airline?.logo : AirlineLogo;
    }

    return (
        <>
            {/* <FlightMatch id={item?.id} /> */}

            <div className={`row align-items-center`}>
                <div className="airline_data col-md-12 col-lg-4">
                    <img src={getAirlineLogo(item?.airline, item?.flight_number, item?.aircraft)} className="airline_logo" alt="airline logo" />
                    <div className="airline_title_section">
                        <MDTypography
                            variant="h6"
                            color="white"
                            className="airline_heading"
                        >
                            {getAirlineName(item?.airline)}
                            {/* {item?.airline} */}
                        </MDTypography>
                        {
                            oneWay &&
                            <div>
                                <MDTypography
                                    variant="p"
                                    color="white"
                                    className="ticket_title"
                                >
                                    One Way
                                </MDTypography>
                            </div>
                        }

                    </div>
                </div>

                <div className={`col-md-12 col-lg-4`}>
                    <div className="flight_detail row pe-1">
                        <div className="col-4">
                            <div>
                                <p className="flight_detail_time">
                                    {`${item?.departure_time}`}
                                </p>
                                <p className="flight_departure_city">
                                    {item?.departure_city}
                                </p>
                                <p className="flight_detail_time">
                                    {item?.departure_date ? formattedDate(item?.departure_date) : ""}
                                </p>
                            </div>
                        </div>
                        <div className="col-4">
                            <ul style={{ padding: "0", textAlign: "center" }}>
                                <img
                                    src={plane}
                                    alt="Custom Plane"
                                    width="68px"
                                    style={{ marginTop: "20px" }}
                                />
                                <p className="flight_detail_time mt-1 text-center">
                                    {item?.estimated_time ? item?.estimated_time?.replace("hours", "hr").replace("minutes", "m") : getTimeDiff(
                                        moment.tz(`${item?.departure_date} ${item?.departure_time}`, 'YYYY-MM-DD hh:mm A', fetchTimeZone(item?.departure_city)).format(),
                                        moment.tz(`${item?.return_date} ${item?.Arrives_time}`, 'YYYY-MM-DD hh:mm A', fetchTimeZone(item?.destination_city)).format()
                                    )}
                                </p>
                            </ul>
                        </div>
                        <div className="col-4 text-end d-flex justify-content-end">
                            <div className="text-right">
                                <p className="flight_detail_time">
                                    {`${item?.Arrives_time}`}
                                </p>
                                <p className="flight_departure_city">
                                    {item?.destination_city}
                                </p>
                                <p className="flight_detail_time">
                                    {item?.return_date ? formattedDate(item?.return_date) : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 col-lg-4 text-end ticket_detail_section">
                    <MDTypography
                        variant="p"
                        color="white"
                        className="flight_ticket_title"
                    >   {item?.aircraft?.length === 2 ? item?.aircraft : ""}
                        {item?.flight_number}
                        <span className="mx-2">|</span>
                        {item?.number_of_travelers ?
                            <>
                                <span>{`${item?.number_of_travelers === 1 ? `${item?.number_of_travelers} Adult` : `${item?.number_of_travelers} Adults`}`}</span>
                                <span className="mx-2">|</span>
                            </>
                            : ""
                        }
                        {item?.flight_class}
                    </MDTypography>
                </div>
            </div>

        </>
    )
}

export default FlightDetails;