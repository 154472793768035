import React from 'react'
import './Tickets.css';
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import FlightDetails from 'components/FlightDetails';
import { encodeIdWithType } from 'utils/encoder';
import { useNavigate } from 'react-router-dom';
import CurrentStatus from 'components/FlightDetails/currentStatus';
import { Modal } from 'react-bootstrap';
import { layoversFlight } from 'utils/api';
import moment from 'moment';
import { updateFlightPair } from 'utils/api';
import { toast } from 'react-toastify';
import { createFlightPair } from 'utils/api';
import { updateSubstituteStatus } from 'utils/api';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { flightOffersById } from 'utils/api';
import { currentPriceById } from 'utils/api';


const FlightBooking = ({ details, totalPrice, same_flight_sub, loading,
  setLoading, }) => {


  const navigate = useNavigate()
  const [show, setShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [siteUrl, setSiteUrl] = React.useState("")
  const [return_id, setReturnId] = React.useState(null);
  const [currentUpdate, setCurrentUpdate] = React.useState(false)
  const [acceptData, setAcceptData] = React.useState(null)
  const [selectedReason, setSelectedReason] = React.useState("");
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const [refreshLoader, setRefreshLoader] = React.useState({});
  const [refCurrentPrice, setRefCurrentPrice] = React.useState({})
  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case "ELIMINATE":
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["Downgrade", "I don’t want downgrade"],
          ["OTHER", "Other"],
        ];
      case "REJECT":
        return [
          ["WAITING", "Waiting"],
          ["LAYOVER", "Layover"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          // ["Downgrade", "I don’t want downgrade"],
          ["OTHER", "Other"],
        ];
      default:
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["WAITING", "Waiting"],
          ["POLICY_RESTRICTION", "Policy Restriction"],
          ["OTHER", "Other"],
        ];
    }
  }, [status]);

  const handleStatus = (id, status, rid, url, data, item, current) => {
    setShow(id);
    setStatus(status);
    setSiteUrl(url)
    setReturnId(rid)
    setCurrentUpdate(current)
    const storedUser = localStorage.getItem("active-user");
    const user = JSON.parse(storedUser);
    if ((status === "ACCEPT" || status === "REINSTATE") && (rid === true || rid === "true")) {
      if (current === "current") {
        setShow(true)
        const currtPrice = same_flight_sub?.total_price?.replace("$", "")
        setAcceptData({
          "id": item?.[0]?.id,
          "price": currtPrice
        })
      } else {
        layoversFlight(id).then((res) => {
          const segment = res?.data?.[0]?.offer_data?.itineraries
          const offer_data = res?.data?.[0]?.offer_data

          const childFlights = segment?.flatMap((flight, i) =>
            flight.segments
              .filter((_, index) => !(i === 0 && index === 0)) // Remove only the first segment from data[0]
              .map((segment) => ({
                message_id: user?.id,
                sender_email: user?.email,
                airline: offer_data?.validatingAirlineCodes?.[0],
                departure_city: segment?.departure?.iataCode,
                destination_city: segment?.arrival?.iataCode,
                departure_date: segment?.departure?.at?.split("T")?.[0],
                return_date: segment?.arrival?.at?.split("T")?.[0],
                price: offer_data?.price?.total,
                flight_number: `${segment?.carrierCode}${segment?.number}`,
                flight_class: offer_data?.travelerPricings?.[0]?.fareDetailsBySegment?.[0]?.cabin,
                departure_time: moment(segment?.departure?.at?.split("T")?.[1], "HH:mm:ss").format("h:mm A"),
                Arrives_time: moment(segment?.arrival?.at?.split("T")?.[1], "HH:mm:ss").format("h:mm A"),
                is_return_flight: i === 0 ? false : true, // First flight segments get false, return flight segments get true
                is_show_data: true,
                number_of_travelers: details?.[0]?.number_of_travelers,
              }))
          );

          setAcceptData({
            "id": details?.[0]?.id,
            "user": user?.id,
            "Flight_substitute": data?.id,
            "saved_amount": data?.saved_amount,
            "booking_amount": data?.price,
            "previouse_amount": details?.[0]?.price,
            "parent_flight": {
              "message_id": user?.id,
              "sender_email": user?.email,
              "airline": data?.airline_name,
              "departure_city": data?.departure_iata_code,
              "destination_city": data?.arrival_iata_code,
              "departure_date": data?.departure_time?.split(" ")?.[0],
              "return_date": data?.arrival_time?.split(" ")?.[0],
              "price": data?.price,
              "flight_number": `${data?.airline_code}${data?.flight_number}`,
              "flight_class": data?.travel_class,
              "departure_time": `${data?.departure_time?.split(" ")?.[1]} ${data?.departure_time?.split(" ")?.[2]}`,
              "Arrives_time": `${data?.arrival_time?.split(" ")?.[1]} ${data?.arrival_time?.split(" ")?.[2]}`,
              "number_of_travelers": details?.[0]?.number_of_travelers,
              "is_return_flight": false,
              "is_show_data": true
            },
            "child_flight": childFlights
          })

        }).catch((error) => {
          console.log(error)
        })
      }

    }
  };

  const handleUpdateStatus = () => {

    if (!selectedReason && status !== "ACCEPT" && status !== "REINSTATE") {
      setSelectMesage("Please select any reason");
    } else if (selectedReason === "OTHER" && !otherReasonText.trim()) {
      setSelectMesage("Please provide a reason in the text field");
    } else {
      const tickeId = return_id ? { id: show, r_id: return_id } : { id: show }
      if ((status === "ACCEPT" || status === "REINSTATE") && return_id) {
        setLoading(true)
        if (currentUpdate === "current") {

          updateFlightPair(acceptData).then((res) => {

            if (res?.status === 200 || res?.status === 201) {
              setLoading(false)
              setShow(false)
              // setPaymentShow(true)
              navigate("/dashboard")
              toast.success(
                `Booking accepted
                     successfully`,
                {
                  theme: "colored",
                }
              );
              setStatus(null)
              // fetchAllSubstitute();
            } else {
              setLoading(false)
              setShow(false)
              toast.error(`Failed to accept status`, {
                theme: "colored",
              });
            }
          }).catch((error) => {
            console.log(error)
            setLoading(false)
            setShow(false)
            toast.error(`Failed to accept status`, {
              theme: "colored",
            });
          })
        } else {
          createFlightPair(acceptData).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              setShow(false)
              setLoading(false)
              setPaymentShow(true)
              // navigate("/dashboard")
              toast.success(
                `Booking accepted successfully`,
                {
                  theme: "colored",
                }
              );
              setStatus(null)
              // fetchAllSubstitute();
            } else {
              setLoading(false)
              setShow(false)
              toast.error(`Failed to accept status`, {
                theme: "colored",
              });
            }
          }).catch((error) => {
            console.log(error)
            setLoading(false)
            setShow(false)
            toast.error(`Failed to accept status`, {
              theme: "colored",
            });
          })
        }

      } else {
        setLoading(true)
        updateSubstituteStatus(show, {
          status: status === "REINSTATE" ? "ACCEPT" : status,
          additional_information: selectedReason,
          other_reason_text: selectedReason === "OTHER" ? otherReasonText : null,
        })
          .then((res) => {
            setLoading(false)
            if (res.status === 200) {
              setShow(false)
              if (status === "ACCEPT" || status === "REINSTATE") {
                setPaymentShow(true)
              }
              toast.success(`Status ${status === "ELIMINATE"
                ? `${status?.toLowerCase()}d`
                : `${status?.toLowerCase()}ed`
                } successfully`, {
                theme: "colored",
              });


              // fetchAllSubstitute();
            } else {
              toast.error(`Failed to ${status?.toLowerCase()} status`, {
                theme: "colored",
              });
              setLoading(false)
            }
          })
          .catch((error) => {
            setLoading(false)
            console.log(error);
          });
      }

    }
  };

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== "OTHER") {
      setOtherReasonText("");
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectMesage("")
    setSelectedReason("")
    setOtherReasonText("")
  };



  const updatebooking = (id) => {
    let final_id = encodeIdWithType(id);
    navigate(`/update-booking/${id}`);
  }

  const handleBackButon = () => {
    navigate("/dashboard")
  }

  const handleRefreshSub = (id, children) => {
    setRefreshLoader((prev) => ({ ...prev, [id]: "true" }));

    const childIds = (!children || children.length === 0) ? "" : children?.map(child => child.id).join(",");
    const refId = `${id}${childIds ? `,${childIds}` : ""}`;

    currentPriceById(refId)
      .then((res) => {
        if (res.status === 200) {
          flightOffersById(id)
            .then((res) => {
              if (res.status == 200) {
                setRefCurrentPrice((prev) => ({ ...prev, [id]: res?.data?.total_price }))
              }
              setRefreshLoader((prev) => ({ ...prev, [id]: "Success" }));
              setTimeout(() => {
                setRefreshLoader((prev) => ({ ...prev, [id]: false }));
              }, 3000);
            })
            .catch((error) => {
              console.log(error);
              setRefreshLoader((prev) => ({ ...prev, [id]: false }));
            });
        } else {
          setRefreshLoader((prev) => ({ ...prev, [id]: false }));
        }
      })
      .catch((error) => {
        console.log(error);
        setRefreshLoader((prev) => ({ ...prev, [id]: false }));
      });
  };

  return (
    <MDBox py={3}>
      <div className="d-flex gap-2 mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={() => handleBackButon()} width="24" height="24" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
        </svg>
        <MDTypography variant="p" className="p-title">Price Monitor</MDTypography>
      </div>
      <div className="container-fluid mb-4 mainpricemonitor">
        <div className="row align-items-center">
          <div className='col-md-3'>
            <p className="Current-Plane">Your Current Plane Ticket   </p>
          </div>
          <div className="col-md-8 dotted-gradient-border">

          </div>
          {details?.length > 0 ? (
            details?.map((flight, index) => (
              <div className="airline_header row justify-content-start align-items-center ps-4 ">
                <div className="d-flex justify-content-between">
                  <MDTypography variant="p"
                    color="white"
                    className="total_price mb-1">Booking Confirmation: <strong>{flight?.confirmation_code}</strong></MDTypography>
                  {
                    !refreshLoader[flight.id] &&
                    <svg onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRefreshSub(flight?.id, flight?.children);
                    }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-clockwise refresh_icon" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                    </svg>
                  }

                  {
                    refreshLoader[flight.id] === "true" &&
                    <div className="refresh_loader_outer">
                      <div className="refresh_loader"></div>
                    </div>
                  }

                  {
                    refreshLoader[flight.id] === "Success" &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-check-lg refresh_icon" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                    </svg>
                  }
                </div>
                <div className="col-md-8 col-lg-9 flight_detail_bg">
                  <>
                    {
                      flight?.children?.length > 0 ?
                        <>
                          <div className="outbound_section">
                            <span className="outbound_booking">Outbound flight</span>
                            <i onClick={() => updatebooking(flight?.id)} className="bi bi-pencil-square update_icon"></i>
                            <FlightDetails item={flight} />
                            {
                              flight?.children?.map((child, i) => {
                                if (child?.is_return_flight === false) {
                                  return (
                                    <FlightDetails item={child} />
                                  )
                                }
                              })
                            }
                          </div>

                          {
                            flight?.children?.map((child, i) => {
                              const firstReturnFlightIndex = flight?.children?.findIndex(c => c?.is_return_flight === true);
                              if (child?.is_return_flight === true) {
                                return (
                                  <>
                                    {
                                      i === firstReturnFlightIndex &&
                                      <div className="outbound_section">
                                        <div className="children_data"></div>
                                        <span className="outbound_booking">Return flight</span>
                                      </div>
                                    }
                                    <FlightDetails children={"children"} item={child} />
                                  </>
                                )
                              }
                            })
                          }
                        </>
                        :
                        <div className="outbound_section">
                          <span className="outbound_booking">Outbound flight</span>
                          <i onClick={() => updatebooking(flight?.id)} className="bi bi-pencil-square main_booking_icon"></i>
                          <FlightDetails oneWay={"oneWay"} item={flight} />
                        </div>
                    }
                  </>
                </div>
                <div className="col-md-4 col-lg-3 py-2 booking_price_section">
                  <div className="row px-2">
                    <div className="col-6 px-1">
                      <div className="price_section_paid ">
                        <p className="total_price mb-0" style={{ lineHeight: "1.2", whiteSpace: "break-spaces" }}>{flight?.price ? <>
                          Price paid {" "}
                          <span>{(flight?.payment_method && flight?.payment_method == "miles") ? `(${flight?.payment_method})` : ""}</span>
                        </> : ""}
                        </p>
                        {/* <p className="total_price mb-0" style={{ lineHeight: "1.2" }}>{flight?.price ? <>
                          Price paid {" "}
                          <span>{flight?.payment_method ? `(${flight?.payment_method})` : ""}</span>
                        </> : ""}
                        </p> */}
                        <MDTypography
                          variant="h6"
                          color="white"
                          className="flight_details main_flight_price mt-2"
                        >
                          {`$${flight?.price?.replace("$", "")?.replace("USD", "")}`}
                        </MDTypography>
                      </div>
                    </div>

                    {((details?.[0]?.children?.length + 1) === same_flight_sub?.flight_offers?.length || refCurrentPrice[flight?.id]) &&
                      <div className="col-6 px-1">
                        <div className="price_section_paid">
                          <p className="total_price mb-0" style={{ lineHeight: "1.2", whiteSpace: "break-spaces" }}>{totalPrice || refCurrentPrice[flight?.id] ? "Current Price" : ""}</p>
                          <MDTypography
                            variant="h6"
                            color="white"
                            className="flight_details mt-2"
                          >
                            {""} {totalPrice || refCurrentPrice[flight?.id]}
                          </MDTypography>
                        </div>
                      </div>
                    }
                  </div>
                  {((details?.[0]?.children?.length + 1) === same_flight_sub?.flight_offers?.length) &&
                    <div className='mt-2 d-flex justify-content-center'>
                      <CurrentStatus paidPrice={flight?.price} currentPrice={totalPrice} samePrice={"samePrice"} returnID={details?.[0]?.children?.length > 0 ? true : false} ticketStatuses={ticketStatuses} item={details} ticket={same_flight_sub} handleStatus={handleStatus} />
                    </div>
                  }

                  {/* <div className="main_price text-end">
                    <MDTypography
                      variant="h4"
                      color="white"
                      className="flight_details"
                    >
                      <span className="total_price">{flight?.price ? "Price Paid: " : ""}</span>
                    
                      {
                        (flight?.price && parseFloat(flight?.price?.replace("$", "")?.replace("USD", "")) > flight?.total_charges)
                          ? `${flight?.price?.replace("$", "")?.replace("USD", "") ? `$${flight?.price?.replace("$", "")?.replace("USD", "")}` : ""}`
                          : `${flight?.total_charges?.replace("$", "")?.replace("USD", "") ? `$${flight?.total_charges?.replace("$", "")?.replace("USD", "")}` : ""}` || "NA"
                      }
                    </MDTypography>
                    {
                      ((details?.[0]?.children?.length + 1) === same_flight_sub?.flight_offers?.length) &&
                      <div className="d-flex justify-content-end mt-2 align-items-center">
                        <span className="total_price">{totalPrice ? "Current Price: " : ""}</span>
                        <MDTypography
                          variant="h6"
                          color="white"
                          className="flight_details"
                        >
                          {""} {totalPrice}
                        </MDTypography>
                      </div>
                    }

                    <div className='mt-3 d-flex justify-content-center ms-2'>
                      <CurrentStatus samePrice={"samePrice"} returnID={details?.[0]?.children?.length > 0 ? true : false} ticketStatuses={ticketStatuses} item={details} ticket={same_flight_sub} handleStatus={handleStatus} />
                    </div>
                  </div> */}

                  <div className="mobile_none"></div>
                </div>
              </div>
            ))
          ) : (
            <p>No flight available</p>
          )}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>

            {status === "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status === "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Please let us know why you are Rejecting this offer.
              </MDTypography>
            }

            {status === "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }
            {
              status === "REINSTATE" && (
                <MDTypography variant="p" className="delete_msj">
                  Are you sure? Would you like to reinstate this flight?
                </MDTypography>
              )
            }

            {status !== "ACCEPT" && status !== "REINSTATE" && (
              <>

                <div className="my-3 text-left">
                  <FormControl fullWidth>
                    <InputLabel id="reason-select-label">
                      Select Reason
                    </InputLabel>
                    <Select
                      labelId="reason-select-label"
                      value={selectedReason}
                      onChange={handleChange}
                      label="Select Reason"
                      className="select_reason_input"
                    >
                      {REASON_CHOICES?.map((reason) => (
                        <MenuItem key={reason[0]} value={reason[0]}>
                          {reason[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedReason === "OTHER" && (
                    <TextField
                      fullWidth
                      label="Please specify the reason"
                      variant="outlined"
                      value={otherReasonText}
                      onChange={(e) => {
                        setOtherReasonText(e.target.value);
                        setSelectMesage("");
                      }}
                      className="other_reason_input mt-3"
                      multiline
                      rows={3}
                    />
                  )}
                  <div className="error_message my-1">{selectMessage}</div>
                </div></>

            )}
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              {status === "ACCEPT" ? "No" : "Cancel"}
            </Button>
            {
              status === "ACCEPT" ?
                <Button
                  variant="primary"
                  className="delete_butn"
                  type="button"
                  onClick={() => handleUpdateStatus()}
                >
                  Yes
                </Button>
                :
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                > Update
                </Button>
            }
          </div>
        </Modal.Body>
      </Modal>
    </MDBox>
  );
}

export default FlightBooking;