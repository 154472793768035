import React from "react";
import airlinrlogo from "assets/img/airlinrlogo.png";
import plane from "assets/img/plane.svg";

const ReturnSubstitute = ({ ticket, handleSubstituteDetail }) => {

    const calculateTotalDuration = (segments) => {
        if (!segments?.length) return "0 hr 0 min"; 
    
        const departureTime = new Date(segments[0].departure_time);
        const arrivalTime = new Date(segments[segments.length - 1].arrival_time);
    
        const diffMs = arrivalTime - departureTime; 
        const totalMinutes = Math.floor(diffMs / (1000 * 60)); 
    
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
    
        return `${hours}hr ${minutes}min`;
    };  

    const getTime = (departure, arrival) => {

        const departureDate = new Date(departure);
        const arrivalDate = new Date(arrival);

        const differenceInMilliseconds = arrivalDate - departureDate;
        const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}hr ${minutes}min`;
    };

    const formatTime = (isoString) => {
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(new Date(isoString));
      };


    return (

        <>
            <div className="row py-2">
                <div className={`col-md-12 col-lg-7 mb-0`}>
                    <div className="row align-item-center">
                        <div className="col-4">
                            <div>
                                <p className="time_difference mb-0">
                                    {ticket?.segments?.[0]?.departure_time ? formatTime(ticket?.segments?.[0]?.departure_time) : ""}
                                </p>

                                <p className="depature_code">
                                    {ticket?.segments?.[0]?.departure_airport}
                                </p>

                                <p className="time_difference mt-0 mb-0">
                                    {new Date(ticket?.segments?.[0]?.departure_time).toLocaleDateString()}
                                </p>
                            </div>
                        </div>
                        <div className="col-4 d-flex">
                            <div style={{ lineHeight: "normal" }}>
                                <p className="stops_count">STOPS: <span><span className="ticket_number">{ticket?.segments?.length - 1}</span></span>
                                    <svg onClick={() => handleSubstituteDetail(ticket?.id, ticket?.segments?.length - 1)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill view_stops_count" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                </p>
                                <img
                                    src={plane}
                                    alt="Custom Plane"
                                    width="68px"
                                />
                                <p className="text-center time_difference mb-0 mt-0">

                                    {calculateTotalDuration(ticket?.segments)}

                                    {/* {getTime(moment(ticket?.departure_date ? ticket?.departure_date : ticket?.departure_time).format(),
                                        moment(ticket?.arrival_date ? ticket?.arrival_date : ticket?.arrival_time).format()
                                    )} */}
                                </p>
                            </div>
                        </div>
                        <div className="col-4 text-end">
                            <ul className="ul-list">
                                <p className="time_difference mb-0">
                                    {ticket?.segments?.[ticket?.segments.length - 1]?.arrival_time ? formatTime(ticket?.segments?.[ticket?.segments.length - 1]?.arrival_time) : ""}
                                </p>

                                <p className="depature_code">
                                    {ticket?.segments?.[ticket?.segments.length - 1]?.arrival_airport}
                                </p>

                                <p className="time_difference mb-0 mt-1">
                                    {new Date(ticket?.segments?.[ticket?.segments.length - 1]?.arrival_time).toLocaleDateString()}
                                </p>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    className={`col-md-12 col-lg-5`}
                >
                    <div
                        className="d-flex"
                        style={{ alignItems: "center" }}
                    >
                        <img
                            src={airlinrlogo}
                            alt=""
                            className="img-fluid"
                        />
                        <div className="ticktnam mx-2">
                            <p className="ticktptag12">
                                {ticket?.segments?.[0]?.carrier_code}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ReturnSubstitute;