import { useState, useEffect, useMemo, createContext } from "react";
import "./App.css";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import AddUser from "layouts/tables/data/addUser";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import routes from "routes";
import { ToastContainer } from "react-toastify";
import userRoutes from "userRoutes";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import Login from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/reset-password";
import Logo from "assets/images/auth/logo.png";
import NewPassword from "layouts/authentication/NewPassword";
import Register from "layouts/authentication/sign-up";
import VerifyEmail from "layouts/authentication/VerifyEmail";
import PriceMonitor from "./layouts/Price Monitor/Pricr-Monitor";
import ViewUserDetail from "layouts/tables/viewUser";
import Subscription from "layouts/subscription";
import AddAdmin from "layouts/AdminManagment/addAdmin";
import FrontPageHome from "layouts/Front-Page/FrontPageHome";
import UserDetails from "layouts/tables/userDetails";
import FlightDetail from "layouts/tables/flightDetail";
import adminRoutes from "adminRoutes";
import Pricing from "layouts/subscription/pricing";
import Plan from "layouts/subscription/plan";
import BookingDetails from "layouts/Booking/booking-details";
import SuccessMsj from "layouts/subscription/success";
import ContactUs from "layouts/Contactus";
import AboutPage from "layouts/About";
import SavingAmountSuccess from "layouts/subscription/savingAmountSuccess";
import UpdateBooking from "layouts/user/userDashboard/updateBooking";
import UpdateHotel from "layouts/user/userDashboard/updateHotel";
import EmailData from "layouts/authentication/EmailData";
import MyContext from "layouts/Context/context";
import PrivateRoute from "authroutes/Private";
import UpdateCar from "layouts/user/userDashboard/updateCar";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [profile, setProfile] = useState("");
  const [routeData, setRouteData] = useState([]);
  const [sub_id, setSubId] = useState(null)
  const [totalAmount, setTotalAmount] = useState(null)
  const [bookingId, setBookingId] = useState(null)

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const subscritipn = localStorage.getItem("is_subscription");

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const navigate = useNavigate()


  useEffect(() => {

    const token = localStorage.getItem("user_access_token");
    const userdata = localStorage.getItem("active-user");
    const newdata = JSON.parse(userdata)
    if (token) {
      localStorage.setItem("is_subscription", newdata?.is_subscription)
      setProfile(newdata);
      if (newdata?.user_type === 2) {
        setRouteData(userRoutes);
      } else if (newdata?.user_type === 1) {
        setRouteData(adminRoutes);
      } else if (newdata?.user_type === 3) {
        setRouteData(routes);
      } else {
        navigate("/")
      }
    } else {
      if (pathname.includes("/update-booking")
        || pathname.includes("/update-car-booking")
        || pathname.includes("/update-hotel-booking")) {
        localStorage.setItem("update-booking", pathname)
      }
    }
    
    console.log("bookinglocation", pathname, token)
  }, [pathname]);


  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes?.map((route) => {
      if (route?.collapse) {
        return getRoutes(route?.collapse);
      }

      if (route?.route) {
        return (
          <Route
            exact
            path={route?.route}
            element={route?.component}
            key={route?.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {/* <NoAccessModal data={sub_id} show={show} setShow={setShow} /> */}

      {layout === "dashboard" && (
        <>
          {pathname !== "/home" && pathname !== "/send-notification" && pathname !== "/send-car-notification" && pathname !== "/send-hotel-notification" && pathname !== "/about-us" && pathname !== "/subscription" && pathname !== "/payment/execute/" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? Logo
                    : Logo
                }
                brandName=""
                routes={routeData}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
        </>
      )
      }
      {layout === "vr" && <Configurator />}
      <MyContext.Provider value={{ sub_id, setSubId, totalAmount, setTotalAmount, bookingId, setBookingId }}>
        <Routes>
          {getRoutes(routeData)}
          <Route path="/" index element={<FrontPageHome />} />
          <Route path="/add-user" element={<PrivateRoute><AddUser /></PrivateRoute>} />
          <Route path="/add-admin" element={<PrivateRoute><AddAdmin /></PrivateRoute>} />
          <Route path="/update-admin/:id" element={<AddAdmin />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/edit-user/:id" element={<PrivateRoute><AddUser /></PrivateRoute>} />
          <Route path="/user-detail/:id" element={<PrivateRoute><ViewUserDetail /></PrivateRoute>} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/user-details/:id" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
          <Route path="/flight-details/:id" element={<PrivateRoute><FlightDetail /></PrivateRoute>} />
          <Route path="/booking-details/:id" element={<PrivateRoute><BookingDetails /></PrivateRoute>} />
          <Route path="/price-monitor/:id" element={<PrivateRoute><PriceMonitor /></PrivateRoute>} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/update-booking/:id" element={<PrivateRoute><UpdateBooking /></PrivateRoute>} />
          <Route path="/update-car-booking/:id" element={<PrivateRoute><UpdateCar /></PrivateRoute>} />
          <Route path="/send-notification" element={<EmailData />} />
          <Route path="/send-car-notification" element={<EmailData />} />
          <Route path="/send-hotel-notification" element={<EmailData />} />
          <Route path="/update-hotel-booking/:id" element={<PrivateRoute><UpdateHotel /></PrivateRoute>} />
          <Route path="/payment/execute/" element={<SuccessMsj />} />
          <Route path="/payment/payment-success/" element={<SavingAmountSuccess />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/password-reset-confirm/:userId/:token/" element={<NewPassword />} />
          <Route path="*" element={<Navigate to={localStorage.getItem("user_access_token") ? "/dashboard" : "/login"} />} />
        </Routes>
      </MyContext.Provider>
      <ToastContainer />
    </ThemeProvider>
  );
}





// {
//   (profile?.user_type == 1 || profile?.user_type == 3 || subscritipn == "true" || subscritipn == true || sub_id?.is_payment == false) ? (
//     <>
//       {getRoutes(routeData)}
//       <Route path="/add-user" element={<AddUser />} />
//       <Route path="/add-admin" element={<AddAdmin />} />
//       <Route path="/" index element={<FrontPageHome />} />
//       <Route path="/update-admin/:id" element={<AddAdmin />} />
//       <Route path="/contact-us" element={<ContactUs />} />
//       <Route path="/edit-user/:id" element={<AddUser />} />
//       <Route path="/user-detail/:id" element={<ViewUserDetail />} />
//       <Route path="/about-us" element={<AboutPage />} />
//       <Route path="/user-details/:id" element={<UserDetails />} />
//       <Route path="/flight-details/:id" element={<FlightDetail />} />
//       <Route path="/booking-details/:id" element={<BookingDetails />} />
//       <Route path="/price-monitor/:id" element={<PriceMonitor />} />
//       <Route path="/subscription" element={<Subscription />} />
//       <Route path="/update-booking/:id" element={<UpdateBooking />} />
//       <Route path="/send-notification" element={<EmailData />} />
//       <Route path="/update-hotel-booking/:id" element={<UpdateHotel />} />
//       <Route path="/payment/execute/" element={<SuccessMsj />} />
//       <Route path="/payment/payment-success/" element={<SavingAmountSuccess />} />
//       <Route path="/plan" element={<Plan />} />
//       <Route path="/login" element={<Login />} />
//       <Route path="/register" element={<Register />} />
//       <Route path="/verify-email" element={<VerifyEmail />} />
//       <Route path="/reset-password" element={<ResetPassword />} />
//       <Route path="/password-reset-confirm/:userId/:token/" element={<NewPassword />} />
//       <Route path="*" element={<Navigate to="/about-us" />} />

//     </>
//   ) : (
//     <>
//       <Route path="/subscription" element={<Subscription />} />
//       <Route path="/payment/execute/" element={<SuccessMsj />} />
//       <Route path="/pricing" element={<Pricing />} />
//       <Route path="/plan" element={<Plan />} />
//       <Route path="/login" element={<Login />} />
//       <Route path="/send-notification" element={<EmailData />} />
//       <Route path="/about-us" element={<AboutPage />} />
//       <Route path="/register" element={<Register />} />
//       <Route path="/contact-us" element={<ContactUs />} />
//       <Route path="/verify-email" element={<VerifyEmail />} />
//       <Route path="/" index element={<FrontPageHome />} />
//       <Route path="/reset-password" element={<ResetPassword />} />
//       <Route
//         path="/password-reset-confirm/:userId/:token/"
//         element={<NewPassword />}
//       />
//       <Route path="*" element={<Navigate to={"/login"} />} />
//     </>
//   )
// }
