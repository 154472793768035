import MDTypography from "components/MDTypography";
import React from "react";
import MDButton from "components/MDButton";
import Matched from "assets/img/matched-icon.png";
import Animities from "assets/img/animities-icon.png";

const HotelSubstitute = ({ hotel, handleStatus, ticketStatuses, index, userStatus }) => {

    const formattedDate = (dateStr) => {
        const [year, month, day] = dateStr?.split('-');
        const formattedDate = `${month}/${day}/${year}`;
        
        return formattedDate
    }

    return (
        <>
            <div className="hotel_header substitutes_card_flight row" style={{ paddingBottom: "0px !important" }}>
                <div className="hotel_name_title col-md-5">
                    <div className="pe-2">
                        <MDTypography
                            variant="h6"
                            className="hotel_name"
                        >
                            {hotel?.hotel_name}
                        </MDTypography>
                        <p
                            className="hotel_address"
                        >
                            {hotel?.hotel_address}
                        </p>
                        <div className="ticket_details">
                            <MDTypography
                                variant="p"
                                className="ticket_title"
                            >
                                Check in: <strong className="ms-1">
                                    {formattedDate(hotel?.check_in_date)}
                                </strong>
                            </MDTypography>
                        </div>
                        <div className="ticket_details">
                            <MDTypography
                                variant="p"
                                className="ticket_title"
                            >
                                Check out: <strong className="ms-1">{formattedDate(hotel?.check_out_date)}</strong>
                            </MDTypography>
                        </div>
                    </div>

                    <div className="row align-items-center" style={{ lineHeight: "normal" }}>
                        <div className="hotel_price col-md-12 col-lg-6">
                            <MDTypography
                                variant="p"
                                className="fstptag mb-0"
                            >
                                ${hotel?.price_total}
                            </MDTypography>
                            <p
                                className="sndptag mb-0"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                You Can Save{" "}
                                <span
                                    style={{
                                        color: "#61a108",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {hotel?.saved_amount ? `$${hotel?.saved_amount}` : ""}
                                    {/* {hotel?.saved_amount?.toFixed(2)} */}
                                    {/* $ {getSaving(hotel?.price?.total, item.rental_price)} */}
                                </span>
                            </p>
                        </div>

                        <div className="col-md-12 col-lg-6 adult_room_type">
                            <MDTypography
                                variant="p"
                                className="ticket_title mb-0"
                            >
                                {hotel?.adults && hotel?.adults === 1 ? `${hotel?.adults} Adult` : `${hotel?.adults} Adults`}
                            </MDTypography>
                            {/* <span className="mx-1">|</span> */}
                            <MDTypography
                                variant="p"
                                className="ticket_title  text-capitalize"
                            >
                                {hotel?.room_type}

                            </MDTypography>
                        </div>
                    </div>
                </div>

                <div className="col-md-5 py-2 px-2">
                    {/* <div>
                        <div className="ticket_details">
                            <img src={Matched} />
                            <MDTypography
                                variant="p"
                                className="ticket_title ms-1"
                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {hotel?.match_percentage}% Matched
                                </span>
                            </MDTypography>
                        </div>

                        <ul className="row">
                            {hotel?.percentage_changes?.split(",")?.map((percent, i) => {

                                return (
                                    <li
                                        className="col-md-12 hotel_desc_list"
                                        key={i}                                    >
                                        {percent}
                                    </li>
                                );

                            })}
                        </ul>
                    </div> */}

                    <div className="animities_section">
                        <div className="ticket_details">
                            <img src={Animities} />
                            <MDTypography
                                variant="p"
                                className="ticket_title ms-1"
                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    Amenities:
                                </span>

                            </MDTypography>
                        </div>
                        <ul className="row">
                            {hotel.description_text
                                ?.split(",")
                                .map((value) => {
                                    if (value !== "") {
                                        return (
                                            <li
                                                className="col-md-12 hotel_desc_list"
                                                key={index}
                                            >
                                                {value}
                                            </li>
                                        );
                                    }
                                })}
                        </ul>
                    </div>
                </div>

                {
                    userStatus ?
                        <div className="col-md-2 status_butn_section">
                            <div className="col-md-12 d-flex justify-content-end  airlinetkt ">
                                <div className="col-md-12 text-end">
                                    <div className="flight_detail_butns">
                                        {hotel?.status === "PENDING" ?
                                            <MDButton
                                                className="accept_butn"
                                            >
                                                PENDING
                                            </MDButton>
                                            :
                                            <>
                                                <MDButton
                                                    onClick={() =>
                                                        handleStatus(
                                                            hotel.id,
                                                            "ACCEPT"
                                                        )
                                                    }
                                                    className={`${hotel?.status === "ACCEPT" ? "accepted_butn" : "accept_butn"}`}
                                                >
                                                    {hotel?.status === "ACCEPT"
                                                        ? "ACCEPTED"
                                                        : "ACCEPT"}
                                                </MDButton>

                                                <MDButton
                                                    onClick={() =>
                                                        handleStatus(
                                                            hotel.id,
                                                            "REJECT"
                                                        )
                                                    }
                                                    className={`${hotel?.status === "REJECT" ? "rejected_butn" : "accept_butn"}`}
                                                >
                                                    {hotel?.status === "REJECT"
                                                        ? "REJECTED"
                                                        : "REJECT"}
                                                </MDButton>

                                                <MDButton
                                                    onClick={() =>
                                                        handleStatus(
                                                            hotel.id,
                                                            "ELIMINATE"
                                                        )
                                                    }
                                                    className={`${hotel?.status === "ELIMINATE" ? "eliminated_butn" : "accept_butn"}`}
                                                >
                                                    {hotel?.status === "ELIMINATE"
                                                        ? "ELIMINATED"
                                                        : "ELIMINATE"}
                                                </MDButton>
                                            </>

                                        }

                                        {/* {hotel?.status === "ELIMINATE" &&
                                            <MDButton
                                                className="eliminated_butn"
                                            >
                                                ELIMINATED
                                            </MDButton>
                                        }

                                        {hotel?.status === "ACCEPT" &&
                                            <MDButton
                                                className="accept_butn accepted_butn"
                                            >
                                                ACCEPTED
                                            </MDButton>
                                        }

                                        {hotel?.status === "REJECT" &&
                                            <MDButton
                                                className="rejected_butn"
                                            >
                                                REJECTED
                                            </MDButton>
                                        } */}

                                        {/* {
                                            hotel?.additional_information === "OTHER" ?
                                                <p className="status_reason mb-0">{hotel?.other_reason_text}</p>
                                                :
                                                <>
                                                    {
                                                        status?.map((item, i) => {
                                                            if (item?.name === hotel?.additional_information) {
                                                                return (
                                                                    <>
                                                                        <p key={i} className="status_reason mb-0">{item?.value}</p>
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }

                                                </>
                                        } */}


                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {hotel?.status === "PENDING" ? (
                                <div className="col-md-2 d-flex airlinetkt">
                                    <div className="col-md-12">
                                        <div className="hotel_detail_butns">
                                            <MDButton
                                                className="accept_butn"
                                                onClick={() =>
                                                    handleStatus(hotel.id, "ACCEPT")
                                                }
                                                disabled={
                                                    ticketStatuses[hotel.id] ||
                                                    hotel.status !== "PENDING"
                                                }
                                            >
                                                ACCEPT
                                            </MDButton>
                                            <MDButton
                                                className={`reject_butn
                                            `}
                                                onClick={() =>
                                                    handleStatus(hotel.id, "REJECT")
                                                }
                                                disabled={
                                                    ticketStatuses[hotel.id] ||
                                                    hotel.status !== "PENDING"
                                                }
                                            >REJECT

                                            </MDButton>
                                            <MDButton
                                                className="eliminate_butn"
                                                onClick={() =>
                                                    handleStatus(
                                                        hotel.id,
                                                        "ELIMINATE"
                                                    )
                                                }
                                                disabled={
                                                    ticketStatuses[hotel.id] ||
                                                    hotel.status !== "PENDING"
                                                }
                                            >
                                                ELIMINATE
                                            </MDButton>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="col-md-2 d-flex justify-content-end  airlinetkt">
                                        <div className="col-md-12 text-end">
                                            <div className="hotel_detail_butns">
                                                <MDButton
                                                    className={`accept_butn ${hotel?.status === "ACCEPT" &&
                                                        "accepted_butn"
                                                        }`}
                                                    disabled={hotel?.status === "ACCEPT"}
                                                    onClick={() =>
                                                        handleStatus(
                                                            hotel.id,
                                                            "ACCEPT"
                                                        )
                                                    }
                                                >
                                                    {hotel?.status === "ACCEPT"
                                                        ? "ACCEPTED"
                                                        : "ACCEPT"}
                                                </MDButton>

                                                {hotel?.status !== "ACCEPT" &&
                                                    <>
                                                        <MDButton
                                                            className={`${hotel?.status === "REJECT"
                                                                ? "rejected_butn"
                                                                : "reject_butn"
                                                                }`}
                                                            disabled={hotel?.status === "REJECT"}
                                                            onClick={() =>
                                                                handleStatus(
                                                                    hotel.id,
                                                                    "REJECT"
                                                                )
                                                            }
                                                        >
                                                            {hotel?.status === "REJECT"
                                                                ? "REJECTED"
                                                                : "REJECT"}
                                                        </MDButton>

                                                        <MDButton
                                                            className={`${hotel?.status === "ELIMINATE"
                                                                ? "eliminated_butn"
                                                                : "eliminate_butn"
                                                                }`}
                                                            disabled={hotel?.status === "ELIMINATE"}
                                                            onClick={() =>
                                                                handleStatus(
                                                                    hotel.id,
                                                                    "ELIMINATE"
                                                                )
                                                            }
                                                        >
                                                            {hotel?.status === "ELIMINATE"
                                                                ? "ELIMINATED"
                                                                : "ELIMINATE"}
                                                        </MDButton>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                }



            </div>

        </>
    )
}

export default HotelSubstitute;