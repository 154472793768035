import { Grid, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const TopChartBox = ({ pieData }) => {

  const [selectedCategory, setSelectedCategory] = useState("Reject");

  useEffect(() => {
    if (pieData?.data?.eliminate) {
      setSelectedCategory("Eliminate")
    } else {
      setSelectedCategory("Reject")
    }
  }, [])


  const categoryData = selectedCategory === "Reject" && pieData?.data?.reject ? pieData?.reject : pieData.eliminate;

  const filteredData = Object.entries(pieData.data)
    .filter(([_, value]) => value > 0);

  const labels = filteredData.map(([key]) =>
    key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
  );
  const values = filteredData.map(([_, value]) => value);

  const filteredCateData = Object.entries(categoryData).filter(([_, value]) => value > 0);
  const catelabels = filteredCateData.map(([key]) =>
    key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
  );
  const catValues = filteredCateData.map(([_, value]) => value);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");

  const plotWidth = isSmallScreen ? 300 : isMediumScreen ? 350 : 500;

  return (
    <>
      <Grid item xs={12} md={12} lg={6}>
        <MDBox mb={3}>
          <MDTypography variant="h6">Booking Status</MDTypography>
          <Plot
            className="chart_section"
            data={[{
              labels: labels,
              values: values,
              type: "pie",
              marker: {
                colors: ["#344767", "#2a6dea", "#10c6f5"],
                opacity: Object.keys(pieData.data).map(
                  (key) => (selectedCategory && key !== selectedCategory ? 0.4 : 1)
                ),
              },
              textinfo: "label+percent",
              hoverinfo: "label+percent",
            }]}
            config={{
              displaylogo: false
            }}
            layout={{
              title: "Overall Data",
              height: 400,
              autosize: true,
              clickmode: "none",
              legend: {
                itemclick: false,
                itemdoubleclick: false,
              }
            }}
            onClick={(event) => {
              const clickedLabel = event.points[0].label;
              if (clickedLabel === "Reject" || clickedLabel === "Eliminate") {
                setSelectedCategory(clickedLabel);
              }
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <MDBox mb={3}>
          <MDTypography variant="h6">{selectedCategory === "Reject" ? "Reject Reasons" : "Eliminate Reasons"}</MDTypography>
          <Plot
            className="chart_section"
            data={[{
              labels: catelabels,
              values: catValues,
              type: "pie",
              marker: {
                colors: ["#344767", "#2a6dea", "#10c6f5", "cyan"],
              },
              textinfo: "label+percent",
              hoverinfo: "label+percent",
            }]}
            config={{
              displaylogo: false
            }}
            layout={{
              title: `Breakdown of ${selectedCategory}`,
              height: 400,
              autosize: true,
              clickmode: "event", // Change clickmode here
              legend: {
                itemclick: false, // Prevents slices from disappearing on legend click
                itemdoubleclick: false, // Prevents hiding others when double-clicking
              }
            }}
          />
        </MDBox>
      </Grid>
    </>
  );
}

export default TopChartBox