import { Navigate } from "react-router-dom";

const Private = ({ children }) => {

    const token = localStorage.getItem("user_access_token");

    return token ? (
        children
    ) : (
        <Navigate to={"/login"} />
    )
}

export default Private