import Profile from "layouts/profile";
import Icon from "@mui/material/Icon";
import UserDashboard from "layouts/user/userDashboard";
import PassedBooking from "layouts/PassedBooking";
import UserAuth from "authroutes/User";

const userRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small" className="sidebar_icons">home</Icon>,
    route: "/dashboard",
    component: <UserAuth><UserDashboard /></UserAuth>,
  },
  {
    type: "collapse",
    name: "Booking History",
    key: "history",
    icon: <Icon fontSize="small" className="sidebar_icons">Booking</Icon>,
    route: "/booking-history",
    component: <UserAuth><PassedBooking /></UserAuth>,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small" className="sidebar_icons">person</Icon>,
    route: "/profile",
    component: <UserAuth><Profile /></UserAuth>,
  },
];

export default userRoutes;
