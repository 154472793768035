import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Button, Grid } from "@mui/material";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { planDescription } from "utils/api";
import { planCreate } from "utils/api";
import { toast } from "react-toastify";
import Loader from "components/Loader";

function Subscription() {

  const [planData, setPlanData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    planDescription().then((res) => {
      if(res.status == 200){
        setPlanData(res.data)
      }      
      setLoading(false)
    }).catch((error) => {
      console.log("error", error)
      setLoading(false)
    })
  }, [])

  const handleSubscrition = () => {
    planCreate().then((res) => {
      if (res.status == 200) {
        window.location.href = res.data.approval_url
      } else {
        toast.success(res?.data?.message, {
          theme: "colored",
          autoClose: 3000,
        })
      }

    }).catch((error) => {
      console.log(error)
    })
  }


  return (
    <BasicLayout>
      <div className="registration_bg">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={10}>
            {
              loading ?
                <Loader />
                :
                <MDBox>
                  <div className="text-center">
                    <MDTypography variant="h5" color="white">Registration</MDTypography>
                  </div>
                  <div>
                    <Grid container justifyContent="center">
                      
                      <Grid item xs={12} md={5} p={2}>
                        <Card className="premium_card">
                         
                          <MDTypography variant="p" color="white" className="for_month" mt={1}>
                            {planData?.[0]?.message && (
                              <>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.5rem',
                                    display: 'block',
                                    textAlign: 'center',
                                  }}
                                >
                                  {planData[0].message.split('\n')[0]}
                                </span>
                              </>
                            )}
                          </MDTypography>

                          <MDTypography variant="p" color="white" className="for_month">
                            {planData?.[0]?.message && (
                              <>
                                <span style={{
                                  display: 'block',
                                  textAlign: 'center',
                                  margin: "5px 20px",
                                  display: "flex",
                                  justifyContent: "center"
                                }}>
                                  {planData[0].message.split('\n')[1]}
                                </span>
                              </>
                            )}
                          </MDTypography>

                          <div className="text-center">
                            <MDTypography
                              className="plans_name"
                              variant="h1"
                              color="white"
                            >
                              ${(planData?.[0]?.price)}
                            </MDTypography>
                          </div>
                          <Button onClick={() => handleSubscrition()} variant="light" className="get_started_2">
                            Pay Now
                          </Button>
                          <MDTypography variant="p" color="white" className="for_month2" mt={2}>
                            <span style={{
                              display: 'block',
                              textAlign: 'left',
                              margin: "5px 10px"
                            }}>
                              {planData?.[0]?.message.split('\n').slice(2).join(" ")}
                            </span>
                          </MDTypography>
                        </Card>
                      </Grid>
                    </Grid>

                  </div>
                </MDBox>
            }
          </Grid>
        </Grid>
      </div>
    </BasicLayout>
  );
}

export default Subscription;
