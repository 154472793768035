import BasicLayout from "layouts/authentication/components/BasicLayout";
import Navbar from "layouts/Front-Page/Navbar";
import NewsletterFooter from "layouts/Front-Page/NewsletterFooter";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { contactUs } from "utils/api";


const ContactUs = () => {

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        message: "",
    });

    const [errors, setErrors] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        message: "",
    });

    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = "";

        switch (name) {
            case "first_name":
                if (!value) error = "First Name is required";
                break;
            case "last_name":
                if (!value) error = "Last Name is required";
                break;
            case "email":
                if (!value) {
                    error = "Email is required";
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    error = "Please enter a valid email";
                }
                break;
            case "phone_number":
                if (!value) {
                    error = "Phone number is required";
                } else if (!/^\d{7,16}$/.test(value)) {
                    error = "Phone number must be between 7 and 16 digits";
                }
                break;
            case "message":
                if (!value) error = "Message is required";
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!formData.first_name) {
            newErrors.first_name = "First Name is required";
            isValid = false;
        }

        if (!formData.last_name) {
            newErrors.last_name = "Last Name is required";
            isValid = false;
        }

        if (!formData.email) {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Please enter a valid email";
            isValid = false;
        }

        if (!formData.phone_number) {
            newErrors.phone_number = "Phone number is required";
            isValid = false;
        } else if (!/^\d{10}$/.test(formData.phone_number)) {
            newErrors.phone_number = "Phone number must be 10 digits";
            isValid = false;
        }

        if (!formData.message) {
            newErrors.message = "Message is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true)
            contactUs(formData)
                .then((res) => {
                    setLoading(false)
                    if (res.status == 200) {
                        toast.success(res?.data?.message, {
                            theme: "colored",
                        });
                        setFormData({
                            first_name: "",
                            last_name: "",
                            email: "",
                            phone_number: "",
                            message: "",
                        });
                        setErrors({
                            first_name: "",
                            last_name: "",
                            email: "",
                            phone_number: "",
                            message: "",
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    toast.error("Something went wrong", {
                        theme: "colored",
                    });
                });
        }
    };


    return (
        <>
            <BasicLayout>
                <div className="home-container">
                    <Navbar />
                    <div className="py-5 container">
                        <div className="contact_us_title">
                            <h1 className="contact_title">Contact</h1>
                        </div>

                        <div className="mt-5 row contact_form_design p-0">
                            <div className="col-md-7 p-5">
                                <h4>Contact us</h4>
                                <p className="contact_text">Feel Free to contact us any time.
                                    <br /> We will get back to you as soon as we can!.</p>
                                <div className="form-section">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group row ">
                                            <div className="col-md-6 mb-2">
                                                <input
                                                    type="text"
                                                    className="formcontrol"
                                                    name="first_name"
                                                    placeholder="First Name"
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.first_name && (
                                                    <p className="error">{errors.first_name}</p>
                                                )}
                                            </div>

                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="formcontrol"
                                                    name="last_name"
                                                    placeholder="Last Name"
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.last_name && <p className="error">{errors.last_name}</p>}
                                            </div>
                                        </div>

                                        <div className="form-group row my-2">
                                            <div className="col-md-6 mb-2">
                                                <input
                                                    type="email"
                                                    className="formcontrol"
                                                    name="email"
                                                    placeholder="Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <p className="error">{errors.email}</p>}
                                            </div>

                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="formcontrol"
                                                    name="phone_number"
                                                    placeholder="Phone number"
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                />
                                                {errors.phone_number && (
                                                    <p className="error">{errors.phone_number}</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row my-2">
                                            <div className="col-md-12">
                                                <textarea
                                                    className="form-control"
                                                    name="message"
                                                    placeholder="How We Can Help You?"
                                                    rows="5"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                ></textarea>
                                                {errors.message && <p className="error">{errors.message}</p>}
                                            </div>
                                        </div>

                                        <button type="submit" className="sendButton mt-4">
                                            {loading ? "Loading..." : "Send Message"}
                                        </button>
                                    </form>
                                </div>
                            </div>

                            <div className="col-md-5 m-0 px-0 d-flex justify-content-end py-5">
                                <div className="info_details">
                                    <h5 className="mt-3">Contact Info</h5>
                                    <a href="tel:+14342359631" className="phone_number gap-2 mt-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                        </svg>
                                        +1 (434) 235-9631
                                    </a>
                                    <a href="mailto:brian@airebooker.com" target="_blank" className="phone_number gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                                        </svg>
                                        brian@airebooker.com
                                    </a>
                                    <p className="phone_number gap-2">Feel Free to contact us any time.
                                        <br /> We will get back to you as soon as we can!.</p>

                                    <div className="d-flex align-items-center gap-4 mt-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NewsletterFooter />
                </div>
            </BasicLayout>
        </>
    )
}

export default ContactUs;