import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";
import { updateSubstituteStatus } from "utils/api";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import FlightDetails from "components/FlightDetails";
import FlightSubstitute from "components/FlightDetails/flightSubstitute";
import PriceDetails from "components/FlightDetails/priceDetails";
import { layoversFlight } from "utils/api";
import StatusButtons from "components/FlightDetails/statusbutton";
import FlightDetailModal from "components/FlightDetailModal";
import ReturnSubstitute from "components/FlightDetails/returnSubstitute";


function PastFlightBooking({ flights, loading, setLoading, allBookingData }) {
  const navigate = useNavigate();
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");
  const [flightshow, setFlightShow] = React.useState(false);
  const [subId, setSubId] = React.useState(null)
  const [data, setData] = React.useState([])
  const [numStops, setNumStops] = React.useState(null)
  const [flightsData, setFlightsData] = React.useState([])


  React.useEffect(() => {
    setFlightsData(flights)
  }, [flights, allBookingData])

  const flightHandleClose = () => {
    setFlightShow(false);
    setData([])
  };

  const REASON_CHOICES = [
    ["INCOMPLETE_RECORDS", "Incomplete Records"],
    ["TECHNICAL_ISSUES", "Technical Issues"],
    ["POLICY_RESTRICTIONS", "Policy Restrictions"],
    ["HUMAN_ERROR", "Human Error"],
    ["NOT_RELEVANT", "Not Relevant"],
    ["OTHER", "Other"],
  ];

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleStatus = (id, status) => {
    // setShow(id);
    // setStatus(status);
  };


  const handleUpdateStatus = () => {
    setLoading(true)
    if (!selectedReason && status !== "ACCEPT") {
      setSelectMesage("Please select any reason");
    } else {
      updateSubstituteStatus(show, {
        status: status,
        additional_information: selectedReason,
      })
        .then((res) => {
          setLoading(true)
          if (res.status === 200) {
            allBookingData();
            toast.success(
              `Status ${status === "ELIMINATE"
                ? `${status?.toLowerCase()}d`
                : `${status?.toLowerCase()}ed`
              } successfully`,
              {
                theme: "colored",
              }
            );
            setShow(false);
            setSelectedReason("");
            setSelectMesage("");
          } else {
            toast.error(`Failed to ${status?.toLowerCase()} status`, {
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          setLoading(true)
          console.log(error);
        });
    }
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "flights");
    navigate(`/price-monitor/${final_id}`, {
      state: { passBooking: "pass_booking", }
    });
  };

  const handleSubstituteDetail = (id, num) => {
    layoversFlight(id).then((res) => {
      setData(res.data)
      setNumStops(num)
      setFlightShow(true)
      setSubId(id)
    }).catch((error) => {
      console.log(error)
    })
  }


  return (
    <MDBox py={3}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {flightsData?.length > 0 ?
            flightsData?.map((item, i) => {
              console.log("item?.children?.length", item?.children)
              if (item?.is_show_data === true) {
                return (
                  <Accordion className="mb-3" defaultExpanded={i === 0} key={i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <div className="airline_header row justify-content-start align-items-center p-0">
                        <div className="col-md-8 col-lg-10 flight_detail_bg">
                          {
                            item?.children?.length > 0 ?
                              <>
                                <div className="outbound_section">
                                  <span className="outbound_booking">Outbound flight</span>
                                  <FlightDetails item={item} getTime={getTime} />
                                  {
                                    item?.children?.map((child, i) => {
                                      if (child?.is_return_flight === false) {
                                        return (
                                          <FlightDetails item={child} getTime={getTime} />
                                        )
                                      }
                                    })
                                  }
                                </div>


                                {
                                  item?.children?.map((child, i) => {
                                    const firstReturnFlightIndex = item?.children?.findIndex(c => c?.is_return_flight === true);
                                    if (child?.is_return_flight === true) {
                                      return (
                                        <>
                                          {
                                            i === firstReturnFlightIndex &&
                                            <div className="outbound_section">
                                              <div className="children_data"></div>
                                              <span className="outbound_booking">Return flight</span>
                                            </div>
                                          }
                                          <FlightDetails children={"children"} item={child} getTime={getTime} />
                                        </>
                                      )
                                    }
                                  })
                                }
                              </>
                              :
                              <FlightDetails oneWay={"oneWay"} item={item} getTime={getTime} />
                          }

                        </div>
                        <div className="col-md-4 col-lg-2 main_price">
                          <div className="row px-2">
                            <div className="col-12 px-1">
                              <div className="price_section_paid ">
                                <span><span className="total_price">{item?.price ? "Price paid" : ""}</span></span>
                                <MDTypography
                                  variant="h6"
                                  color="white"
                                  className="flight_details main_flight_price"
                                >
                                  {`$${item?.price?.replace("$", "")?.replace("USD", "")}`}
                                </MDTypography>
                              </div>
                            </div>

                            {/* {(item?.children?.length + 1) === item?.same_flight_sub?.flight_offers?.length && */}
                            {/* {item?.same_flight_sub?.total_price &&
                              <div className="col-6 px-1">
                                <div className="price_section_paid">
                                  <span className="total_price">{item?.same_flight_sub?.total_price ? "Current Price" : ""}</span>
                                  <MDTypography
                                    variant="h6"
                                    color="white"
                                    className="flight_details"
                                  >
                                    {""} {item?.same_flight_sub?.total_price}
                                  </MDTypography>
                                </div>
                              </div>} */}


                            {/* } */}
                          </div>
                          {/* <MDTypography
                            variant="h4"
                            color="white"
                            className="flight_details"
                          >
                            {item?.children?.length > 0 ?
                              `$${item?.price?.replace("$", "")?.replace("USD", "")}` :
                              `$${item?.price?.replace("$", "")?.replace("USD", "")}`}
                          </MDTypography> */}
                          {/* <div className="d-flex justify-content-end mt-2 align-items-center">
                            <span className="total_price">{item?.same_flight_sub?.total_price ? "Now: " : ""}</span>
                            <MDTypography
                              variant="h6"
                              color="white"
                              className="flight_details"
                            >
                              {""} {item?.same_flight_sub?.total_price}
                            </MDTypography>
                          </div> */}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="substitues_section">
                      <div className="">
                        <div className="substitutes my-2">
                          <MDTypography variant="h6" color="white">
                            Substitutes Tickets
                          </MDTypography>
                          {item?.substitutes && item?.substitutes?.length > 0 ? (
                            <button
                              className="view_all"
                              onClick={() => redirection(item?.id)}
                            >
                              View All
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                        {loading ? (
                          <>
                            <MDTypography
                              className="text-center"
                              variant="h6"
                              color="white"
                            >
                              Loading...
                            </MDTypography>
                          </>
                        ) : (
                          <>
                            {/* <div>
                              {item?.same_flight_sub &&
                                <div
                                  className="container-fluid p-3 mb-3 subtiktmain position-relative"
                                >
                                  <button className="current_butn">Current</button>
                                  <div
                                    className="row align-items-center"
                                    style={{ alignItems: "center" }}
                                  >
                                    {
                                      item?.same_flight_sub?.return_offer ?
                                        <div className="col-md-6 col-lg-7">
                                          <FlightSubstitute returnSub={"returnSub"} item={item} ticket={item?.same_flight_sub?.outbound_offer} getTime={getTime}
                                            handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                            ticketStatuses={ticketStatuses} />

                                          <FlightSubstitute returnSub={"returnSub"} item={item} ticket={item?.same_flight_sub?.return_offer} getTime={getTime}
                                            handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                            ticketStatuses={ticketStatuses} />

                                        </div>
                                        :
                                        <div className="col-md-6 col-lg-7">
                                          <FlightSubstitute returnSub={"returnSub"} item={item} ticket={item?.same_flight_sub?.outbound_offer} getTime={getTime}
                                            handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                            ticketStatuses={ticketStatuses} />
                                        </div>
                                    }
                                    <PriceDetails current={"current"} item={item} ticket={item?.same_flight_sub?.return_offer ? item?.same_flight_sub : item?.same_flight_sub?.outbound_offer} getTime={getTime} />
                                    <StatusButtons samePrice={"samePrice"} returnID={item?.same_flight_sub?.return_offer?.id} ticketStatuses={ticketStatuses} item={item} ticket={item?.same_flight_sub?.outbound_offer} handleStatus={handleStatus} />
                                  </div>
                                </div>

                              }
                            </div> */}


                            {item?.substitutes &&
                              item?.substitutes?.length > 0 &&
                              item?.substitutes
                                ?.slice(0, 2)
                                ?.map((ticket, index) => (
                                  <div
                                    key={index}
                                    className="container-fluid p-3 mb-3 subtiktmain"
                                  >
                                    <div
                                      className="row align-items-center"
                                      style={{ alignItems: "center" }}
                                    >
                                      {
                                        ticket?.return_offer ?
                                          <div className="col-md-6 col-lg-7">
                                            <FlightSubstitute item={ticket} ticket={ticket?.outbound_offer} getTime={getTime}
                                              handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                              ticketStatuses={ticketStatuses} index={index} />

                                            <FlightSubstitute item={ticket} ticket={ticket?.return_offer} getTime={getTime}
                                              handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                              ticketStatuses={ticketStatuses} index={index} />

                                          </div>
                                          :
                                          <div className="col-md-6 col-lg-7">
                                            <FlightSubstitute item={ticket} ticket={ticket?.outbound_offer} getTime={getTime}
                                              handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                              ticketStatuses={ticketStatuses} index={index} />
                                          </div>
                                      }
                                      <PriceDetails item={ticket} ticket={ticket?.outbound_offer} getTime={getTime}
                                        handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                        ticketStatuses={ticketStatuses} index={index} />
                                      <StatusButtons returnID={ticket?.return_offer?.id} ticketStatuses={ticketStatuses} index={index} item={ticket} ticket={ticket?.outbound_offer} handleStatus={handleStatus} />
                                    </div>
                                  </div>
                                ))
                            }

                            {item?.retSubstitues && item?.retSubstitues?.length > 0 ? (
                              (() => {
                                const groupedFlights = item.retSubstitues.reduce((acc, flight) => {
                                  acc[flight.total_price] = acc[flight.total_price] || { outbound: null, return: null };
                                  acc[flight.total_price][flight.flight_type] = flight;
                                  return acc;
                                }, {});

                                const filteredFlights = Object.values(groupedFlights).filter(pair => pair.outbound && pair.return);

                                return filteredFlights?.slice(0, 2)?.map((pair, index) => (
                                  <div
                                    key={index}
                                    className="container-fluid p-3 mb-3 subtiktmain"
                                  >
                                    <div
                                      className="row align-items-center"
                                      style={{ alignItems: "center" }}
                                    >
                                      <div className="col-md-6 col-lg-7">
                                        <ReturnSubstitute ticket={pair?.outbound} item={pair} index={index} handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                          ticketStatuses={ticketStatuses} />
                                        <ReturnSubstitute ticket={pair?.return} item={pair} index={index} handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                          ticketStatuses={ticketStatuses} />
                                      </div>
                                      <PriceDetails retprice={"return_price"} item={pair} ticket={pair?.outbound} getTime={getTime}
                                        handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                                        ticketStatuses={ticketStatuses} index={index} />
                                      <StatusButtons ticketStatuses={ticketStatuses} index={index} item={pair} ticket={pair?.outbound_offer} handleStatus={handleStatus} />
                                    </div>
                                  </div>
                                ));
                              })()
                            ) : null}
                          </>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )
              }
            })
            :
            <>
              <div className="text-center mt-3">
                <MDTypography variant="h5">No Booking History Found</MDTypography>
              </div>
            </>
          }
        </div>
      )}

      <FlightDetailModal numStops={numStops} data={data} subId={subId} setSubId={setSubId} flightshow={flightshow} setFlightShow={setFlightShow} handleClose={flightHandleClose} />

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {status === "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status === "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Please let us know why you are Rejecting this offer.
              </MDTypography>
            }

            {status === "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }

            {status !== "ACCEPT" && (
              <div className="my-3 text-left">
                <FormControl fullWidth>
                  <InputLabel id="reason-select-label">
                    Select Reason
                  </InputLabel>
                  <Select
                    labelId="reason-select-label"
                    value={selectedReason}
                    onChange={handleChange}
                    label="Select Reason"
                    className="select_reason_input"
                  >
                    {REASON_CHOICES?.map((reason) => (
                      <MenuItem key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            )}

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleUpdateStatus();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </MDBox>
  );
}

export default PastFlightBooking;
