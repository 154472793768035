import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getUser } from "utils/userApi";
import Icon from "@mui/material/Icon";
import "./tableStyle.css";
import Loader from "components/Loader";
import { getPermissionByID } from "utils/api";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import Modal from "react-bootstrap/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { updateUserStatus } from "utils/api";
import { getUserByID } from "utils/userApi";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ITEMS_PER_PAGE = 5;

const BookingCount = ({ userId }) => {
  const [totalCount, setTotalCount] = useState(null);

  useEffect(() => {
    if (userId) {
      getUserByID(userId).then((res) => {
        const data = res?.data?.children;
        const count =
          (data?.flight_data?.filter(
            (flight) => flight?.parent_booking === null && flight?.is_show_data
          )?.length || 0) +
          (data?.hotel_data?.filter(
            (flight) => flight?.is_show_data
          )?.length || 0) +
          (data?.car_data?.filter(
            (flight) => flight?.is_show_data
          )?.length || 0);
        setTotalCount(count);
      });
    }
  }, [userId]);

  return <p>{totalCount !== null ? totalCount : "0"}</p>;
};

function Tables() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [togalStatus, setTogalStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [permission, setPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [userType, setUserType] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const location = useLocation();

  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    setFilteredUsers(users)
  }, [users])


  const fetchUsers = () => {
    setLoading(true);
    getUser()
      .then((response) => {
        if (response?.status === 200) {
          setLoading(false);
          setUsers(response.data);
          setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE));
        } else {
          setLoading(false);
          toast.error("Failed to fetch users");
          setUsers([]);
        }
      })
      .catch((error) => {
        setUsers([]);
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  };

  const handleOpenModal = (id, status) => {
    setOpenModal(id);
    setTogalStatus(status);
  };

  const handleStatusToggle = () => {
    setLoading(true);
    updateUserStatus(openModal, { is_active: togalStatus })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          toast.success(res?.data?.message, {
            theme: "colored",
          });
          fetchUsers();
          setOpenModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
    getUserPermission();

    const searchText = localStorage.getItem("user_search_value");

    if (searchText) {
      setSearchValue(searchText);
    } else if (location.state?.search) {
      setSearchValue(location.state.search);
    } else if (searchText === null) {
      setSearchValue("");
    } else {
      setSearchValue("");
    }

    const clearSearchOnRefresh = () => {
      localStorage.removeItem("user_search_value");
      navigate(location.pathname, { replace: true, state: null });
    };

    window.addEventListener("beforeunload", clearSearchOnRefresh);

    return () => {
      window.removeEventListener("beforeunload", clearSearchOnRefresh);
    };
  }, []);

  const getUserPermission = () => {
    const user = JSON.parse(localStorage.getItem("active-user"));
    const id = user?.id
    if (user) {
      setUserType(user.user_type);
      getPermissionByID()
        .then((res) => {
          const userData = res?.data?.user_permissions
          const filterdata = userData?.filter((item) => {
            return item?.user_id === id
          })
          const data = filterdata[0]?.permissions;
          const newData = data?.filter((item) => {
            return item.codename === "view_user";
          });
          setPermission(newData?.[0]);
          const addUSer = data?.filter((item) => {
            return item.codename === "add_user";
          });
          setAddPermission(addUSer?.[0]);
        })
        .catch((error) => {
          console.error("Error fetching permission:", error);
        });
    }
  };


  const columns = [
    {
      name: "sr_no",
      label: "Sr. No.",
      options: {
        filter: false,
        setCellProps: () => ({ style: { width: "90px", textAlign: "left", paddingLeft: "20px" } }),
        setCellHeaderProps: () => ({ style: { width: "90px", textAlign: "left", paddingLeft: "20px" } }),
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
      },
    },
    {
      name: "bookings",
      label: "Bookings",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const newuser = filteredUsers?.length > 0 ? filteredUsers : users;
          const userId = newuser[tableMeta.rowIndex]?.id;

          return <BookingCount userId={userId} />;
        },
      },
    },
    {
      name: "savings",
      label: "Pending Amount",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const totalSavedAmount = value?.reduce((acc, item) => acc + parseFloat(item?.saved_amount?.replace("$", "")), 0);
          const payment = value?.[0]?.is_payment
          const result = totalSavedAmount && payment === false ? `$${(totalSavedAmount * 0.10)?.toFixed(2)}` : "0"
          return <>{result}</>;
        },
      },
    },
    {
      name: "is_active",
      label: "Active Status",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { width: "130px" } }),
        setCellHeaderProps: () => ({ style: { width: "130px" } }),
        customBodyRender: (value, tableMeta) => {
          const newuser = filteredUsers?.length > 0 ? filteredUsers : users
          const userId = newuser[tableMeta.rowIndex]?.id;
          // const userId = tableMeta.rowData[6];
          return (
            <MDBox display="flex" justifyContent="left">
              <Button
                variant="contained"
                size="large"
                startIcon={<Icon>{value ? "toggle_on" : "toggle_off"}</Icon>}
                style={{
                  color: value ? "green" : "red",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  minWidth: "auto",
                  padding: "8px 5px",
                }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleOpenModal(userId, !value)
                }}
              />
            </MDBox>

          );
        },
      },
    },
    // {
    //   name: "id",
    //   label: "Action",
    //   options: {
    //     filter: false,
    //     setCellProps: () => ({ style: { width: "120px" } }),
    //     setCellHeaderProps: () => ({ style: { width: "120px" } }),
    //     customBodyRender: (value) => {
    //       return (
    //         <MDBox display="flex" justifyContent="left">
    //           {/* View Button */}
    //           <Button className="user_eye_icon">
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="20"
    //               height="20"
    //               fill="currentColor"
    //               className="bi bi-eye-fill"
    //               viewBox="0 0 16 16"
    //               onClick={() => {
    //                 navigate(`/user-details/${value}`, { state: searchValue })
    //                 if(searchValue){
    //                   localStorage.setItem("user_search_value", searchValue);
    //                 }
    //                 localStorage.setItem("user-sub-id", value)
    //               }}
    //             >
    //               <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
    //               <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
    //             </svg>
    //           </Button>
    //         </MDBox>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    download: false,
    viewColumns: false,
    searchText: searchValue,
    search: true,
    onSearchChange: (searchedText) => {
      setSearchValue(searchedText);
      const filtered = users?.filter((user) =>
        user?.first_name?.toLowerCase()?.includes(searchedText?.toLowerCase()) ||
        user?.last_name?.toLowerCase()?.includes(searchedText?.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(searchedText?.toLowerCase())
      );
      setFilteredUsers(filtered); // Store filtered data in state
    },
    onRowClick: (rowData, tableMeta) => {
      console.log(rowData)
      // const updatedUsers = searchValue ? filteredUsers : users; // Ensure updated list is used
      const userId = (users.find((user) => user.email === rowData[3])).id;
      if (userId) {
        navigate(`/user-details/${userId}`, { state: searchValue });

        if (searchValue) {
          localStorage.setItem("user_search_value", searchValue);
        }
        localStorage.setItem("user-sub-id", userId);
      }
    }
  };


  const handleClose = () => {
    setOpenModal(false);
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              textAlign: "left",
              marginLeft: "10px",
              paddingLeft: "10px",
            },
            head: {
              fontWeight: "bold",
              textAlign: "left", // left header cells
              marginLeft: "10px",
              paddingLeft: "10px",
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  User Management
                </MDTypography>
                {(
                  addPermission?.codename === "add_user" ||
                  userType === 1) && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => navigate("/add-user")}
                    >
                      Add User
                    </Button>
                  )}
              </MDBox>
              {loading ? (
                <Loader />
              ) : (
                <MDBox pt={3}>
                  <div className="table-container">
                    {users?.length > 0 ? (
                      <>
                        <div className="custom-table">
                          {
                            permission?.codename === "view_user" ||
                              userType === 1 ? (
                              <div className="tableContainer">
                                <ThemeProvider theme={getMuiTheme()}>
                                  <MUIDataTable
                                    title={""}
                                    data={users}
                                    columns={columns}
                                    options={options}
                                    className="user_data_table user_detail_admin"
                                  />
                                </ThemeProvider>
                              </div>
                            ) : (
                             
                              <div className="text-center mt-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="35"
                                  height="35"
                                  fill="currentColor"
                                  className="bi bi-x-circle cancel_icon mb-4"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                                <MDTypography variant="h3">
                                  You Have No Access
                                </MDTypography>
                              </div>
                            )}
                        </div>
                        
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Modal show={openModal} onHide={handleClose} centered className="layover_modal">
        <Modal.Body>
          <div className="text-center p-4">
            <img src="/favicon.png" width={100} className="mb-4" />            
            <MDTypography variant="h5">Are you Sure?</MDTypography>
            <MDTypography variant="p" className="delete_msj">
              Do you want to {!togalStatus ? "Deactivate" : "Active"} the User?
            </MDTypography>
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleStatusToggle();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </DashboardLayout>
  );
}

export default Tables;
