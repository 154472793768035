import React from 'react'
import { Navigate } from 'react-router-dom';

const UserAuth = ({ children }) => {
    const token = localStorage.getItem("user_access_token");
    const user = JSON.parse(localStorage.getItem("active-user"));
    const subscription = localStorage.getItem("is_subscription");


    return (token && (user?.user_type == 2)) ? (
        <>
            {
                (subscription == true || subscription == "true") ? (
                    children
                ) : (
                    <Navigate to={"/login"} />
                )
            }
        </>
    ) : (
        <Navigate to={"/login"} />
    )
}

export default UserAuth