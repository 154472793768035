import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";
import Cardetail from "components/CarDetails";
import { Card } from "@mui/material";
import CarSubstitute from "components/CarDetails/carSubstitute";

const PastCarBooking = ({ carData, loading }) => {
  return (
    <MDBox py={3}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {carData?.length > 0 ?
            carData?.map((item, index) => (
              <Accordion
                className="mb-3"
                defaultExpanded={0}
                key={index}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Cardetail item={item} />

                </AccordionSummary>
                <AccordionDetails className="substitues_section">
                  <div className="substitutes my-2 text-center">
                    <MDTypography variant="h6" color="white">
                      Substitutes Tickets
                    </MDTypography>
                  </div>

                  <Card className="hotel_cards my-2">
                    <CarSubstitute />
                  </Card>
                </AccordionDetails>
              </Accordion>
            ))
            :
            <div className="text-center mt-3">
              <MDTypography variant="h5">No Booking History Found</MDTypography>
            </div>}
        </div>
      )}
    </MDBox>
  );
};

export default PastCarBooking;
