import React from "react";
import MDTypography from "components/MDTypography";
import HotelImg from "assets/user-dash/hotelimg.png";
import { useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";

const Hoteldetail = ({ item }) => {

    const navigate = useNavigate()

    const updatebooking = (id) => {
        let final_id = encodeIdWithType(id);
        navigate(`/update-hotel-booking/${id}`);
        localStorage.setItem("active-tab", 2)
    }

    const formattedDate = (dateStr) => {
        const [year, month, day] = dateStr?.split('-');
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate
    }

    return (
        <>
            <div className="airline_header row hotel_booking_design mt-0" >
                <MDTypography variant="p"
                    color="white"
                    className="total_price mb-1">Booking Reference:<strong className="ms-1">{item?.booking_reference}</strong>
                </MDTypography>
                <i onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updatebooking(item?.id)
                }} className="bi bi-pencil-square hotel_update_icon"></i>
                <div className="col-md-8 col-lg-10">
                    <div className="row d-flex align-items-center">
                        <div className="airline_data col-md-12 py-0">
                            <img src={HotelImg} alt="airline logo" />
                            <div className="row align-items-center hotel_name_detail">
                                <div className="col-md-12 col-lg-4">
                                    <div className="airline_title_section">
                                        <MDTypography
                                            variant="h6"
                                            color="white"
                                            className="airline_heading"
                                        >
                                            {item?.hotel_name}

                                        </MDTypography>
                                        <MDTypography
                                            variant="h6"
                                            color="white"
                                            className="hotel_address mt-1"
                                        >
                                            {item?.hotel_address}
                                        </MDTypography>
                                        <div>
                                            <MDTypography
                                                variant="p"
                                                color="white"
                                                className="ticket_title hotel_room_type"
                                            >
                                                {item?.room_type}
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mt-1 ">
                                        <p className="pick_up">
                                            Check In: <strong className="ms-1">{`${formattedDate(item?.check_in_date)}`}</strong>
                                        </p>
                                        <p className="pick_up">
                                            Check Out: <strong className="ms-1">{formattedDate(item?.check_out_date)}</strong>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-4 hotel_book_id">
                                    <MDTypography
                                        variant="h5"
                                        color="white"
                                        className="flight_details"
                                    >
                                        {item?.guest_name}
                                    </MDTypography>

                                    <div>
                                        <MDTypography
                                            variant="p"
                                            color="white"
                                            className="ticket_title hotel_room_type"
                                        > {item?.guest_count ? `${item?.guest_count?.replace("Adults", "")?.replace("Adult", "")} Adults` : ""}
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="col-md-4 col-lg-2">
                    <MDTypography
                        variant="h5"
                        color="white"
                        className="flight_details"
                    >
                        ${item?.rental_price?.replace("$", "")}
                    </MDTypography>

                </div>
            </div>
        </>
    )
}

export default Hoteldetail;