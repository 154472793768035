import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";

function ComplexStatisticsCard({ color, title, count, percentage, icon }) {
  return (
    <Card className="dashboard_top_boxes">
      <Grid container pt={4} px={2} >
        <Grid item xs={4} sm={4} md={5} lg={5} >
          <MDBox
            variant="gradient"
            bgColor="#2196f321"
            color={color === "light" ? "#2196f3" : "#2196f3"}
            // coloredShadow={color}
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            className="box_bg_img"
          >
            <img src={icon} />
            {/* <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon> */}
          </MDBox>
        </Grid>
        <Grid item xs={8} sm={8} md={7} lg={7}>
          <MDBox textAlign="left" lineHeight={1}>
            <MDTypography variant="h4">{count}</MDTypography>
            <MDTypography variant="button" fontWeight="light" color="text">
              {title}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <Divider />
      
    </Card>
  );
}

ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
