import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";
import Hoteldetail from "components/HotelDetail";
import HotelSubstitute from "components/HotelDetail/hotelSubstitute";
import { Modal } from "react-bootstrap";
import { updateHotelStatus } from "utils/api";
import { toast } from "react-toastify";
import { Button, Card, FormControl, InputLabel, MenuItem, Select, TextField, } from "@mui/material";

const HotelDetail = ({ booking, substitutes, loading, allBookingData }) => {
  const navigate = useNavigate();
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("")
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case 'ELIMINATE':
        return [
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['NOT_ACCEPTABLE_HOTEL', 'Not Acceptable Hotel'],
          ['OTHER', 'Other'],
        ];
      case 'REJECT':
        return [
          ['WAITING', 'Waiting/Not Enough Saving'],
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['OTHER', 'Other'],
        ];
      default:
        return [
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['NOT_ACCEPTABLE_HOTEL', 'Not Acceptable Hotel'],
          ['OTHER', 'Other'],
          ['WAITING', 'Waiting/Not Enough Saving'],
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
        ];
    }
  }, [status]);

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== 'OTHER') {
      setOtherReasonText("");
    }
  };

  const [ticketStatuses, setTicketStatuses] = React.useState({});

  const [show, setShow] = React.useState(false);


  const handleClose = () => {
    setShow(false);
    setSelectedReason("");
    setOtherReasonText("");
    setSelectMesage("");
  };

  const handleStatus = (id, status) => {
    setShow(id);
    setStatus(status);
  };

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT") {
      setSelectMesage("Please select any reason");
      return;
    }
    if (selectedReason === 'OTHER' && !otherReasonText.trim()) {
      setSelectMesage("Please provide a reason in the text field");
      return;
    }
    updateHotelStatus(show, {
      status: status,
      additional_information: selectedReason,
      other_reason_text: selectedReason === 'OTHER' ? otherReasonText : null
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(
            `Status ${status === "ELIMINATE"
              ? `${status?.toLowerCase()}d`
              : `${status?.toLowerCase()}ed`
            } successfully`,
            {
              theme: "colored",
            }
          );
          if (status === "ACCEPT") {
            setPaymentShow(true)
            setSelectedReason("");
            setOtherReasonText("");
            setSelectMesage("");
            setShow(false);
          } else {
            allBookingData();
            setSelectedReason("");
            setOtherReasonText("");
            setSelectMesage("");
            setShow(false);
          }
        } else {
          toast.error(`Failed to ${status?.toLowerCase()} status`, {
            theme: "colored",
          });
          setShow(false);
          setSelectedReason("");
          setOtherReasonText("");
          setSelectMesage("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div>
          <MDBox py={3}>
            <div className="user_detail_section">
              <MDTypography variant="p" className="p-title">
                <svg onClick={() => handleBackButtonClick()} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} width="34" height="34" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                </svg>
                {booking?.hotel_name}
              </MDTypography>
            </div>
            <Card className="container-fluid mb-2 p-3 hotel_detail_bg">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <MDTypography
                    variant="p"
                    color="white"
                    className="Current-Plane"
                  >
                    Your Current Hotel Booking
                  </MDTypography>
                </div>
                <div className="col-md-8 dotted-gradient-border"></div>

                <Hoteldetail item={booking} />
              </div>
            </Card>
          </MDBox>
          {substitutes.length > 0 ? (
            <>
              <MDTypography variant="p" className="p-title">
                Substitute Tickets
              </MDTypography>
              {substitutes.map((hotel, index) => (
                <div
                  key={index}
                  className="container-fluid p-3 mb-3 subtiktmain"
                >
                  <HotelSubstitute hotel={hotel} index={index} userStatus={"userStatus"} ticketStatuses={ticketStatuses} handleStatus={handleStatus} />

                </div>
              ))}
            </>
          ) : (
            <MDTypography variant="h6" color="textSecondary">
              No Substitutes Found
            </MDTypography>
          )}
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            <MDTypography variant="h5">Are you Sure?</MDTypography>
            {status === "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status === "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Please let us know why you are Rejecting this offer.
              </MDTypography>
            }

            {status === "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }

            {status !== "ACCEPT" &&
              <div className="my-3 text-left">
                <FormControl fullWidth>
                  <InputLabel id="reason-select-label">Select Reason</InputLabel>
                  <Select
                    labelId="reason-select-label"
                    value={selectedReason}
                    onChange={handleChange}
                    label="Select Reason"
                    className="select_reason_input"
                  >
                    {REASON_CHOICES?.map((reason) => (
                      <MenuItem key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </MenuItem>
                    ))}
                    {selectedReason === 'OTHER' && (
                      <TextField
                        fullWidth
                        label="Please specify the reason"
                        variant="outlined"
                        value={otherReasonText}
                        onChange={(e) => {
                          setOtherReasonText(e.target.value);
                          setSelectMesage(""); // Clear any previous error messages
                        }}
                        className="other_reason_input"
                        multiline
                        rows={3}
                      />
                    )}
                  </Select>
                </FormControl>
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            }

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleUpdateStatus();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotelDetail;
