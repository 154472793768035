import React from 'react';
import './service.css'
import servicecard1 from 'assets/img/servicecard1.png'


const Services = () => {
  return (
    <div className="container py-5">
    <div className="row center-test">
     
      <div className="col-md-3">
        <div className="image-overlay">
          <img
            src={servicecard1}
            alt="Overlapping Image"
            className="overlapping-image "
          />
        </div>
      </div>
  
    
      <div className="col-md-8 col-sm-12">
        <div className="services-box p-4">
          <div className="srvscontent">
            <h2>Services</h2>
            <p className="serviceptag">
              <ul>
                <li>Monitoring real-time prices for flights, hotels, and rental cars.</li>
                <li>Offering logical substitutes.</li>
                <li>Effectively managing corporate travel through prudent travel agents and policy management.</li>
              </ul>
            </p>
            <button className="btn btn-primary">Know More</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default Services;
