import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { fetchFlightOffers } from "utils/api";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import { decodeIdWithType } from "utils/encoder";
import { fetchSubstitueHotel } from "utils/api";
import { flightBookingDetails } from "utils/api";
import { HotelBookingDetails } from "utils/api";
import { CarBookingDetails } from "utils/api";
import FlightBooking from "./components/flightBooking";
import HotelBooking from "./components/hotelBooking";
import CarBooking from "./components/carBooking";
import FlightSubstitutes from "./components/flightSubstitute";

function BookingDetails() {
  const params = useParams();
  const [loadingBookings, setLoadingBookings] = useState(true);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null);

  const { type, id } = decodeIdWithType(params.id);
  const [booking_data, setBookingData] = useState([]);
  const [substitutes, setSubstitutes] = useState([]);


  useEffect(() => {
    setLoadingBookings(true)
    if (id) {
      if (type === "flights") {
        flightBookingDetails(id)
          .then((response) => {
            setBookingData(response?.data);
          })
          .catch((error) => {
            console.log(error);
          });
        fetchFlightOffers(id).then((res) => {
          setLoadingBookings(false);
          if (res?.data) {
            setSubstitutes(Array.isArray(res?.data?.paired_offers) ? res?.data?.paired_offers : []);
          } else {
            setSubstitutes([]);
          }
        });
      } else if (type === "hotels") {
        HotelBookingDetails(id)
          .then((response) => {
            setBookingData(response?.data);
          })
          .catch((error) => {
            console.log(error);
          });
        fetchSubstitueHotel(id).then((res) => {
          setLoadingBookings(false);
          if (res.data) {
            setSubstitutes(Array.isArray(res.data) ? res.data : []);
          } else {
            setSubstitutes([]);
          }
        });
      } else if (type === "cars") {
        setLoadingBookings(true);
        CarBookingDetails(id)
          .then((response) => {
            setBookingData(response?.data);
            setLoadingBookings(false);
          })
          .catch((error) => {
            setLoadingBookings(false);
            console.log(error);
          });
        setSubstitutes([]);
      }
    } else {
      setError(new Error("No flight ID provided."));
      setLoadingBookings(false);
    }
  }, [id]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {loadingBookings ? (
          <Loader />
        ) : (
          <>
            {type === "flights" ? (
              <>
                <FlightBooking details={booking_data} />                
                  <FlightSubstitutes
                    substitutes={substitutes}
                    loading={loadingBookings}
                    current_price={booking_data[0]?.price}
                  />               
              </>
            ) : type === "hotels" ? (
              <>
                <HotelBooking
                  booking={booking_data}
                  substitutes={substitutes}
                  loading={loadingBookings}
                />
              </>
            ) : type === "cars" ? (
              <>
                <CarBooking
                  booking={booking_data}
                  substitutes={substitutes}
                  loading={loadingBookings}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default BookingDetails;
