import React from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import office_1 from "../assets/images/office_1.png";
import office_2 from "../assets/images/office_2.png";
import office_3 from "../assets/images/office_3.png";

const offices = [
    {
        name: "Hello, Berlin.",
        src: office_1,
        lane_1: "Warschauer Platz 12",
        lane_2: "10245 Berlin |",
        lane_3: "Germany"
    },
    {
        name: "Hello, Cambridge.",
        src: office_2,
        lane_1: "10 Canal Park",
        lane_2: "Cambridge, MA 02141 |",
        lane_3: "USA"
    },
    {
        name: "Hola, Miami.",
        src: office_3,
        lane_1: "255 Giralda Ave, 5/",
        lane_2: "F Miami, FL 33134 |",
        lane_3: "USA"
    }
]
const OfficeSwiper = () => {
    return (
            <Swiper
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={3}
                navigation={{
                    nextEl: ".office-slide-next__active",
                    prevEl: ".office-slide-prev__active",
                }}
                direction='horizontal'
                breakpoints={{
                    320: { slidesPerView: 1 },
                    425: { slidesPerView: 1 },
                    640: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                    1280: { slidesPerView: 3 },
                    1440: { slidesPerView: 3 },
                    1920: { slidesPerView: 3 },
                }}
                className='w-100 row'
            >
                {
                    offices.map((card, index) => (
                        <SwiperSlide key={index} className='col-md-4 col-lg-4 col-12'>
                            <div className={`card p-4 text-center office-card ${index === 0 ? 'border-primary shadow-lg' : ''}`}>
                                <img src={card.src} alt={card.name} className='w-100 rounded' />
                                <div className='my-4 swiper_desc_text'>
                                    <h5 className='fw-bold'>{card.name}</h5>
                                    <p className='mb-0'>{card.lane_1}</p>
                                    <p className='mb-0'>{card.lane_2}</p>
                                    <p className='mb-5'>{card.lane_3}</p>
                                    <button className={`btn btn-secondary view_map_butn rounded-pill px-4 py-2  ${index === 0 ? "button-active" : "button-inactive"}`}>View On Map</button>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
    )
}

export default OfficeSwiper