import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
import { fetchBookingsById } from "utils/api";
import MDTypography from "components/MDTypography";
import { CarBookingDetails } from "utils/api";
import { updateCarBookingsById } from "utils/api";
import { toast } from "react-toastify";
import { transferOffers } from "utils/api";

function UpdateCar() {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    // const { id } = decodeIdWithType(params.id);
    const { id } = params
    const navigate = useNavigate()

    const convertTo12Hour = (time24h) => {
        if (!time24h) return "";
        let [hours, minutes] = time24h.split(":");
        let modifier = "AM";

        if (parseInt(hours, 10) >= 12) {
            modifier = "PM";
            if (hours !== "12") {
                hours = String(parseInt(hours, 10) - 12);
            }
        } else if (hours === "00") {
            hours = "12";
        }

        return `${hours}:${minutes} ${modifier}`;
    };

    const [formData, setFormData] = useState([]);
    const [date_time, setDateTime] = useState({
        pickup_date: "",
        dropoff_date: "",
        pickup_time: "",
        drop_time: ""
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "number_of_travelers") {
            const numValue = parseInt(value, 10);
            if (numValue <= 0 || isNaN(numValue)) {
                return;
            }
        }

        if (name === "rental_price") {
            if (value === "") {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: "",
                }));
                return;
            }

            if (!/^\d*\.?\d*$/.test(value)) {
                return;
            }

            const numValue = Number(value);

            if (numValue === 0) {
                return;
            }

            let formattedValue = value.replace(/^0+(\d)/, "$1");

            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
            }));
            return;
        }


        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "departure_time" || name === "Arrives_time" ? convertTo12Hour(value) : value,
        }));
    };


    const handleParentSubmit = (e) => {
        e.preventDefault();
        const requiredFields = [
            "car_model",
            "pickup_date",
            "dropoff_date",
            "rental_price",
            // "pickup_location",
            // "dropoff_location",
            "rental_company",
            "rental_class",
        ];

        for (const field of requiredFields) {
            const value = formData[field];
            if (value === undefined || value === null || value === "" || (typeof value === "string" && value.trim() === "")) {
                toast.error(`Please fill in the ${field.replace("_", " ")} field.`, {
                    theme: "colored"
                });
                return;
            }
        }
        setLoading(true)
        updateCarBookingsById(id, formData).then((res) => {
            setLoading(false)
            if (res.status == 200) {
                transferOffers(id)
                toast.success("Successfully Updated")
                navigate(-1);
            } else {
                toast.error("Failed to update")
            }
        }).catch((error) => {
            console.log("error", error)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchAllSubstitute();
    }, [id]);

    const fetchAllSubstitute = () => {
        const userdata = localStorage.getItem("active-user");
        const newdata = JSON.parse(userdata)
        if (id) {
            setLoading(true);
            if (newdata?.user_type == 2) {
                fetchBookingsById(id)
                    .then((response) => {
                        if (response.status === 200) {
                            const { cars } = response?.data;
                            setFormData(cars?.[0])
                            setDateTime({
                                pickup_date: cars?.[0]?.pickup_date?.split("T")[0], // Extract Date (YYYY-MM-DD)
                                dropoff_date: cars?.[0]?.dropoff_date?.split("T")[0], // Extract Date (YYYY-MM-DD)
                                pickup_time: cars?.[0]?.pickup_date?.split("T")[1]?.slice(0, 5),
                                drop_time: cars?.[0]?.dropoff_date?.split("T")[1]?.slice(0, 5)
                            });
                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => setLoading(false));
            } else {
                CarBookingDetails(id)
                    .then((response) => {
                        if (response.status === 200) {
                            setFormData(response?.data)
                            setDateTime({
                                pickup_date: response?.data?.pickup_date?.split("T")[0], // Extract Date (YYYY-MM-DD)
                                dropoff_date: response?.data?.dropoff_date?.split("T")[0], // Extract Date (YYYY-MM-DD)
                                pickup_time: response?.data?.pickup_date?.split("T")[1]?.slice(0, 5),
                                drop_time: response?.data?.dropoff_date?.split("T")[1]?.slice(0, 5)
                            });

                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => setLoading(false));
            }
        } else {
            console.log(new Error("No flight ID provided."));
            setLoading(false);
        }
    };

    const handleBackButon = () => {
        navigate(-1)
    }

    const handleDateTimeChange = (e) => {
        const { name, value } = e.target;

        setDateTime((prevState) => ({
            ...prevState,
            [name]: value
        }));

        if (name === "pickup_date" || name === "dropoff_date") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value + "T" + (prevData[name]?.split("T")[1] || "00:00:00")
            }));
        } else if (name === "pickup_time" || name === "drop_time") {
            const dateField = name === "pickup_time" ? "pickup_date" : "dropoff_date";
            setFormData((prevData) => ({
                ...prevData,
                [dateField]: (prevData[dateField]?.split("T")[0] || "2025-01-01") + "T" + value + ":00"
            }));
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <MDBox>
                            <div className="d-flex gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={() => handleBackButon()} width="24" height="24" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                                </svg>
                                <MDTypography variant="h6" className="p-title">Update Car Booking</MDTypography>
                            </div>

                            <div className="py-3 px-4">
                                <form className="update_booking_form">

                                    <div className="row update_main_booking my-4">
                                        <MDTypography variant="h6" className="mt-2">Car Details</MDTypography>
                                        <div className="col-md-4 my-2">
                                            <label>
                                                Car Name:
                                            </label>
                                            <input
                                                type="text"
                                                name="car_model"
                                                value={formData?.car_model}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Pickup Date:
                                            </label>
                                            <input
                                                type="date"
                                                name="pickup_date"
                                                value={date_time?.pickup_date}
                                                onChange={handleDateTimeChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Drop Date:
                                            </label>
                                            <input
                                                type="date"
                                                name="dropoff_date"
                                                value={date_time?.dropoff_date}
                                                onChange={handleDateTimeChange}
                                                className="w-full p-2 border rounded"
                                            // disabled={formData?.children?.len}
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Price:
                                            </label>
                                            <input
                                                type="text"
                                                name="rental_price"
                                                value={formData?.rental_price?.replace("USD", "")}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>


                                        <div className="col-md-4 my-2">
                                            <label>
                                                Pickup Time:
                                            </label>
                                            <input
                                                type="time"
                                                name="pickup_time"
                                                value={date_time?.pickup_time}
                                                onChange={handleDateTimeChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Drop Time:
                                            </label>
                                            <input
                                                type="time"
                                                name="drop_time"
                                                value={date_time?.drop_time}
                                                onChange={handleDateTimeChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>

                                                Rental Company:
                                            </label>
                                            <input
                                                type="text"
                                                name="rental_company"
                                                value={formData?.rental_company}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Pickup Location:
                                            </label>
                                            <input
                                                type="text"
                                                name="pickup_location"
                                                value={formData?.pickup_location}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Drop Location:
                                            </label>
                                            <input
                                                type="text"
                                                name="dropoff_location"
                                                value={formData?.dropoff_location}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Rental class:
                                            </label>
                                            <input
                                                type="text"
                                                name="rental_class"
                                                value={formData?.rental_class}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>


                                        <div className="col-md-12 mt-2 mb-4 text-end">
                                            <button type="button" onClick={(e) => handleParentSubmit(e)} className="update_booking_submit">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </MDBox>
                    </>
                )}
            </MDBox>
        </DashboardLayout>
    );
}

export default UpdateCar;
