import React from "react"
import MDButton from "components/MDButton";

const CurrentStatus = ({ ticket, item, returnID, handleStatus, paidPrice, currentPrice }) => {

    return (
        <>
            <div className="col-md-11 col-lg-12 col-5">
                <div className="current_butns">
                    {
                        Number(paidPrice?.replace("$", "").replace("USD", "")) >
                        Number(currentPrice?.replace("$", "").replace("USD", "")) && (
                            <MDButton
                                className="current_accept_butn"
                                onClick={() =>
                                    handleStatus(ticket?.id, "ACCEPT", returnID, ticket?.airline_website, ticket, item, "current")
                                }
                            >
                                ACCEPT
                            </MDButton>
                        )
                    }


                    {/* {
                        `${paidPrice?.replace("$", "")?.replace("USD", "")}` < `${currentPrice?.replace("$", "")?.replace("USD", "")}` &&
                        <MDButton
                            className={`current_reject_butn`}
                        onClick={() =>
                            handleStatus(item?.id, "REJECT")
                        }
                        >
                            REJECT
                        </MDButton>
                    } */}
                </div>
            </div>
        </>
    )
}

export default CurrentStatus;