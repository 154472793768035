import React from "react";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";
import Cardetail from "components/CarDetails";
import CarSubstitute from "components/CarDetails/carSubstitute";

const CarDetails = ({ booking, substitutes, loading }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="user_detail_section">
            <MDTypography variant="p" className="p-title">
              <svg onClick={() => handleBackButtonClick()} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} width="34" height="34" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
              </svg>
              {booking?.car_model}
            </MDTypography>
          </div>
          <Card className="mt-2">
            <div className="car_main_header py-2 px-3">
              <Cardetail item={booking} />
            </div>

          </Card>

          <div className="d-flex justify-content-between align-items-center mt-4 mb-1">
            <MDTypography variant="p" className="p-title">
              Substitute Tickets
            </MDTypography>
            {/* <div>
              <FormControl sx={{ width: 200, mx: 1 }}>
                <InputLabel id="demo-multiple-booking_type-label">Sort by</InputLabel>
                <Select
                  className="sort_by_select"
                  labelId="demo-multiple-booking_type-label"
                  id="demo-multiple-booking_type"
                  value={bookingType}
                  onChange={handleBookingType}
                  input={<OutlinedInput label="Booking" />}
                >
                  {sortOptions?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <button className="clear_butn" onClick={() => handleClear()}>Clear</button>
            </div> */}
          </div>
          <div className="text-center py-1">
            <MDTypography variant="h5" >No substitutes available</MDTypography>
          </div>
          {/* <Card className="hotel_cards">
            <CarSubstitute />
          </Card> */}
        </div>
      )}
    </>
  );
};

export default CarDetails;
