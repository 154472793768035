import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Navbar from "layouts/Front-Page/Navbar";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Plan() {

  const location = useLocation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AVQg6M67LVaJKmIViVANHQztxZ1ktmFU2riAh784oURU0Ct8UxrVjO9HTzY7CrzaJ1th2OmGUV00rUCI&vault=true&intent=subscription";
    script.setAttribute("data-sdk-integration-source", "button-factory");
    script.async = true;
    script.onload = () => {
      if (window.paypal) {
        window.paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "gold",
              layout: "vertical",
              label: "subscribe",
            },
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                plan_id: location?.state
              });
            },
            onApprove: function (data) {
              alert(`Subscription successful! ID: ${data.subscriptionID}`);
            },
          })
          .render("#paypal-button-container"); 
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <BasicLayout>
        <div className="pt-1">
          <Navbar />
        </div>
        <Grid container justifyContent="center">
          <Grid item xs={10} md={4} lg={4}>
            <MDBox pt={6}>
              <Card>
                <div className="p-5">
                  <div id="paypal-button-container"></div>
                </div>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </BasicLayout>
    </div>
  );
}

export default Plan;
