import React from "react";
import MDTypography from "components/MDTypography";
import CarImg from "assets/user-dash/carImage.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";

const Cardetail = ({ item, carBooking }) => {

    const navigate = useNavigate()

    const updatebooking = (id) => {
        let final_id = encodeIdWithType(id);
        navigate(`/update-car-booking/${id}`);
        localStorage.setItem("active-tab", 3)
    }

    const formattedDate = (dateFormate) => {
        const [year, month, day] = dateFormate?.split('T')[0].split('-');
        return `${month}/${day}/${year}`;
    }

    return (
        <>
            <div className="airline_header row car_main_section p-0 position-relative" >
                <MDTypography variant="p"
                    color="white"
                    className="total_price mb-1">Booking Reference:<strong className="ms-1">{item?.booking_reference}</strong>
                </MDTypography>
                <i onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updatebooking(item?.id)
                }} className="bi bi-pencil-square car_update_icon"></i>
                <div className="col-md-8 col-lg-10">
                    <div className="row d-flex align-items-center">
                        <div className="airline_data col-md-12 py-0">
                            <img src={CarImg} alt="airline logo" />
                            <div className="row align-items-center hotel_name_detail">
                                <div className="col-md-12 col-lg-4">
                                    <div className="airline_title_section">
                                        <MDTypography
                                            variant="h6"
                                            color="white"
                                            className="airline_heading"
                                        >
                                            {item?.car_model}
                                        </MDTypography>
                                        <p className="car_details_text">
                                            {item?.rental_company}
                                        </p>
                                        <p className="car_details_text">
                                            {item?.rental_class}
                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <p className="location_details">Location Details</p>
                                    <div className="row">
                                        <div className="col-2">
                                            <p className="pick_up">Pick Up:</p>
                                        </div>
                                        <div className="col-10">
                                            <p className="pickup_location">{item?.pickup_location}</p>
                                            <p className="pickup_date_time">
                                                <strong>
                                                    {item?.pickup_date ? `${formattedDate(item?.pickup_date)}` : ""},
                                                    <span style={{ whiteSpace: "nowrap", marginLeft: "5px" }}>{moment(item?.pickup_date).format("LT")}</span>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <p className="pick_up">Drop Off:</p>
                                        </div>
                                        <div className="col-10">
                                            <p className="pickup_location">{item?.dropoff_location}</p>
                                            <p className="pickup_date_time">
                                                <strong>
                                                    {item?.dropoff_date ? `${formattedDate(item?.dropoff_date)}` : ""},
                                                    <span style={{ whiteSpace: "nowrap", marginLeft: "5px" }}>{moment(item?.dropoff_date).format("LT")}</span>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-3 hotel_book_id">
                                    <MDTypography
                                        variant="h5"
                                        color="white"
                                        className="flight_details"
                                    >
                                        {item?.renter_name}
                                    </MDTypography>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="col-md-4 col-lg-2 d-flex align-items-center justify-content-between">

                    <div>
                        <MDTypography
                            variant="h5"
                            color="white"
                            className="flight_details"
                        >
                            {`$${item?.rental_price}`}
                        </MDTypography>

                    </div>

                    {carBooking &&
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-right-circle-fill next_right_icon" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Cardetail;