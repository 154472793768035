import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Loader from "components/Loader";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { encodeIdWithType } from "utils/encoder";
import { pastFlightsDetails } from "utils/api";
import { fetchFlightOffers } from "utils/api";
import { fetchSubstitueHotel } from "utils/api";
import PastFlightBooking from "./components/pastFlight";
import PastHotelBooking from "./components/pastHotel";
import PastCarBooking from "./components/pastCar";
import { flightOffersById } from "utils/api";
import { userFlightsHistory } from "utils/api";
import { Form } from "react-bootstrap";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Flight Bookings", "Hotel Bookings", "Car Bookings"];
const booking_name = ["Booking accepted", "Booking eliminated", "Booking rejected"];
const history_name = ["Past Booking", "Flight History"];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const PassedBooking = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [flights, setFlights] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [carData, setCarData] = useState([]);
  const [personName, setPersonName] = useState("Flight Bookings");
  const [booking_type, setBookingType] = useState("Booking accepted");
  const [history_type, setHistoryType] = useState("Past Booking");
  const [pageData, setPageData] = useState([]);
  const [filterData, setFilterData] = useState([])
  const [history, setHistory] = useState([])
  const theme = useTheme();

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(pageData?.length / itemsPerPage);

  const displayedItems = pageData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );


  const allBookingData = () => {
    setLoading(true);
    pastFlightsDetails()
      .then(async (response) => {
        const flightsWithSubstitutes = [];
        if (response.data.flights.length > 0) {
          for (const item of response.data.flights) {

            try {
              const firstReturnFlightIndex = item?.children?.findIndex(c => c?.is_return_flight === true);

              // let res; 
              let newres;

              // if (item?.children?.length > 0 && firstReturnFlightIndex >= 0) {
              //   newres = await fetchFlightRoundTripSub(item.id);
              //   // res = await fetchReturnById(item.id);
              // } else {

              //   newres = await fetchFlightSingleTripSub(item.id);
              // }

              const res = await fetchFlightOffers(item.id);

              const flightOffer = await flightOffersById(item.id)

              const retSubstitues = res?.data?.length > 0 ? res?.data : []

              const substitutes = Array.isArray(res.data.paired_offers)
                ? res.data.paired_offers.map((offer) => ({
                  outbound_offer: offer.outbound_offer,
                  return_offer: offer.return_offer,
                  total_price: offer.total_price,
                }))
                : [];

              const same_flight_sub = flightOffer?.data

              if (flightOffer.status == 200) {
                const flightWithSubstitutes = {
                  ...item,
                  substitutes,
                  retSubstitues,
                  same_flight_sub,
                }
                flightsWithSubstitutes.push(flightWithSubstitutes);
              } else {
                const flightWithSubstitutes = {
                  ...item,
                  retSubstitues,
                  substitutes,
                }

                flightsWithSubstitutes.push(flightWithSubstitutes);
              }
            } catch (error) {
              console.error(`Error fetching flight offers for ${item.id}:`, error);
              flightsWithSubstitutes.push({
                ...item,
                substitutes: [],
                total_price: null,
              });
            }
          }

          setFlights(flightsWithSubstitutes);
          setLoading(false);
          setPageData(flightsWithSubstitutes)
          setFilterData(flightsWithSubstitutes)
        }

        if (response.data.hotels.length > 0) {
          let data = [];
          for (let i = 0; i < response.data.hotels.length; i++) {
            let item = response.data.hotels[i];

            let res = await fetchSubstitueHotel(item.id);
            if (res.data) {
              item.substitutes = Array.isArray(res.data) ? res.data : [];
            }
            data.push(item);
          }
          setHotels(data);
          // setFilterData(data)
        }

        if (response?.data?.cars) {
          setCarData(response.data.cars);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("BookingCard: Error fetching data:", error);
        setLoading(false);
      });
  };

  const getFlightsHistory = () => {
    userFlightsHistory().then(async (res) => {

      if (res.status == 200) {
        const flightsWithSubstitutes = [];
        if (res.data.length > 0) {
          for (const item of res.data) {

            try {
              const firstReturnFlightIndex = item?.children?.findIndex(c => c?.is_return_flight === true);

              // let res; 
              let newres;

              // if (item?.children?.length > 0 && firstReturnFlightIndex >= 0) {
              //   newres = await fetchFlightRoundTripSub(item.id);
              //   // res = await fetchReturnById(item.id);
              // } else {

              //   newres = await fetchFlightSingleTripSub(item.id);
              // }

              const res = await fetchFlightOffers(item.id);

              const flightOffer = await flightOffersById(item.id)

              const retSubstitues = res?.data?.length > 0 ? res?.data : []

              const substitutes = Array.isArray(res.data.paired_offers)
                ? res.data.paired_offers.map((offer) => ({
                  outbound_offer: offer.outbound_offer,
                  return_offer: offer.return_offer,
                  total_price: offer.total_price,
                }))
                : [];

              const same_flight_sub = flightOffer?.data

              if (flightOffer.status == 200) {
                const flightWithSubstitutes = {
                  ...item,
                  substitutes,
                  retSubstitues,
                  same_flight_sub,
                }
                flightsWithSubstitutes.push(flightWithSubstitutes);
              } else {
                const flightWithSubstitutes = {
                  ...item,
                  retSubstitues,
                  substitutes,
                }

                flightsWithSubstitutes.push(flightWithSubstitutes);
              }
            } catch (error) {
              console.error(`Error fetching flight offers for ${item.id}:`, error);
              flightsWithSubstitutes.push({
                ...item,
                substitutes: [],
                total_price: null,
              });
            }
          }
          setHistory(flightsWithSubstitutes);
          // setLoading(false);
          // setPageData(flightsWithSubstitutes)
          // setFilterData(flightsWithSubstitutes)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }



  useEffect(() => {
    allBookingData();
    getFlightsHistory()
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const updateFilteredData = (historyType, category) => {
    if (historyType === "Past Booking") {
      if (category === "Flight Bookings") {
        setFilterData(flights);
        setPageData(flights);
      } else if (category === "Hotel Bookings") {
        setFilterData(hotels);
        setPageData(hotels);
      } else if (category === "Car Bookings") {
        setFilterData(carData);
        setPageData(carData);
      }
    } else if (historyType === "Flight History") {
      if (category === "Flight Bookings") {
        setFilterData(history);
        setPageData(history);
      } else {
        setFilterData([]);
        setPageData([]);
      }
    }
  };

  const handleHistoryChange = (e) => {
    const value = e.target.value;
    setHistoryType(value);
    updateFilteredData(value, personName);
  };

  const handleChange = (event) => {
    setPage(1);
    const value = event.target.value;
    setPersonName(value);
    updateFilteredData(history_type, value);
  };


  const handleBookingChange = (event) => {
    const {
      target: { value },
    } = event;

    setPage(1);
    setBookingType(value);

    const statusMapping = {
      "Booking accepted": "ACCEPT",
      "Booking eliminated": "ELIMINATE",
      "Booking rejected": "REJECT",
    };

    const selectedStatus = statusMapping[value];

    const filteredData = filterData?.map((item) => ({
      ...item,
      substitutes: item.substitutes.filter(
        (sub) => sub.outbound_offer.status === selectedStatus
      ),
    }));

    setPageData(filteredData);
  };



  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "flights");
    navigate(`/price-monitor/${final_id}`);
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox my={2}>
          <div className="user_name_icons">
            <div className="user_detail_section">
              <ArrowBackIosNewIcon onClick={handleBackButtonClick} />
              <MDTypography varient="h1" className="user_fullname">
                Booking History
              </MDTypography>
            </div>
          </div>

          <Card className="mt-4">
            <MDBox py={2} px={2}>
              <div className="filters_sections">
                <div className="d-flex gap-2">
                  <Form.Group className="status-select-container" controlId="history-select">
                    <Form.Select
                      value={history_type}
                      onChange={handleHistoryChange}
                      className="custom-status-select"
                    >
                      {history_name?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {/* <MDInput placeholder="Search" /> */}
                  <Form.Group className="status-select-container" controlId="category-select">
                    <Form.Select
                      value={personName}
                      onChange={handleChange}
                      className="custom-status-select"
                    >
                      {names?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="status-select-container" controlId="booking-select">
                    <Form.Select
                      value={booking_type}
                      onChange={handleBookingChange}
                      className="custom-status-select"
                    >
                      {booking_name?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                </div>
              </div>

              {personName == "Flight Bookings" && (
                <PastFlightBooking
                  flights={displayedItems}
                  loading={loading}
                  setLoading={setLoading}
                  allBookingData={allBookingData}
                />
              )}

              {personName == "Hotel Bookings" && (
                <>
                  <PastHotelBooking
                    hotels={displayedItems}
                    loading={loading}
                    allBookingData={allBookingData}
                  />
                </>
              )}

              {personName == "Car Bookings" && (
                <>
                  <PastCarBooking carData={displayedItems} loading={loading} />
                </>
              )}

              {
                displayedItems?.length > 0 &&
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              }
            </MDBox>
          </Card>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default PassedBooking;
