import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUserByID } from "utils/userApi";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AvatorImg from "assets/images/defaultAvatar.jpg";
import MDInput from "components/MDInput";
import AirlineLogo from "assets/user-dash/airlineLogo.png";
import HotelImg from "assets/user-dash/hotelimg.png";
import CarImg from "assets/user-dash/carImage.png";
import Loader from "components/Loader";

const ViewUserDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getUserByID(id)
      .then((res) => {
        setLoading(false);
        setUser(res.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [id]);

  const handleBackButtonClick = () => {
    navigate(-1); // This will navigate to the previous page in history
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox my={2}>
          <div className="user_name_icons">
            <div className="user_detail_section">
              <ArrowBackIosNewIcon onClick={handleBackButtonClick} />
              <MDTypography
                varient="h1"
                className="user_fullname"
              >{`${user?.first_name} ${user?.last_name}`}</MDTypography>
            </div>
            <div className="user_action_icons">
              <Link to={`/edit-user/${user.id}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                  />
                </svg>
              </Link>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="bi bi-trash3-fill"
                viewBox="0 0 16 16"
              >
                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
              </svg>
            </div>
          </div>

          <Card className="mt-4">
            <Grid container className="profile_container">
              <Grid item xs={12} md={4} className="user_profile_image">
                <div>
                  <img
                    src={
                      user?.image
                        ? `${process.env.REACT_APP_API_URL}${user?.image}`
                        : AvatorImg
                    }
                    alt="user-image"
                    className="user_image"
                  />
                  <MDTypography varient="h1" className="user_profile_fullname">
                    {`${user?.first_name} ${user?.last_name}`}
                  </MDTypography>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <MDTypography varient="p" className="general_info">
                  General Info
                </MDTypography>
                <div className="user_intro_section">
                  <Grid container className="user_info_grid">
                    <Grid item xs={12} md={6}>
                      <div className="my-3">
                        <MDTypography varient="p" className="general_info">
                          First Name
                        </MDTypography>
                        <MDInput
                          value={user?.first_name}
                          className="user_info_input"
                        />
                      </div>
                      <div className="my-3">
                        <MDTypography varient="p" className="general_info">
                          Last Name
                        </MDTypography>
                        <MDInput
                          value={user?.last_name}
                          className="user_info_input"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="my-3">
                        <MDTypography varient="p" className="general_info">
                          Email
                        </MDTypography>
                        <MDInput
                          value={user?.email}
                          className="user_info_input"
                        />
                      </div>
                      <div className="my-3">
                        <MDTypography varient="p" className="general_info">
                          Phone Number
                        </MDTypography>
                        <MDInput
                          value={user?.phone_number}
                          className="user_info_input"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Card>

          {user?.flight_data?.[0]?.airline && (
            <Card className="mt-4">
              <div className="airline_header row">
                <div className="airline_data col-md-4">
                  <img src={AirlineLogo} alt="airline logo" />
                  <div className="airline_title_section">
                    <MDTypography
                      variant="h6"
                      color="white"
                      className="air_line_title"
                    >
                      {user?.flight_data[0]?.airline}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="user_ticket_title"
                    >
                      Oneway Ticket
                    </MDTypography>
                  </div>
                </div>
                <div className="col-md-4">
                  <MDTypography variant="h6" className="airline_Text">
                    {user?.flight_data[0]?.total_charges}
                  </MDTypography>
                </div>
                
                <div className="col-md-4">
                  <div className="ticket_detail_heading">
                    <MDTypography variant="h6">
                      {user?.flight_data[0]?.departure_city?.split(",")[0]}-to-
                      {user?.flight_data[0]?.destination_city?.split(",")[0]}
                    </MDTypography>
                    <div className="ticket_details">
                      <MDTypography variant="p" className="ticket_title">
                        {user?.flight_data[0]?.departure_date}
                        {user?.flight_data[0]?.return_date &&
                          "/" + user?.flight_data[0]?.return_date}
                      </MDTypography>
                      <span>|</span>
                      <MDTypography variant="p" className="ticket_title">
                        1 Adult
                      </MDTypography>
                      <span>|</span>
                      <MDTypography variant="p" className="ticket_title">
                        {user?.flight_data[0]?.flight_class}
                      </MDTypography>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          )}

          {user?.hotel_data[0]?.hotel_name && (
            <Card className="mt-4">
              <div className="airline_header row">
                <div className="airline_data col-md-4">
                  <img src={HotelImg} alt="airline logo" />
                  <div className="airline_title_section">
                    <MDTypography
                      variant="h6"
                      color="white"
                      className="air_line_title"
                    >
                      {user?.hotel_data[0]?.hotel_name}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="user_ticket_title"
                    >
                      Check in: {user?.hotel_data[0]?.check_in_date}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="user_ticket_title"
                    >
                      Check out: {user?.hotel_data[0]?.check_out_date}
                    </MDTypography>
                  </div>
                </div>
                <div className="col-md-4">
                  <MDTypography variant="h6" className="airline_Text">
                    {user?.hotel_data[0]?.guest_name}
                  </MDTypography>
                  <MDTypography
                    variant="p"
                    color="white"
                    className="user_ticket_title"
                  >
                    Booking reference: {user?.hotel_data[0]?.booking_reference}
                  </MDTypography>
                </div>
                <div className="col-md-4">
                  <div className="ticket_detail_heading">
                    <MDTypography variant="h6">
                      {user?.hotel_data[0]?.rental_price}
                    </MDTypography>
                    <div className="ticket_details">
                      <MDTypography variant="p" className="ticket_title">
                        {user?.hotel_data[0]?.room_type}
                      </MDTypography>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          )}

          {user?.car_data[0]?.car_model && (
            <Card className="mt-4">
              <div className="airline_header row">
                <div className="airline_data col-md-4">
                  <img src={CarImg} alt="airline logo" />
                  <div className="airline_title_section">
                    <MDTypography
                      variant="h6"
                      color="white"
                      className="air_line_title"
                    >
                      {user?.car_data[0]?.car_model}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="user_ticket_title"
                    >
                      {`Pick up: ${user?.car_data[0]?.pickup_date}, ${
                        user?.car_data[0]?.pickup_location?.length > 20
                          ? `${user?.car_data[0]?.pickup_location?.slice(
                              0,
                              20
                            )}...`
                          : user?.car_data[0]?.pickup_location
                      }`}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="white"
                      className="user_ticket_title"
                    >
                      Drop: {user?.car_data[0]?.dropoff_date},{" "}
                      {user?.car_data[0]?.dropoff_location}
                    </MDTypography>
                  </div>
                </div>
                <div className="col-md-4">
                  <MDTypography variant="h6" className="airline_Text">
                    {user?.car_data[0]?.renter_name}
                  </MDTypography>
                  <MDTypography
                    variant="p"
                    color="white"
                    className="user_ticket_title"
                  >
                    Booking reference: {user?.car_data[0]?.booking_reference}
                  </MDTypography>
                </div>

                <div className="col-md-4">
                  <div className="ticket_detail_heading">
                    <MDTypography variant="h6">
                      {user?.car_data[0]?.rental_price}
                    </MDTypography>
                    <div className="ticket_details">
                      <MDTypography variant="p" className="ticket_title">
                        {user?.car_data[0]?.rental_company}
                      </MDTypography>
                    </div>
                    <div className="ticket_details">
                      <MDTypography
                        variant="p"
                        color="white"
                        className="user_ticket_title"
                      >
                        {user?.car_data[0]?.rental_class}
                      </MDTypography>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          )}
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default ViewUserDetail;
