export default function validator(name, value, second) {
    // Trimmed input value to remove leading/trailing whitespace
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
        const newValue = name
            .replace(/_/g, " ") // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
        return `${newValue} is required`;
    } else {
        if (name === "first_name" || name === "last_name") {
            if (trimmedValue.length < 2) {
                return "Min 2 characters required";
            } else {
                return false;
            }
        } else if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(trimmedValue)) {
                return "Invalid email address";
            } else {
                return false;
            }
        } else if (name === "password" || name === "new_password") {
            if (trimmedValue.length < 6) {
                return "Password must be at least 6 characters long";
            } else {
                return false;
            }
        } else if (name === "confirm_password") {
            if (trimmedValue !== second?.trim()) {
                return "Password does not match";
            } else {
                return false;
            }
        }
    }
}
