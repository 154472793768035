import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
import { fetchBookingsById } from "utils/api";
import MDTypography from "components/MDTypography";
import { updateBookingsById } from "utils/api";
import { toast } from "react-toastify";
import { flightBookingDetails } from "utils/api";
import { fetchFlightRoundTripSub } from "utils/api";
import { fetchFlightSingleTripSub } from "utils/api";

function UpdateBooking() {
    const params = useParams();
    let outboundCounter = 2;

    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tripdata, setTripData] = useState(false)
    // const { id } = decodeIdWithType(params.id);
    const { id } = params

    useEffect(() => {
        const route = localStorage.getItem("update-booking");
        if (route) {
            localStorage.removeItem("update-booking");
        }
    }, [])


    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const [isUpdate, setIsUpdate] = useState(false);


    const convertTo24Hour = (time12h) => {
        if (!time12h) return "";
        const [time, modifier] = time12h.split(" ");
        let [hours, minutes] = time.split(":");

        if (modifier === "PM" && hours !== "12") {
            hours = String(parseInt(hours, 10) + 12);
        }
        if (modifier === "AM" && hours === "12") {
            hours = "00";
        }

        return `${hours}:${minutes}`;
    };

    const convertTo12Hour = (time24h) => {
        if (!time24h) return "";
        let [hours, minutes] = time24h.split(":");
        let modifier = "AM";

        if (parseInt(hours, 10) >= 12) {
            modifier = "PM";
            if (hours !== "12") {
                hours = String(parseInt(hours, 10) - 12);
            }
        } else if (hours === "00") {
            hours = "12";
        }

        return `${hours}:${minutes} ${modifier}`;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "number_of_travelers") {
            const numValue = parseInt(value, 10);
            if (numValue <= 0 || isNaN(numValue)) {
                return;
            }
        }

        if (name === "price") {
            // Allow empty input (so user can delete it)
            if (value === "") {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: "",
                }));
                return;
            }

            // Prevent input with only a decimal (".", ".5" not allowed, but "1.5" is allowed)
            if (!/^\d+(\.\d*)?$/.test(value)) {
                return;
            }

            // Allow user to delete 0.0 or 0.00 completely with backspace
            if (parseFloat(value) === 0 && !value.includes(".")) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: "",
                }));
                return;
            }

            // Prevent numbers less than 1 (except allowing user to type 0 temporarily)
            const numValue = Number(value);
            if (numValue < 1 && numValue !== 0) {
                return;
            }

            // Remove leading zeros (except when user is typing "0.")
            let formattedValue = value.replace(/^0+(\d)/, "$1");

            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
            }));
            return;
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "departure_time" || name === "Arrives_time" ? convertTo12Hour(value) : value,
        }));
    };

    const handleChildInputChange = (index, e) => {
        const { name, value } = e.target;

        if (name === "number_of_travelers") {
            const numValue = parseInt(value, 10);
            if (numValue <= 0 || isNaN(numValue)) {
                return;
            }
        }

        const updatedChildren = [...formData?.children];

        updatedChildren[index] = {
            ...updatedChildren[index],
            [name]: name === "departure_time" || name === "Arrives_time" ? convertTo12Hour(value) : value,
        };

        setFormData((prevData) => ({
            ...prevData,
            children: updatedChildren,
        }));
    };

    const handleParentSubmit = (e) => {
        e.preventDefault();
        const { children, ...parentData } = formData;
        const requiredFields = [
            "airline",
            "departure_city",
            "destination_city",
            "departure_date",
            "return_date",
            "price",
            "flight_number",
            "flight_class",
            "departure_time",
            "Arrives_time",
            "number_of_travelers"
        ];

        for (const field of requiredFields) {
            const value = parentData[field];
            if (value === undefined || value === null || value === "" || (typeof value === "string" && value.trim() === "")) {
                toast.error(`Please fill in the ${field.replace("_", " ")} field.`, {
                    theme: "colored"
                });
                return;
            }
        }
        setLoading(true)
        updateBookingsById(id, parentData).then((res) => {
            setLoading(false)
            if (res.status === 200) {
                if (res?.data?.data?.update_count == 5) {
                    toast.warning(res?.data?.message)

                } else {
                    toast.success(res?.data?.message)
                    if (!tripdata) {
                        fetchFlightSingleTripSub(id);
                    } else {
                        fetchFlightRoundTripSub(id)
                    }
                }

                // if (userData?.user_type !== 2) {
                //     navigate(-1)
                // }
            } else {
                toast.error("Failed to update")
            }
        }).catch((error) => {
            console.log("error", error)
            setLoading(false)
        })
    }


    const handleChildrenSubmit = (e, index) => {
        e.preventDefault();
        const { children } = formData;
        const requiredFields = [
            "airline",
            "departure_city",
            "destination_city",
            "departure_date",
            "return_date",
            "flight_number",
            "flight_class",
            "departure_time",
            "Arrives_time",
            "number_of_travelers"
        ];

        for (const field of requiredFields) {
            const value = children[index][field];
            if (value === undefined || value === null || value === "" || (typeof value === "string" && value.trim() === "")) {
                toast.error(`Please fill in the ${field.replace("_", " ")} field.`, {
                    theme: "colored"
                });
                return;
            }
        }

        const c_id = children[index]?.id
        setLoading(true)
        updateBookingsById(c_id, children[index]).then((res) => {
            setLoading(false)
            if (res.status === 200) {
                if (!tripdata) {
                    fetchFlightSingleTripSub(id);
                } else {
                    fetchFlightRoundTripSub(id)
                }
                toast.success("Successfully Updated")
            } else {
                toast.error("Failed to update")
            }
        }).catch((error) => {
            console.log("error", error)
            setLoading(false)
        })
    };

    useEffect(() => {
        fetchAllSubstitute();
    }, []);

    const fetchAllSubstitute = () => {
        const userdata = localStorage.getItem("active-user");
        const newdata = JSON.parse(userdata)
        if (id) {
            setLoading(true)
            if (newdata?.user_type === 2) {
                fetchBookingsById(id)
                    .then((response) => {
                        setLoading(false)
                        if (response.status === 200) {
                            const { flights } = response?.data;
                            if (flights?.length > 0) {
                                setFormData(flights?.[0])
                                // const hasUpdate = flights?.[0]?.is_update || flights?.[0]?.children.some(child => child.is_update);
                                const firstReturnFlightIndex = flights?.[0]?.children?.filter(
                                    (c) => c?.is_return_flight === true
                                )
                                const returnData = firstReturnFlightIndex?.length > 0 ? true : false
                                setTripData(returnData)
                            } else {
                                navigate("/dashbaord")
                            }

                            // setIsUpdate(hasUpdate);
                        } else {
                            setLoading(false)
                            setError(
                                new Error(
                                    "Failed to fetch bookings. Status: " +
                                    response.status +
                                    ". Message: " +
                                    (response.data?.message || "No message")
                                )
                            );
                        }
                    })
                    .catch((error) => {
                        setError(error)
                        setLoading(false)
                    })
            } else {
                flightBookingDetails(id)
                    .then((response) => {
                        if (response.status === 200) {
                            setLoading(false)
                            setFormData(response?.data)
                            const firstReturnFlightIndex = response?.data?.[0]?.children?.findIndex(
                                (c) => c?.is_return_flight === true
                            )
                            setTripData(firstReturnFlightIndex)
                        } else {
                            setLoading(false)
                            navigate("/dashboard")
                            setError(
                                new Error(
                                    "Failed to fetch bookings. Status: " +
                                    response.status +
                                    ". Message: " +
                                    (response.data?.message || "No message")
                                )
                            );
                        }
                    })
                    .catch((error) => setError(error))
            }

        } else {
            setError(new Error("No flight ID provided."));
            setLoading(false);
        }
    };



    const handleBackButon = () => {
        const isExternalReferrer = document.referrer === "" || !document.referrer.includes(window.location.origin);

        if (isExternalReferrer) {
            navigate("/dashboard");
        } else {
            navigate(-1);
        }
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                {loading && (
                    <Loader />
                )}
                <>
                    <MDBox>
                        <div className="d-flex gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={() => handleBackButon()} width="24" height="24" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                            </svg>
                            <MDTypography variant="h6" className="p-title">Update Booking</MDTypography>
                        </div>

                        <div className="py-3 px-4">
                            <form className="update_booking_form">

                                <div className="row update_main_booking mb-4 mt-1">
                                    <MDTypography variant="h6" className="mt-2">Flight Details 1</MDTypography>
                                    <div className="col-md-4 my-2">
                                        <label>
                                            Airline:
                                        </label>
                                        <input
                                            type="text"
                                            name="airline"
                                            value={formData?.airline}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <label>
                                            Departure City:
                                        </label>
                                        <input
                                            type="text"
                                            name="departure_city"
                                            value={formData?.departure_city}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>

                                    <div className="col-md-4 my-2">
                                        <label>
                                            Destination City:
                                        </label>
                                        <input
                                            type="text"
                                            name="destination_city"
                                            value={formData?.destination_city}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>

                                    <div className="col-md-4 my-2">
                                        <label>
                                            Price:
                                        </label>
                                        <input
                                            type="text"
                                            name="price"
                                            value={formData?.price?.replace("$", "") || ""}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>

                                    <div className="col-md-4 my-2">
                                        <label>
                                            Departure Date:
                                        </label>
                                        <input
                                            type="date"
                                            name="departure_date"
                                            value={formData?.departure_date}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>

                                    <div className="col-md-4 my-2">
                                        <label>
                                            Arrival Date:
                                        </label>
                                        <input
                                            type="date"
                                            name="return_date"
                                            value={formData?.return_date}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        // disabled={formData?.children?.len}
                                        />
                                    </div>

                                    <div className="col-md-4 my-2">
                                        <label>
                                            Number of Travelers:
                                        </label>
                                        <input
                                            type="number"
                                            name="number_of_travelers"
                                            value={formData?.number_of_travelers}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                            min="1"
                                        />
                                    </div>

                                    <div className="col-md-4 my-2">
                                        <label>
                                            Departure Time:
                                        </label>
                                        <input
                                            type="time"
                                            name="departure_time"
                                            value={convertTo24Hour(formData?.departure_time)}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>

                                    <div className="col-md-4 my-2">
                                        <label>
                                            Arrival Time:
                                        </label>
                                        <input
                                            type="time"
                                            name="Arrives_time"
                                            value={convertTo24Hour(formData?.Arrives_time)}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <label>
                                            Flight Class:
                                        </label>
                                        <input
                                            type="text"
                                            name="flight_class"
                                            value={formData?.flight_class}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>


                                    <div className="col-md-4 my-2">
                                        <label>
                                            Flight Number:
                                        </label>
                                        <input
                                            type="text"
                                            name="flight_number"
                                            value={formData?.flight_number}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            disabled={isUpdate}
                                        />
                                    </div>



                                    <div className="col-md-12 my-2 text-end">
                                        <button type="button" onClick={(e) => handleParentSubmit(e)} className="update_booking_submit">
                                            Save Flight
                                        </button>
                                    </div>


                                </div>

                                {formData?.children?.map((child, index) => {
                                    if (child?.is_return_flight === false) {
                                        return (
                                            <div className="row update_main_booking my-4">
                                                <MDTypography variant="h6" className="text-xl mt-3 font-bold">Outbound Flight {outboundCounter++}</MDTypography>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Airline:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="airline"
                                                        value={child?.airline}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>
                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Departure City:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="departure_city"
                                                        value={child?.departure_city}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Destination City:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="destination_city"
                                                        value={child?.destination_city}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Flight Number:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="flight_number"
                                                        value={child?.flight_number}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Departure Date:
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="departure_date"
                                                        value={child?.departure_date}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Arrival Date:
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="return_date"
                                                        value={child?.return_date}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                {/* <div className="col-md-4 my-2">
                                                        <label>
                                                            Price:
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="price"
                                                            value={child?.price}
                                                            onChange={(e) => handleChildInputChange(index, e)}
                                                            className="w-full p-2 border rounded"
                                                            disabled={isUpdate}
                                                        />
                                                    </div> */}



                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Flight Class:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="flight_class"
                                                        value={child?.flight_class}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Departure Time:
                                                    </label>
                                                    <input
                                                        type="time"
                                                        name="departure_time"
                                                        value={convertTo24Hour(child?.departure_time)}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Arrival Time:
                                                    </label>
                                                    <input
                                                        type="time"
                                                        name="Arrives_time"
                                                        value={convertTo24Hour(child?.Arrives_time)}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Number of Travelers:
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="number_of_travelers"
                                                        value={child?.number_of_travelers}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                        min="1"
                                                    />
                                                </div>

                                                <div className="col-md-12 my-2 text-end">
                                                    <button type="button" onClick={(e) => handleChildrenSubmit(e, index)} className="update_booking_submit">
                                                        Save Flight
                                                    </button>
                                                </div>

                                            </div>
                                        )
                                    }

                                })}

                                {formData?.children?.map((child, index) => {
                                    if (child?.is_return_flight === true) {
                                        return (
                                            <div className="row update_main_booking my-4">
                                                <MDTypography variant="h6" className="text-xl mt-3 font-bold">Return Flight {outboundCounter++}</MDTypography>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Airline:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="airline"
                                                        value={child?.airline}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>
                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Departure City:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="departure_city"
                                                        value={child?.departure_city}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Destination City:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="destination_city"
                                                        value={child?.destination_city}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Flight Number:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="flight_number"
                                                        value={child?.flight_number}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Departure Date:
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="departure_date"
                                                        value={child?.departure_date}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Arrival Date:
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="return_date"
                                                        value={child?.return_date}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                {/* <div className="col-md-4 my-2">
                                                        <label>
                                                            Price:
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="price"
                                                            value={child?.price}
                                                            onChange={(e) => handleChildInputChange(index, e)}
                                                            className="w-full p-2 border rounded"
                                                            disabled={isUpdate}
                                                        />
                                                    </div> */}



                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Flight Class:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="flight_class"
                                                        value={child?.flight_class}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Departure Time:
                                                    </label>
                                                    <input
                                                        type="time"
                                                        name="departure_time"
                                                        value={convertTo24Hour(child?.departure_time)}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Arrival Time:
                                                    </label>
                                                    <input
                                                        type="time"
                                                        name="Arrives_time"
                                                        value={convertTo24Hour(child?.Arrives_time)}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                    />
                                                </div>

                                                <div className="col-md-4 my-2">
                                                    <label>
                                                        Number of Travelers:
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="number_of_travelers"
                                                        value={child?.number_of_travelers}
                                                        onChange={(e) => handleChildInputChange(index, e)}
                                                        className="w-full p-2 border rounded"
                                                        disabled={isUpdate}
                                                        min="1"
                                                    />
                                                </div>

                                                <div className="col-md-12 my-2 text-end">
                                                    <button type="button" onClick={(e) => handleChildrenSubmit(e, index)} className="update_booking_submit">
                                                        Save Flight
                                                    </button>
                                                </div>

                                            </div>
                                        )
                                    }

                                })}

                                {/* {
                                        formData?.children?.length > 0 &&
                                        <div className="text-end">
                                            <button type="button" onClick={(e) => handleSubmit(e)} className="update_booking_submit">
                                                Update Flight
                                            </button>
                                        </div>
                                    } */}

                            </form>
                        </div>
                    </MDBox>
                </>

            </MDBox>
        </DashboardLayout>
    );
}

export default UpdateBooking;
