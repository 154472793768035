import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Projects from "layouts/dashboard/components/Projects";
import TopChartBox from "./components/TopChartBox";
import { useEffect, useState } from "react";
import Loader from "components/Loader";
import { getPieData } from "utils/api";
import { getUser } from "utils/userApi";
import { Form } from "react-bootstrap";
import { getHotelBookingCount } from "utils/api";
import HotelData from "./components/Projects/hoteldata";
import UserSatusdetail from "./components/userStatus";
import CarData from "./components/Projects/carData";

function Dashboard() {
  const names = ["Flight Bookings", "Car Bookings", "Hotel Bookings"];
  const [loading, setLoading] = useState(false)
  const [personName, setPersonName] = useState("Flight Bookings");
  const [users, setUsers] = useState([]);
  const [hotelData, setHotelData] = useState({
    data: { accept: 0, reject: 0, eliminate: 0 },
    eliminate: {
      time_difference: 0,
      unacceptable: 0,
      no_downgrade: 0,
      other: 0
    },
    reject: {
      waiting: 0,
      policy_restriction: 0,
      no_downgrade: 0,
      other: 0,
    }
  })
  const [pieData, setPieData] = useState({
    data: { accept: 0, reject: 0, eliminate: 0 },
    eliminate: {
      time_difference: 0,
      unacceptable: 0,
      no_downgrade: 0,
      other: 0
    },
    reject: {
      waiting: 0,
      policy_restriction: 0,
      no_downgrade: 0,
      other: 0,
    }
  });

  const [carData, setCarData] = useState(null)

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    setPersonName(selectedStatus);
  };


  useEffect(() => {
    fetchData();
    fetchUsers()
    getHotelCount()
  }, [])

  const fetchData = async () => {
    let res = await getPieData();
    if (res && res.data) {
      if (res?.data?.rejected) {
        setPieData({
          data: {
            accept: res?.data?.accepted,
            reject: res?.data?.rejected?.count,
            eliminate: res?.data?.eliminated?.count
          },
          reject: res?.data?.rejected?.details,
          eliminate: res?.data?.eliminated?.details,
        });
      } else {
        setPieData(null)
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const getHotelCount = () => {
    getHotelBookingCount().then((res) => {
      console.log("getHotelBookingCount", res)
      if (res && res.data) {
        if (res?.data?.rejected) {
          setHotelData({
            data: {
              accept: res?.data?.accepted,
              reject: res?.data?.rejected?.count,
              eliminate: res?.data?.eliminated?.count
            },
            reject: res?.data?.rejected?.details,
            eliminate: res?.data?.eliminated?.details,
          });
        } else {
          setHotelData(null)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const fetchUsers = () => {
    getUser()
      .then((response) => {
        if (response?.status == 200) {
          setUsers(response?.data);
        } else {
          setUsers(null);
        }
      })
      .catch((error) => {
        setUsers(null);
        console.error("Error fetching users:", error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        loading ?
          <>
            <Loader />
          </>
          :
          <MDBox py={3}>
            <div className="d-flex justify-content-end my-3">
              <Form.Group className="status-select-container mx-2" style={{width:"170px"}} controlId="status-select">
                <Form.Select
                  value={personName}
                  onChange={handleChange}
                  className="custom-status-select "
                >
                  {/* <option value="">{personName}</option> Placeholder option */}
                  {names.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            {
              personName == "Flight Bookings" &&
              <div>
                <UserSatusdetail users={users} pieData={pieData} />
                <MDBox mt={4.5}>
                  <Grid container spacing={3}>
                    {
                      pieData &&
                      <TopChartBox loading={loading} pieData={pieData} setLoading={setLoading} />
                    }
                  </Grid>
                </MDBox>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Projects user={users} />
                    </Grid>
                  </Grid>
                </MDBox>
              </div>
            }

            {
              personName == "Hotel Bookings" &&
              <div>
                <UserSatusdetail pieData={hotelData} users={users} />
                <MDBox mt={4.5}>
                  <Grid container spacing={3}>
                    {
                      hotelData &&
                      <TopChartBox loading={loading} pieData={hotelData} setLoading={setLoading} />
                    }
                  </Grid>
                </MDBox>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <HotelData user={users} />
                    </Grid>
                  </Grid>
                </MDBox>
              </div>
            }

            {
              personName == "Car Bookings" &&
              <div>
                <UserSatusdetail pieData={carData} users={users} />
                <MDBox mt={4.5}>
                  <Grid container spacing={3}>
                    {
                      carData &&
                      <TopChartBox loading={loading} pieData={carData} setLoading={setLoading} />
                    }
                  </Grid>
                </MDBox>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <CarData user={users} />
                    </Grid>
                  </Grid>
                </MDBox>
              </div>
            }
          </MDBox>
      }
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
