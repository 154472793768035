import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { userPaidAmounts } from "utils/api";
import MDTypography from "components/MDTypography";


function PendingHistory() {
    const [users, setUsers] = useState([]);

    const fetchAmount = () => {
        userPaidAmounts().then((res) => {
            if (res.status == 200) {
                setUsers(res?.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchAmount()
    }, []);


    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            textAlign: "left",
                        },
                        head: {
                            fontWeight: "bold",
                            textAlign: "left",
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: "first_name",
            label: "First Name",
            options: {
                setCellProps: () => ({ style: { textAlign: "left" } }),
                setCellHeaderProps: () => ({ style: { textAlign: "left" } })
            }
        },
        {
            name: "last_name",
            label: "Last Name",
            options: {
                setCellProps: () => ({ style: { textAlign: "left" } }),
                setCellHeaderProps: () => ({ style: { textAlign: "left" } })
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                setCellProps: () => ({ style: { textAlign: "left" } }),
                setCellHeaderProps: () => ({ style: { textAlign: "left" } })
            }
        },
        {
            name: "previouse_amount",
            label: "Original Booking($)",
            options: {
                setCellProps: () => ({ style: { textAlign: "left" } }),
                setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
                customBodyRender: (value) => (value ? `$${value?.replace("$", "")}` : "N/A"),
            }
        },
        {
            name: "booking_amount",
            label: "Rebooking",
            options: {
                setCellProps: () => ({ style: { textAlign: "left" } }),
                setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
                customBodyRender: (value) => (value ? `$${value?.replace("$", "")}` : "N/A"),
            }
        },
        {
            name: "saved_amount",
            label: "Saving Amount",
            options: {
                setCellProps: () => ({ style: { textAlign: "left" } }),
                setCellHeaderProps: () => ({ style: { textAlign: "left" } })
            }
        },
        {
            name: "saved_amount",
            label: "Paid Amount",
            options: {
                setCellProps: () => ({ style: { textAlign: "left" } }),
                setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
                customBodyRender: (value) => (value ? `$${(value?.replace("$", "") * 0.1).toFixed(2)}` : "N/A"),
            }
        },
    ];

    const options = {
        selectableRows: "none",
        responsive: "standard",
        print: false,
        download: false,
        viewColumns: false,
    };

    // Transform user data to include payments
    const transformedData = users?.map(user => ({
            first_name: user.user.first_name,
            last_name: user.user.last_name,
            email: user.user.email,
            previouse_amount: user.previouse_amount,
            booking_amount: user.booking_amount,
            saved_amount: user.saved_amount,
        }))
    



    return (
        <div className="table-container">
            <div className="custom-table">
                {users?.length > 0 ? (
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            data={transformedData}
                            columns={columns}
                            options={options}
                            className="user_data_table"
                        />
                    </ThemeProvider>
                )
            :
            <>
            <div className="d-flex justify-content-center mt-4 ">
                <MDTypography variant="h5">
                    No Pending Amount History Found
                </MDTypography>
            </div>
            </>
            }
            </div>
        </div>
    );
}

export default PendingHistory;
