import React, { useEffect, useState } from "react";
import "./SubstituteTicket.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MDTypography from "components/MDTypography";
import { updateSubstituteStatus } from "utils/api";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import {
  Button,
  Pagination,
  TextField,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import airlineName from "utils/airlinename.json"
import FlightDetailModal from "components/FlightDetailModal";
import { layoversFlight } from "utils/api";
import FlightSubstitute from "components/FlightDetails/flightSubstitute";
import StatusButtons from "components/FlightDetails/statusbutton";
import PriceDetails from "components/FlightDetails/priceDetails";
import { useNavigate } from "react-router-dom";
import { createFlightPair } from "utils/api";
import moment from "moment";
import { updateFlightPair } from "utils/api";
import NoAccessModal from "components/NoAccessModal";

const SubstituteTicket = ({
  substitutes,
  loading,
  setLoading,
  fetchAllSubstitute,
  passBooking,
  details,
  same_flight_sub,
  retData
}) => {


  const navigate = useNavigate()
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [return_id, setReturnId] = React.useState(null);
  const [flightshow, setFlightShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [acceptData, setAcceptData] = React.useState(null)
  const [numStops, setNumStops] = React.useState(null)
  const [selectedReason, setSelectedReason] = React.useState("");
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");
  const [siteUrl, setSiteUrl] = React.useState("")
  const [layoverPrice, setLayoverPrice] = React.useState(null)
  const [substituteId, setSubstituteId] = useState("")
  const [layoverData, setLayoverData] = React.useState(null)
  const [currentUpdate, setCurrentUpdate] = React.useState(false)
  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case "ELIMINATE":
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["Downgrade", "I don’t want downgrade"],
          ["OTHER", "Other"],
        ];
      case "REJECT":
        return [
          ["WAITING", "Waiting"],
          ["LAYOVER", "Layover"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          // ["Downgrade", "I don’t want downgrade"],
          ["OTHER", "Other"],
        ];
      default:
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["WAITING", "Waiting"],
          ["POLICY_RESTRICTION", "Policy Restriction"],
          ["OTHER", "Other"],
        ];
    }
  }, [status]);
  const [pageData, setPageData] = useState([]);
  const [bookingType, setBookingType] = useState("");
  const [data, setData] = useState([])
  const [subId, setSubId] = useState(null)
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const sortOptions = [
    "Price: Low to High",
    "Price: High to Low",
    "Airline Name",
    "Early time",
    "Duration"
  ];

  const [selectedSort, setSelectedSort] = useState("");

  const getAirlineName = (airlineCode) => {
    const airline = airlineName?.find(item => item?.id === airlineCode);
    return airline ? airline?.name : airlineCode;
  };



  useEffect(() => {
    const filteredData = substitutes?.length > 0
      ? substitutes.filter((item) => item.outbound_offer.status !== "ELIMINATE")
      : [];

    let sortedData = [...filteredData];

    if (selectedSort) {
      sortedData = sortData(sortedData, selectedSort);
    }

    setPageData(sortedData);
  }, [substitutes, fetchAllSubstitute, retData, selectedSort]);

  const handleBookingType = (event) => {
    const selectedOption = event.target.value;
    setSelectedSort(selectedOption);
    setBookingType(selectedOption);
  };

  const sortData = (data, type) => {
    const sortedData = [...data];

    if (type === "Price: Low to High") {
      sortedData.sort((a, b) => a.outbound_offer.price - b.outbound_offer.price);
    } else if (type === "Price: High to Low") {
      sortedData.sort((a, b) => b.outbound_offer.price - a.outbound_offer.price);
    } else if (type === "Airline Name") {
      sortedData.sort((a, b) =>
        getAirlineName(a.outbound_offer.airline_code).localeCompare(getAirlineName(b.outbound_offer.airline_code))
      );
    } else if (type === "Early time") {
      sortedData.sort((a, b) => {
        const timeA = parseTime(a.outbound_offer.departure_time);
        const timeB = parseTime(b.outbound_offer.departure_time);
        return timeA - timeB;
      });
    } else if (type === "Duration") {
      sortedData.sort((a, b) => {
        return convertToMilliseconds(a.duration) - convertToMilliseconds(b.duration);
      });
    }

    return sortedData;
  };

  const convertToMilliseconds = (duration) => {
    const match = duration.match(/(\d+)h\s*(\d+)m/); // Extract hours and minutes
  
    if (!match) return 0; // Return 0 if no match found
  
    const hours = parseInt(match[1], 10) || 0;
    const minutes = parseInt(match[2], 10) || 0;
  
    return (hours * 3600000) + (minutes * 60000); // Convert to ms
  };

  const formatDuration = (duration) => {
    const hoursMatch = duration?.match(/(\d+)H/);
    const minutesMatch = duration?.match(/(\d+)M/);

    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    return hours * 60 + minutes; // Convert total duration into minutes
  };

  const parseTime = (timeString) => {
    const [date, time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return new Date(2025, 1, 5, hours, parseInt(minutes, 10));
  };


  const handleAcceptSite = () => {
    window.open(siteUrl, '_blank')
    setShow(false)
  }

  const pageCount = Math.ceil(pageData?.length / itemsPerPage);

  const displayedItems = pageData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== "OTHER") {
      setOtherReasonText("");
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectMesage("")
    setSelectedReason("")
    setOtherReasonText("")
  };

  const flightHandleClose = () => {
    setFlightShow(false);
    setData([])
  };



  const handleSubstituteDetail = (id, num, price, data, layover) => {
    setData(data)
    setNumStops(num)
    setFlightShow(true)
    setLayoverPrice(price)
    setLayoverData(layover)
    setSubId(id)
  }

  const handleStatus = (id, status, rid, url, data, item, current) => {
    setShow(id);
    setStatus(status);
    setSiteUrl(url)
    setReturnId(rid)
    setSubstituteId(id)
    setCurrentUpdate(current)
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let confirCode = "AI";

    for (let i = 0; i < 4; i++) {
      confirCode += letters[Math.floor(Math.random() * letters.length)];
    }
    const storedUser = localStorage.getItem("active-user");
    const user = JSON.parse(storedUser);
    if ((status === "ACCEPT" || status === "REINSTATE")) {
      if (current === "current") {
        setShow(true)
        const currtPrice = same_flight_sub?.total_price
        setAcceptData({
          "id": item?.[0]?.id,
          "price": currtPrice
        })
      } else {
        layoversFlight(id).then((res) => {
          const segment = res?.data?.[0]?.offer_data?.itineraries
          const offer_data = res?.data?.[0]?.offer_data
          const singleSegment = segment?.[0]?.segments?.[0]

          const childFlights = segment?.flatMap((flight, i) =>
            flight.segments
              .filter((_, index) => !(i === 0 && index === 0)) // Remove only the first segment from data[0]
              .map((segment) => ({
                message_id: user?.id,
                sender_email: user?.email,
                confirmation_code: confirCode,
                airline: segment?.carrierCode,
                departure_city: segment?.departure?.iataCode,
                destination_city: segment?.arrival?.iataCode,
                departure_date: segment?.departure?.at?.split("T")?.[0],
                return_date: segment?.arrival?.at?.split("T")?.[0],
                price: offer_data?.price?.total,
                flight_number: `${segment?.carrierCode}${segment?.number}`,
                flight_class: offer_data?.travelerPricings?.[0]?.fareDetailsBySegment?.[0]?.cabin,
                departure_time: moment(segment?.departure?.at?.split("T")?.[1], "HH:mm:ss").format("h:mm A"),
                Arrives_time: moment(segment?.arrival?.at?.split("T")?.[1], "HH:mm:ss").format("h:mm A"),
                is_return_flight: i === 0 ? false : true, // First flight segments get false, return flight segments get true
                is_show_data: true,
                number_of_travelers: details?.[0]?.number_of_travelers,
              }))
          );

          if(rid === true || rid === "true"){
            setAcceptData({
              "id": details?.[0]?.id,
              "user": user?.id,
              "Flight_substitute": data?.id,
              "saved_amount": data?.saved_amount,
              "booking_amount": data?.price,
              "previouse_amount": details?.[0]?.price,
              "parent_flight": {
                "message_id": user?.id,
                "sender_email": user?.email,
                "confirmation_code": confirCode,
                "airline": getAirlineName(data?.airline_code),
                "departure_city": data?.departure_iata_code,
                "destination_city": data?.arrival_iata_code,
                "departure_date": data?.departure_time?.split(" ")?.[0],
                "return_date": data?.arrival_time?.split(" ")?.[0],
                "price": data?.price,
                "flight_number": `${data?.airline_code}${data?.flight_number}`,
                "flight_class": data?.travel_class,
                "departure_time": `${data?.departure_time?.split(" ")?.[1]} ${data?.departure_time?.split(" ")?.[2]}`,
                "Arrives_time": `${data?.arrival_time?.split(" ")?.[1]} ${data?.arrival_time?.split(" ")?.[2]}`,
                "number_of_travelers": details?.[0]?.number_of_travelers,
                "is_return_flight": false,
                "is_show_data": true
              },
              "child_flight": childFlights
            })
          }else{
            setAcceptData({
              "id": details?.[0]?.id,
              "user": user?.id,
              "Flight_substitute": data?.id,
              "saved_amount": data?.saved_amount,
              "booking_amount": data?.price,
              "previouse_amount": details?.[0]?.price,
              "parent_flight": {
                "message_id": user?.id,
                "sender_email": user?.email,
                "confirmation_code": confirCode,
                "airline": getAirlineName(data?.airline_code),
                "departure_city": data?.departure_iata_code,
                "destination_city": singleSegment?.arrival?.iataCode,
                "departure_date": data?.departure_time?.split(" ")?.[0],
                "return_date": singleSegment?.arrival?.at?.split("T")?.[0],
                "price": data?.price,
                "flight_number": `${data?.airline_code}${data?.flight_number}`,
                "flight_class": data?.travel_class,
                "departure_time": `${data?.departure_time?.split(" ")?.[1]} ${data?.departure_time?.split(" ")?.[2]}`,
                "Arrives_time": moment(singleSegment?.arrival?.at?.split("T")?.[1], "HH:mm:ss").format("h:mm A"),
                "number_of_travelers": details?.[0]?.number_of_travelers,
                "is_return_flight": false,
                "is_show_data": true
              },
              "child_flight": childFlights
            })
          }
         

        }).catch((error) => {
          console.log(error)
        })
      }

    }
  };

  const handleUpdateStatus = () => {
    
      if (!selectedReason && status !== "ACCEPT" && status !== "REINSTATE") {
        setSelectMesage("Please select any reason");
      } else if (selectedReason === "OTHER" && !otherReasonText.trim()) {
        setSelectMesage("Please provide a reason in the text field");
      } else {
        const tickeId = return_id ? { id: show, r_id: return_id } : { id: show }
        if ((status === "ACCEPT" || status === "REINSTATE") && acceptData?.child_flight?.length > 0) {
          setLoading(true)
          if (currentUpdate === "current") {
  
            updateFlightPair(acceptData).then((res) => {
  
              if (res?.status === 200 || res?.status === 201) {
                setLoading(false)
                setShow(false)
                // setPaymentShow(true)
                navigate("/dashboard")
                toast.success(
                  `Booking accepted
                   successfully`,
                  {
                    theme: "colored",
                  }
                );
                setStatus(null)
                // fetchAllSubstitute();
              } else {
                setLoading(false)
                setShow(false)
                toast.error(`Failed to accept status`, {
                  theme: "colored",
                });
              }
            }).catch((error) => {
              console.log(error)
              setLoading(false)
              setShow(false)
              toast.error(`Failed to accept status`, {
                theme: "colored",
              });
            })
          } else {
            createFlightPair(acceptData).then((res) => {
              if (res?.status === 200 || res?.status === 201) {
                setShow(false)
                setLoading(false)
                setPaymentShow(true)
                // navigate("/dashboard")
                toast.success(
                  `Booking accepted successfully`,
                  {
                    theme: "colored",
                  }
                );
                setStatus(null)
                // fetchAllSubstitute();
              } else {
                setLoading(false)
                setShow(false)
                toast.error(`Failed to accept status`, {
                  theme: "colored",
                });
              }
            }).catch((error) => {
              console.log(error)
              setLoading(false)
              setShow(false)
              toast.error(`Failed to accept status`, {
                theme: "colored",
              });
            })
          }
  
        } else {
          setLoading(true)
          updateSubstituteStatus(substituteId, {
            status: status === "REINSTATE" ? "ACCEPT" : status,
            additional_information: selectedReason,
            other_reason_text: selectedReason === "OTHER" ? otherReasonText : null,
          })
            .then((res) => {
              setLoading(false)
              if (res.status === 200) {
                setShow(false)
                if (status === "ACCEPT" || status === "REINSTATE") {
                  setPaymentShow(true)
                } else {
                  fetchAllSubstitute();
                }
                toast.success(`Status ${status === "ELIMINATE"
                  ? `${status?.toLowerCase()}d`
                  : `${status?.toLowerCase()}ed`
                  } successfully`, {
                  theme: "colored",
                });
                setSelectMesage("")
                setSelectedReason("")
                setOtherReasonText("")
  
                // fetchAllSubstitute();
              } else {
                toast.error(`Failed to ${status?.toLowerCase()} status`, {
                  theme: "colored",
                });
                setLoading(false)
              }
            })
            .catch((error) => {
              setLoading(false)
              console.log(error);
            });
        }
  
      }
    
  };

  const getSaving = (item_price, ticket_price) => {
    const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (itemPrice - ticketPrice).toFixed(2);
    return parseFloat(savings);
  };

  const getTime = (departure, arrival) => {
    const departureDate = new Date(departure);
    const arrivalDate = new Date(arrival);

    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}hr ${minutes}min`;
  };


  const handleClear = () => {
    setPageData(substitutes)
    setBookingType("")
  }

  return (
    <>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <MDTypography variant="p" className="p-title">
          Highest Scoring Matches
        </MDTypography>

        <div className="d-flex gap-2">
          <Form.Group className="status-select-container" controlId="sort-by-select">
            <Form.Select
              value={bookingType}
              onChange={handleBookingType}
              className="custom-status-select"
            >
              <option value="">Sort By</option> {/* Placeholder */}
              {sortOptions?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <button className="clear_butn" onClick={() => handleClear()}>Clear</button>
        </div>

      </div>
      {loading && (
        <>
          <h5>Loading ..</h5>
        </>
      )}

      <>

        {((details?.[0]?.children?.length + 1) === same_flight_sub?.flight_offers?.length) &&
          <div
            className="container-fluid p-3 mb-3 subtiktmain position-relative"
          >
            <button className="current_butn">Current</button>
            <div
              className="row align-items-center"
              style={{ alignItems: "center" }}
            >
              <div className="col-md-6 col-lg-8">
                {
                  same_flight_sub?.flight_offers?.length > 0 ?
                    same_flight_sub?.flight_offers?.map((same, index) => {
                      const returnFlightCount = details?.[0]?.children?.filter(child => child?.is_return_flight === true)?.length || 0;
                      const targetIndex = same_flight_sub?.flight_offers?.length - returnFlightCount;

                      return (
                        <>
                          {index === targetIndex &&
                            <div className="position-relative">
                              <div className="children_data_same"></div>
                              <span className="return_booking_same">Return flight</span>
                            </div>
                          }
                          <FlightSubstitute childrenLen={returnFlightCount} itemLength={same_flight_sub?.flight_offers?.length - 1} index={index}
                            returnSub={"returnSub"} item={details} ticket={same} getTime={getTime}
                            handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                            ticketStatuses={ticketStatuses} />
                        </>
                      )
                    })
                    :
                    <></>
                }
              </div>
              <PriceDetails current={"current"} item={details} ticket={same_flight_sub} getTime={getTime} />
              {/* <CurrentStatus samePrice={"samePrice"} returnID={details?.[0]?.children?.length > 0 ? true : false} ticketStatuses={ticketStatuses} item={details} ticket={same_flight_sub} handleStatus={handleStatus} /> */}
            </div>
          </div>
        }
        {substitutes?.length > 0 &&
          displayedItems?.length > 0 &&
          displayedItems?.map((ticket, index) => {
            const firstReturnFlightIndex = details?.[0]?.children?.findIndex(c => c?.is_return_flight === true);
            const retSubstitues = details?.[0]?.children?.length > 0 && firstReturnFlightIndex >= 0 ? true : false
            return (
              <div
                key={index}
                className="container-fluid p-3 mb-3 subtiktmain position-relative"
              >
                <div className="high_matches">
                  <p className="mb-0"> <strong className="match_percent">{ticket?.outbound_offer?.match_percentage} Score</strong> </p>
                </div>
                <div
                  className="row align-items-center"
                  style={{ alignItems: "center", marginTop: "30px" }}
                >
                  {
                    ticket?.return_offer ?
                      <div className="col-md-6 col-lg-7">
                        <FlightSubstitute item={ticket} ticket={ticket?.outbound_offer} getTime={getTime}
                          handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                          ticketStatuses={ticketStatuses} index={index} />

                        <FlightSubstitute item={ticket} ticket={ticket?.return_offer} getTime={getTime}
                          handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                          ticketStatuses={ticketStatuses} index={index} />

                      </div>
                      :
                      <div className="col-md-6 col-lg-7">
                        <FlightSubstitute item={ticket} ticket={ticket?.outbound_offer} getTime={getTime}
                          handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                          ticketStatuses={ticketStatuses} index={index} />
                      </div>
                  }
                  <PriceDetails item={ticket} ticket={ticket?.return_offer ? ticket : ticket?.outbound_offer} getTime={getTime}
                    handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                    ticketStatuses={ticketStatuses} index={index} />
                  <StatusButtons returnID={retSubstitues} ticketStatuses={ticketStatuses} index={index} item={ticket} ticket={ticket?.outbound_offer} handleStatus={handleStatus} />
                </div>
              </div>
            )
          })}

        {/* {
  retData?.length > 0 && displayedItems?.length > 0 ? (
    (() => {
      const groupedFlights = displayedItems.reduce((acc, flight) => {
        acc[flight.total_price] = acc[flight.total_price] || { outbound: null, return: null };
        acc[flight.total_price][flight.flight_type] = flight;
        return acc;
      }, {});

      const filteredFlights = Object.values(groupedFlights).filter(pair => pair.outbound && pair.return);

      return filteredFlights?.map((pair, index) => (
        <div
          key={index}
          className="container-fluid p-3 mb-3 subtiktmain"
        >
          <div
            className="row align-items-center"
            style={{ alignItems: "center" }}
          >
            <div className="col-md-6 col-lg-7">
              <ReturnSubstitute ticket={pair?.outbound} item={pair} index={index} handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                ticketStatuses={ticketStatuses} />
              <ReturnSubstitute ticket={pair?.return} item={pair} index={index} handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
                ticketStatuses={ticketStatuses} />
            </div>
            <PriceDetails retprice={"return_price"} item={pair} ticket={pair?.outbound} getTime={getTime}
              handleSubstituteDetail={handleSubstituteDetail} handleStatus={handleStatus}
              ticketStatuses={ticketStatuses} index={index} />
            <StatusButtons ticketStatuses={ticketStatuses} index={index} item={pair} ticket={pair?.outbound_offer} handleStatus={handleStatus} />
          </div>
        </div>
      ));
    })()
  ) : null} */}


      </>

      <Pagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
      />

      {
        paymentShow && (
          <NoAccessModal navidash={"navidash"} show={true} setShow={setPaymentShow} />
        )
      }


      <FlightDetailModal layoverData={layoverData} layoverPrice={layoverPrice} numStops={numStops} data={data} subId={subId} setSubId={setSubId} flightshow={flightshow} setFlightShow={setFlightShow} handleClose={flightHandleClose} />

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>

            {status === "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status === "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Please let us know why you are Rejecting this offer.
              </MDTypography>
            }

            {status === "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }
            {
              status === "REINSTATE" && (
                <MDTypography variant="p" className="delete_msj">
                  Are you sure? Would you like to reinstate this flight?
                </MDTypography>
              )
            }

            {status !== "ACCEPT" && status !== "REINSTATE" && (
              <>

                <div className="my-3 text-left">
                  <Form.Group className="select_reason_input" controlId="reason-select">
                    {/* <Form.Label>Select Reason</Form.Label> */}
                    <Form.Select value={selectedReason} onChange={handleChange} className="custom-select">
                      <option value="">Select Reason</option> {/* Placeholder option */}
                      {REASON_CHOICES?.map((reason) => (
                        <option key={reason[0]} value={reason[0]}>
                          {reason[1]}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  {selectedReason === "OTHER" && (
                    <TextField
                      fullWidth
                      label="Please specify the reason"
                      variant="outlined"
                      value={otherReasonText}
                      onChange={(e) => {
                        setOtherReasonText(e.target.value);
                        setSelectMesage("");
                      }}
                      className="other_reason_input mt-3"
                      multiline
                      rows={3}
                    />
                  )}
                  <div className="error_message my-1">{selectMessage}</div>
                </div></>

            )}
            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              {status === "ACCEPT" ? "No" : "Cancel"}
            </Button>
            {
              status === "ACCEPT" ?
                <Button
                  variant="primary"
                  className="delete_butn"
                  type="button"
                  onClick={() => handleUpdateStatus()}
                >
                  Yes
                </Button>
                :
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                > Update
                </Button>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubstituteTicket;
