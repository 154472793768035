import React from 'react'
import { Navigate } from 'react-router-dom';

const SuperAuth = ({ children }) => {
    const token = localStorage.getItem("user_access_token");
    const user = JSON.parse(localStorage.getItem("active-user"));


    return (token && (user?.user_type == 1)) ? (
        children
    ) : (
        <Navigate to={"/login"} />
    )
}

export default SuperAuth