import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
import { decodeIdWithType } from "utils/encoder";
import { fetchBookingsById } from "utils/api";
import MDTypography from "components/MDTypography";
import { toast } from "react-toastify";
import { updateHotelBookingsById } from "utils/api";
import { getProfile } from "utils/api";
import { HotelBookingDetails } from "utils/api";
import { flightAmadeusHotels } from "utils/api";

function UpdateHotel() {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    // const { id } = decodeIdWithType(params.id);
    const { id } = params
    const navigate = useNavigate()

    const convertTo12Hour = (time24h) => {
        if (!time24h) return "";
        let [hours, minutes] = time24h.split(":");
        let modifier = "AM";

        if (parseInt(hours, 10) >= 12) {
            modifier = "PM";
            if (hours !== "12") {
                hours = String(parseInt(hours, 10) - 12);
            }
        } else if (hours === "00") {
            hours = "12";
        }

        return `${hours}:${minutes} ${modifier}`;
    };

    const [formData, setFormData] = useState([]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "number_of_travelers") {
            const numValue = parseInt(value, 10);
            if (numValue <= 0 || isNaN(numValue)) {
                return;
            }
        }

        if (name === "rental_price") {
            if (value === "") {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: "",
                }));
                return;
            }

            if (!/^\d+(\.\d*)?$/.test(value)) {
                return;
            }

            if (parseFloat(value) === 0 && !value.includes(".")) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: "",
                }));
                return;
            }

            const numValue = Number(value);
            if (numValue < 1 && numValue !== 0) {
                return;
            }

            let formattedValue = value.replace(/^0+(\d)/, "$1");

            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
            }));
            return;
        }


        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "departure_time" || name === "Arrives_time" ? convertTo12Hour(value) : value,
        }));
    };


    const handleParentSubmit = (e) => {
        e.preventDefault();
        const requiredFields = [
            "hotel_name",
            "check_in_date",
            "check_out_date",
            "rental_price",
            "room_type",
            "booking_reference"
        ];

        for (const field of requiredFields) {
            const value = formData[field];
            if (value === undefined || value === null || value === "" || (typeof value === "string" && value.trim() === "")) {
                toast.error(`Please fill in the ${field.replace("_", " ")} field.`, {
                    theme: "colored"
                });
                return;
            }
        }
        setLoading(true)
        updateHotelBookingsById(id, formData).then((res) => {
            setLoading(false)
            if (res.status == 200) {
                toast.success("Successfully Updated")
                navigate(-1);
                flightAmadeusHotels(id)
            } else {
                toast.error("Failed to update")
            }
        }).catch((error) => {
            console.log("error", error)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchAllSubstitute();
    }, [id]);

    const fetchAllSubstitute = () => {
        const userdata = localStorage.getItem("active-user");
        const newdata = JSON.parse(userdata)
        if (id) {
            setLoading(true);
            if (newdata?.user_type == 2) {
                fetchBookingsById(id)
                    .then((response) => {
                        if (response.status === 200) {
                            const { hotels } = response?.data;
                            setFormData(hotels?.[0])
                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => setLoading(false));
            } else {
                HotelBookingDetails(id)
                    .then((response) => {
                        if (response.status === 200) {
                            setFormData(response?.data)
                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => setLoading(false));
            }
        } else {
            console.log(new Error("No flight ID provided."));
            setLoading(false);
        }
    };

    const handleBackButon = () => {
        navigate(-1)
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <MDBox>
                            <div className="d-flex gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={() => handleBackButon()} width="24" height="24" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                                </svg>
                                <MDTypography variant="h6" className="p-title">Update Hotel Booking</MDTypography>
                            </div>

                            <div className="py-3 px-4">
                                <form className="update_booking_form">

                                    <div className="row update_main_booking my-4">
                                        <MDTypography variant="h6" className="mt-2">Hotel Details</MDTypography>
                                        <div className="col-md-4 my-2">
                                            <label>
                                                Hotel Name:
                                            </label>
                                            <input
                                                type="text"
                                                name="hotel_name"
                                                value={formData?.hotel_name}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Check in Date:
                                            </label>
                                            <input
                                                type="date"
                                                name="check_in_date"
                                                value={formData?.check_in_date}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Check Out Date:
                                            </label>
                                            <input
                                                type="date"
                                                name="check_out_date"
                                                value={formData?.check_out_date}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                                disabled={formData?.children?.len}
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Price:
                                            </label>
                                            <input
                                                type="text"
                                                name="rental_price"
                                                value={formData?.rental_price?.replace("USD", "")}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Room Type:
                                            </label>
                                            <input
                                                type="text"
                                                name="room_type"
                                                value={formData?.room_type}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-4 my-2">
                                            <label>
                                                Booking reference:
                                            </label>
                                            <input
                                                type="text"
                                                name="booking_reference"
                                                value={formData?.booking_reference}
                                                onChange={handleInputChange}
                                                className="w-full p-2 border rounded"
                                            />
                                        </div>

                                        <div className="col-md-12 mt-2 mb-4 text-end">
                                            <button type="button" onClick={(e) => handleParentSubmit(e)} className="update_booking_submit">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </MDBox>
                    </>
                )}
            </MDBox>
        </DashboardLayout>
    );
}

export default UpdateHotel;
