
import { useState, useEffect, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
// import { getProfile } from "utils/api";
import { userSaving } from "utils/api";
import { toast } from "react-toastify";
import NoAccessModal from "components/NoAccessModal";
import MyContext from "layouts/Context/context";

function DashboardNavbar({ absolute, light, isMini }) {

  const { sub_id, setSubId, totalAmount, setTotalAmount } = useContext(MyContext);

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [user, setUser] = useState({})
  const navigate = useNavigate();
  const [show, setShow] = useState(false)

  useEffect(() => {
    const userdata = localStorage.getItem("active-user");
    const newdata = JSON.parse(userdata)
    setUser(newdata);
    // getProfile()
    //   .then((res) => {
    //     if (res.status === 200) {
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // if (data) {
    //   setUser(data)
    // }
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const pendingAmount = () => {
    userSaving().then((response) => {
      if (response?.data?.length > 0) {
        const unpaidData = response?.data?.filter(item => item.is_payment === false);
        if (unpaidData.length > 0) {
          setSubId(unpaidData)
          const totalSavedAmount = unpaidData.reduce((acc, item) => {
            const amount = parseFloat(item.saved_amount.replace("$", ""));
            return acc + amount;
          }, 0);
          const tenPercent = (totalSavedAmount * 0.10).toFixed(2);
          setTotalAmount(tenPercent)
          
        } else {
          setSubId(null)
        }
      } else {
        setSubId(null)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    pendingAmount()
  }, [])


  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload()
    setTimeout(() => {
      navigate("/");
    }, 200)
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={() => {
          navigate("/profile");
        }}
        icon={<Icon>email</Icon>}
        title="Profie"
      />
      <NotificationItem
        onClick={() => {
          handleLogout();
        }}
        icon={<Icon>logout</Icon>}
        title="Logout"
      />
    </Menu>
  );

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const savePercent = (amount) => {
    const savedAmount = parseFloat(amount);
    const tenPercent = (savedAmount * 10) / 100;
    return tenPercent
  }

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox pr={1}>
          <MDInput
            label="Search here"
            className="search_input_label"
            style={{ borderRadius: "30px" }}
          />
        </MDBox>

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"} className="header_section">
              {/* <Link to="/authentication/sign-in/basic"> */}
              {/* <IconButton sx={navbarIconButton} size="small" disableRipple>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton> */}
              {/* </Link> */}
              {
                sub_id?.[0]?.saved_amount &&
                <p className="pending_amount">Amount Due: <span onClick={() => setShow(true)} className="amount_price">{totalAmount ? `$${totalAmount}` : ""}</span></p>
              }

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}

              <IconButton
                size="large"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                {
                  user && user?.image ? (
                    <img
                      src={user.image}
                      height={44}
                      width={44}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      className="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                      <path
                        fillRule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                      />
                    </svg>
                  )
                }
                {/* <Icon sx={iconsStyle} className="profile_icon">account_circle</Icon> */}
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      {
        show && (

          <NoAccessModal totalAmount={totalAmount} data={sub_id} show={show} setShow={setShow} />
        )
      }
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
