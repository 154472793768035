import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import { Button, Card, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import HotelImg from "assets/user-dash/hotelimg.png";
import MDButton from "components/MDButton";
import Loader from "components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";
import { toast } from "react-toastify";
import { updateHotelStatus } from "utils/api";
import { Modal } from "react-bootstrap";
import Hoteldetail from "components/HotelDetail";
import HotelSubstitute from "components/HotelDetail/hotelSubstitute";

function PastHotelBooking({ hotels, loading, allBookingData }) {
  console.log("hotelshotels", hotels)
  const filteredFlights = hotels.map((hotel) => ({
    ...hotel,
    substitutes: hotel?.substitutes.filter((substitute) =>
      ["PENDING", "ACCEPT", "REJECT"].includes(substitute.status)
    ),
  }));
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("")

  const REASON_CHOICES = [
    ["INCOMPLETE_RECORDS", "Incomplete Records"],
    ["TECHNICAL_ISSUES", "Technical Issues"],
    ["POLICY_RESTRICTIONS", "Policy Restrictions"],
    ["HUMAN_ERROR", "Human Error"],
    ["NOT_RELEVANT", "Not Relevant"],
    ["OTHER", "Other"],
  ];

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("")
  };
  const navigate = useNavigate();

  const [ticketStatuses, setTicketStatuses] = React.useState({});

  const [show, setShow] = React.useState(false);
  const [status, setStatus] = React.useState(null);

  const handleClose = () => {
    setShow(false);
  };

  const handleStatus = (id, status) => {
    setShow(id);
    setStatus(status);
  };

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT") {
      setSelectMesage("Please select any reason")
    } else {
      updateHotelStatus(show, {
        status: status,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              `Status ${status === "ELIMINATE"
                ? `${status?.toLowerCase()}d`
                : `${status?.toLowerCase()}ed`
              } successfully`,
              {
                theme: "colored",
              }
            );
            allBookingData();
            setShow(false);
          } else {
            toast.error(`Failed to ${status?.toLowerCase()} status`, {
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

  };


  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "hotels");
    navigate(`/price-monitor/${final_id}`, {
      state: { passBooking: "pass_booking", }
    });
  };


  return (
    <MDBox py={3}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {hotels?.length > 0
            ? hotels?.map((item, i) => {
              return (
                <Accordion className="mb-3" defaultExpanded={i === 0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Hoteldetail item={item} />
                  </AccordionSummary>
                  <AccordionDetails className="substitues_section">
                    <div className="substitutes my-2">
                      <MDTypography variant="h6" color="white">
                        Substitutes Tickets
                      </MDTypography>
                      {item?.substitutes && item?.substitutes?.length > 0 ? (
                        <button
                          className="view_all"
                          onClick={() => {
                            redirection(item.id);
                          }}
                        >
                          View All
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {loading ? (
                      <>
                        <MDTypography
                          className="text-center"
                          variant="h6"
                          color="white"
                        >
                          Loading...
                        </MDTypography>
                      </>
                    ) : (
                      <>
                        {item.substitutes?.length > 0 ? (
                          item?.substitutes?.slice(0, 3)?.map((hotel, index) => {
                            return (
                              <Card className="hotel_cards my-2">
                                <HotelSubstitute hotel={hotel} index={index} ticketStatuses={ticketStatuses} handleStatus={handleStatus} />
                              </Card>
                            );
                          })
                        ) : (
                          <p className="no_substitute">
                            No Booking History Found
                          </p>
                        )}
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })
            :
            <div className="text-center mt-3">
              <MDTypography variant="h5">No Booking History Found</MDTypography>
            </div>
          }
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {status === "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status === "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Please let us know why you are Rejecting this offer.
              </MDTypography>
            }

            {status === "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }

            {status !== "ACCEPT" &&
              <div className="my-3 text-left">
                <FormControl fullWidth>
                  <InputLabel id="reason-select-label">Select Reason</InputLabel>
                  <Select
                    labelId="reason-select-label"
                    value={selectedReason}
                    onChange={handleChange}
                    label="Select Reason"
                    className="select_reason_input"
                  >
                    {REASON_CHOICES?.map((reason) => (
                      <MenuItem key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            }

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleUpdateStatus();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </MDBox>
  );
}

export default PastHotelBooking;
