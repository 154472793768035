import React from "react"
import MDButton from "components/MDButton";


const StatusButtons = ({ ticket, returnID, item, handleStatus, ticketStatuses, samePrice }) => {

    return (
        <>
            <div className="col-md-3 col-lg-2 col-5">
                {ticket?.status === "PENDING" ? (
                    <div className="col-md-12 d-flex airlinetkt">
                        <div className="col-md-12">
                            <div className="flight_detail_butns">
                                <MDButton
                                    className="accept_butn"
                                    onClick={() =>
                                        handleStatus(ticket?.id, "ACCEPT", returnID, ticket?.airline_website,ticket, item)
                                    }
                                    disabled={
                                        ticketStatuses[ticket?.id] ||
                                        ticket?.status !== "PENDING"
                                    }
                                >
                                    ACCEPT
                                </MDButton>
                                <MDButton
                                    className={`reject_butn`}
                                    onClick={() =>
                                        handleStatus(ticket?.id, "REJECT", returnID)
                                    }
                                    disabled={
                                        ticketStatuses[ticket?.id] ||
                                        ticket?.status !== "PENDING"
                                    }
                                >
                                    REJECT
                                </MDButton>
                                {
                                    samePrice !== "samePrice" &&
                                    <MDButton
                                        className="eliminate_butn"
                                        onClick={() =>
                                            handleStatus(
                                                ticket?.id,
                                                "ELIMINATE",
                                                returnID
                                            )
                                        }
                                        disabled={
                                            ticketStatuses[ticket?.id] ||
                                            ticket?.status !== "PENDING"
                                        }
                                    >
                                        ELIMINATE
                                    </MDButton>
                                }

                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="col-md-12 d-flex justify-content-end  airlinetkt ">
                            <div className="col-md-12 text-end">
                                <div className="flight_detail_butns"
                                // style={{
                                //   position: 'relative',
                                //   right: '0',
                                //   top: '67px'
                                // }}
                                >
                                    {
                                        ticket?.status === "ELIMINATE" ?
                                            <MDButton
                                                className={`accept_butn ${ticket?.status === "ACCEPT" &&
                                                    "accepted_butn"
                                                    }`}
                                                disabled={
                                                    ticket?.status === "ACCEPT"
                                                }
                                                onClick={() =>
                                                    handleStatus(
                                                        ticket?.id,
                                                        "REINSTATE", returnID, ticket?.airline_website,ticket, item
                                                    )
                                                }
                                            >
                                                {ticket?.status === "ACCEPT"
                                                    ? "ACCEPTED"
                                                    : "ACCEPT"}
                                            </MDButton>
                                            :
                                            <MDButton
                                                className={`accept_butn ${ticket?.status === "ACCEPT" &&
                                                    "accepted_butn"
                                                    }`}
                                                disabled={
                                                    ticket?.status === "ACCEPT"
                                                }
                                                onClick={() =>
                                                    handleStatus(
                                                        ticket?.id,
                                                        "ACCEPT",
                                                        returnID, ticket?.airline_website,ticket, item
                                                    )
                                                }
                                            >
                                                {ticket?.status === "ACCEPT"
                                                    ? "ACCEPTED"
                                                    : "ACCEPT"}
                                            </MDButton>

                                    }
                                    {
                                        ticket?.status !== "ACCEPT" &&
                                        <>
                                            <MDButton
                                                className={`${ticket?.status === "REJECT"
                                                    ? "rejected_butn"
                                                    : "reject_butn"
                                                    }`}
                                                disabled={
                                                    ticket?.status === "REJECT"
                                                }
                                                onClick={() =>
                                                    handleStatus(
                                                        ticket?.id,
                                                        "REJECT",
                                                        returnID
                                                    )
                                                }
                                            >
                                                {ticket?.status === "REJECT"
                                                    ? "REJECTED"
                                                    : "REJECT"}
                                            </MDButton>

                                            {
                                                samePrice !== "samePrice" &&

                                                <MDButton
                                                    className={`${ticket?.status === "ELIMINATE"
                                                        ? "eliminated_butn"
                                                        : "eliminate_butn"
                                                        }`}
                                                    disabled={
                                                        ticket?.status === "ELIMINATE"
                                                    }
                                                    onClick={() =>
                                                        handleStatus(
                                                            ticket?.id,
                                                            "ELIMINATE",
                                                            returnID
                                                        )
                                                    }
                                                >
                                                    {ticket?.status === "ELIMINATE"
                                                        ? "ELIMINATED"
                                                        : "ELIMINATE"}
                                                </MDButton>
                                            }
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default StatusButtons;