import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";

const FlightDetailModal = ({ data,layoverPrice, subId,layoverData, flightshow, setFlightShow, handleClose, numStops }) => {


    const offer_data = data
    const segments = data

    const formatDuration = (duration) => {
        const hoursMatch = duration?.match(/(\d+)H/); 
        const minutesMatch = duration?.match(/(\d+)M/);
        const hours = hoursMatch ? `${hoursMatch[1]}h` : "";
        const minutes = minutesMatch ? `${minutesMatch[1]}m` : "";
        return `${hours} ${minutes}`?.trim();
    };

    const itineraryDuration = offer_data?.duration;
    const formattedDuration = formatDuration(itineraryDuration);

    const calculateDuration = (start, end) => {
        if (!start || !end) return "";

        const startTime = new Date(start);
        const endTime = new Date(end);
        const diffInMs = endTime - startTime;

        const hours = Math.floor(diffInMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}h ${minutes}m`;
    };


    return (
        <>
            <Modal show={flightshow} onHide={handleClose} centered size="lg" className="layover_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Your flight from {offer_data?.segments?.[0]?.departure?.iataCode} to {offer_data?.segments?.[offer_data?.segments?.length - 1]?.arrival?.iataCode}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {/* <h5 className="flight_name_depar">Flight from {offer_data?.segments?.[0]?.departure?.iataCode} to {offer_data?.segments?.[offer_data?.segments?.length - 1]?.arrival?.iataCode}</h5> */}
                        {numStops > 0 &&
                            <p className="departure_time mb-3">Duration: {layoverPrice ? layoverPrice : formattedDuration}  Stop: {numStops}  </p>
                        }
                    </div>
                    <div>
                        {
                            offer_data?.segments?.length > 0 ?
                                offer_data?.segments?.map((item, i) => {
                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex gap-3">
                                                        <div className="left_list">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                            </svg>
                                                            <div className="vertical-line"></div>
                                                        </div>
                                                        <div>
                                                            <p className="departure_time">{moment(item?.departure?.at).format('llll')}</p>
                                                            <h6>{item?.departure?.iataCode}</h6>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex gap-3 mt-2">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                            </svg>
                                                        </div>

                                                        <div>
                                                            <p className="departure_time">{moment(item?.arrival?.at).format('llll')}</p>
                                                            <h6>{item?.arrival?.iataCode}</h6>
                                                        </div>
                                                    </div>

                                                    <div className="ms-2">
                                                        {i < offer_data.segments.length - 1 && (
                                                            <div className="d-flex gap-2 align-items-center my-1 mb-4 time_duration">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                                                </svg>
                                                                <p className="mb-0">Layover</p>
                                                                <p className="mb-0">
                                                                    {calculateDuration(
                                                                        offer_data.segments[i]?.arrival?.at,
                                                                        offer_data.segments[i + 1]?.departure?.at
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <p className="departure_time">{`${item?.carrierCode}${item?.number}`}</p>
                                                    <p className="departure_time">{`${layoverData?.cabin}`}</p>
                                                    <p className="departure_time">Flight time {formatDuration(item.duration)}</p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                : ""
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FlightDetailModal