import BasicLayout from 'layouts/authentication/components/BasicLayout'
import AboutUs from 'layouts/Front-Page/Aboutus'
import Contactus from 'layouts/Front-Page/Contactus'
import FAQ from 'layouts/Front-Page/FAQ'
import Navbar from 'layouts/Front-Page/Navbar'
import NewsletterFooter from 'layouts/Front-Page/NewsletterFooter'
import React from 'react';
import topImage from "../../assets/images/about_us_top.png"
import grp1 from "../../assets/images/grp1.svg";
import grp2 from "../../assets/images/grp2.svg";
import grp3 from "../../assets/images/grp3.svg";

import team_1 from "../../assets/images/team_1.png";
import team_2 from "../../assets/images/team_2.png";
import team_3 from "../../assets/images/team_3.png";
import team_4 from "../../assets/images/team_4.png";
import team_5 from "../../assets/images/team_5.png";
import team_6 from "../../assets/images/team_6.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import OfficeSwiper from 'components/OfficeSwiper'

const numbers_group = [
    {
        src: grp1,
        title: "1000+",
        description: "Members of the team"
    },
    {
        src: grp2,
        title: "26",
        description: "International Offices"
    },
    {
        src: grp3,
        title: "20+",
        description: "Site Languages"
    }
]



const team_images = [
    team_1, team_2, team_3, team_4, team_5, team_6,
]
const AboutPage = () => {
    return (
        <BasicLayout>
            <div className="home-container about-us-font">
                <Navbar />
                <div className="hersecon">
                    <div className="container text-center">
                        <section>
                            <div className='text-center'>
                                <p className="about-heading more_about_title">More About AiRebooker</p>
                                <h1 className="highlight">About{" "}
                                    <span className='about_us_text'                                        
                                    >{" "}
                                        {` Us`}
                                    </span>
                                </h1>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-lg-6'>
                                    <h1 className='text-end us_text'
                                        style={{ fontSize: "65px", color: "#344585" }}
                                    >
                                        Us
                                    </h1>
                                    <img src={topImage} className='w-100' />
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className="about_descrition_section text-left">
                                        <p className="about-description text-left">
                                            At AiRebooker, we are dedicated to simplifying the travel
                                            experience for frequent travelers. Our innovative mobile app
                                            helps users automatically monitor travel reservations, track
                                            price changes, and secure savings through rebooking at lower
                                            rates. By leveraging advanced technology and seamless
                                            integration with major travel service providers, AiRebooker
                                            empowers users to manage their travel plans effortlessly.
                                        </p>
                                    </div>
                                </div>
                              
                            </div>
                        </section>
                        <section className='my-5'>
                            <div className='about-us-section-2 p-5'>
                                <div className='row'>
                                    <div className='col-md-4 col-lg-4 text-start m-auto'>
                                        <p className='ai_by_num mb-1'>AIRebooker by the numbers</p>
                                        <h4 className=' text-light display-5 fw-bold'>By the numbers.</h4>
                                        <p className=' fs-6 opacity-75 me-auto text-light fw-lighter'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                    {
                                        numbers_group.map((item, index) => (
                                            <div key={index} className='col-md-2 col-lg-2 text-light number_card mx-auto  cardnumber'>
                                                <img src={item.src} alt={item.title} className='img-fluid' />
                                                <h4 className='my-3 fs-2 ps-2'>{item.title}</h4>
                                                <p className='ps-2'>{item.description}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </section>
                        <section className='text-start my-5 py-5'>
                            <h5 className='ai_by_num fw-extrabold'>Our Offices</h5>
                            <div className='mb-4 d-flex justify-content-between w-100'>
                                <h2 className='fw-bolder'>Track, Save, Rebook – Your <br />Travel, Simplified</h2>
                                <div className='d-flex flex-row gap-3 mt-auto'>
                                    <button className='btn-left px-2 fs-4 shadow-lg office-slide-prev__active'>&larr;</button>
                                    <button className='btn-right px-2 fs-4 shadow-lg office-slide-next__active'>&rarr;</button>
                                </div>
                            </div>
                            <div className="office-swiper-container">
                                <OfficeSwiper />
                            </div>
                        </section>
                        <section className='py-5 my-5'>
                            <div className="row">
                                <div className="col-md-2 col-lg-2 d-flex align-items-center">
                                    <div className='d-flex flex-row gap-3 mx-auto'>
                                        <button className='btn-left px-2 fs-4 shadow-lg team-slide-prev__active'>&larr;</button>
                                        <button className='btn-right px-2 fs-4 shadow-lg team-slide-next__active'>&rarr;</button>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 overflow-hidden my-auto">
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={20}
                                        slidesPerView={2}
                                        navigation={{
                                            nextEl: ".team-slide-next__active",
                                            prevEl: ".team-slide-prev__active",
                                        }}
                                        direction='horizontal'
                                        pagination={{ clickable: true }}
                                        breakpoints={{
                                            320: { slidesPerView: 1 },
                                            640: { slidesPerView: 2 },
                                            1024: { slidesPerView: 4 },
                                            1280: { slidesPerView: 6 },
                                        }}
                                        className='w-100'
                                    >
                                        {team_images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img src={image} alt={`Team ${index + 1}`} className="w-full h-auto rounded-lg shadow-md" />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>

                                <div className="col-md-4 col-lg-4 text-start">
                                    <p className='ai_by_num mb-1'>Join Our Team</p>
                                    <h4 className='display-6 fw-bold'>Interested in a career at AIReBooker</h4>
                                    <button className={`btn btn-secondary rounded-pill px-4 py-2 button-active mt-3`}>View Positions</button>
                                </div>
                            </div>
                        </section>
                        <FAQ />
                        <Contactus />
                        <NewsletterFooter />
                    </div>
                </div>
            </div>
        </BasicLayout>
    )
}

export default AboutPage